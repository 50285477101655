import { DatasetFilter } from "@/models/mapViewerModels";
import { Geometry } from "ol/geom";
import { Feature } from "ol";
import { ComputedRef, Ref } from "vue";

export enum Stage {
  Selecting = 1,
  Viewing
}

export enum MapMode {
  TotalVisitors = 1,
  Origin,
  Destination,
  DwellTime,
  TotalPayments,
  BlendedHotspots,
  BlendedCorrelation,
  BlendedHighSpendRatio,
  BlendedLowSpendRatio
}

export class AnalysisMode {
  name: string;
  description: string;
  filters: Ref<DatasetFilter>[];
  endpoint: string;
  magnitudeDisplayTitle: ComputedRef<string>;
  showValuesSlider: boolean;
  getQueryParams: (zoneIds: number[]) => string;
  getSelectableZoneIds?: (zoneFeatures: Feature<Geometry>[]) => number[];

  constructor(
    name: string,
    description: string,
    filters: Ref<DatasetFilter>[],
    endpoint: string,
    magnitudeDisplayTitle: ComputedRef<string>,
    getQueryParams: (zoneIds: number[]) => string,
    showValuesSlider: boolean,
    getSelectableZoneIds?: (zoneFeatures: Feature<Geometry>[]) => number[]
  ) {
    this.name = name;
    this.description = description;
    this.filters = filters;
    this.endpoint = endpoint;
    this.magnitudeDisplayTitle = magnitudeDisplayTitle;
    this.showValuesSlider = showValuesSlider;
    this.getQueryParams = getQueryParams;
    this.getSelectableZoneIds = getSelectableZoneIds;
  }
}