import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { reactive, ref, provide, computed } from "vue";
import WizardStepper from "@/components/WizardStepper.vue";
import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import ProjectDetailsStep from "./components/wizardsteps/ProjectDetailsStep.vue";
import LocationStep from "./components/wizardsteps/LocationStep.vue";
import ConfirmStep from "./components/wizardsteps/ConfirmStep.vue";
import { MAP_CENTER, MAP_ZOOM } from "@/util/maps/mapUtils";
import { CustomerReport, dtoToUserRequestedReport, UserRequestedReport } from "@/models/customerReportsModels";
import envConfig from "@/config/envConfig";
import { useRequest } from "@/composables/requestComposables";
import { authenticatedRequest } from "@/composables/requestComposables";
import { useRouter } from 'vue-router'
import { getQueryParams } from "@/util/urlHelpers";


export default _defineComponent({
  setup(__props) {

const userReportDetails = reactive<UserRequestedReport>({
  reportId: -1,
  reportName: "",
  clientProjectName: "",
  pois: [],
  reportInputs: [],
  selectedZones: [],
  zoneSystem: "Standard"
});

const datasetDetailsRequest = useRequest({
  requestCallback(datasetId: string) {
    const url = `${envConfig.admin_address}/api/services/app/CustomerReport/GetRequestedReport?id=${datasetId}`
    const headers = new Headers();
    return authenticatedRequest(url, { headers })
      .then(res => {
        if (!res.ok) throw new Error("Unable to load report details")
        return res.json()
      })
      .then(data => {
        const result = dtoToUserRequestedReport(data.result);
        Object.keys(result).forEach(key => {
          (userReportDetails as { [index: string]: any })[key] = (result as { [index: string]: any })[key];
        });

        userReportDetails.clientProjectName = "";

        return result;
      });
  },
  errorModal: true
});

const datasetId = getQueryParams()["datasetid"];
if (datasetId) datasetDetailsRequest.invoke(datasetId);

const reportConfig = ref<CustomerReport>();
const getReportRequest = useRequest({
  requestCallback: () => {
    const reportId = getQueryParams()["id"];
    const url = envConfig.admin_address + `/api/services/app/CustomerReport/GetCustomerReport?id=${reportId}`;
    const headers = new Headers();

    return authenticatedRequest(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        reportConfig.value = data.result;
        userReportDetails.reportId = reportConfig.value?.id ?? -1;
        userReportDetails.reportName = reportConfig.value?.name ?? "";
        return data.result as CustomerReport;
      });
  },
  requestImmediately: true
});

provide("userReportDetails", userReportDetails);
provide("reportConfig", reportConfig);

const selectedInputOptions = computed(() => userReportDetails.reportInputs.map(i => i.selectedOptions).flat());
const baseCost = computed(() => {
  //Inputs  +base only. Population + Area costs handled by child components
  let inputCost = 0;
  if (selectedInputOptions.value.length > 0) {
    inputCost = selectedInputOptions.value.map(so => so.credits).reduce((x, y) => x + y)
  }

  return (reportConfig.value?.baseCredits ?? 0) + inputCost;
});

provide("baseCost", baseCost);

const mapZoom = ref(MAP_ZOOM);
const mapCenter = ref(MAP_CENTER);

provide("mapZoom", mapZoom);
provide("mapCenter", mapCenter);

let currentWizardStep = ref(0);

const wizardSteps = computed(() => {
  const steps = [
    { title: "Details", index: 0 },
    { title: "Location", index: 1 },
    { title: "Confirm", index: 2 }
  ];
  return steps;
});


function back() {
  const steps = wizardSteps.value.map((s) => s.index);
  const currentStepPosition = steps.indexOf(currentWizardStep.value);
  const newStepPosition = currentStepPosition - 1;

  if (currentStepPosition === 0) {
    return;
  }
  else if (newStepPosition == 0) {
    userReportDetails.geofenceWkt = "";
    userReportDetails.studyAreaWkt = "";
    userReportDetails.pois = [];
  }

  currentWizardStep.value = wizardSteps.value[newStepPosition].index;
}

function projectDetailsSubmit() {
  nextStep();
}

function locationSubmit() {
  nextStep();
}

function nextStep() {
  //There is a variable number of steps with specific indicies, therefore we can't just increment
  const steps = wizardSteps.value.map((s) => s.index);
  const currentStepPosition = steps.indexOf(currentWizardStep.value);
  currentWizardStep.value = wizardSteps.value[currentStepPosition + 1].index;
}


const requestReportRequest = useRequest({
  requestCallback: (userReportDetails: UserRequestedReport) => {
    const url = `${envConfig.api_address}/datasetsapi/datasets/request`;
    const headers = new Headers();

    const body = JSON.stringify(userReportDetails);

    return authenticatedRequest(url, { method: "POST", headers, body })
      .then((response) => {
        if (response.ok)
          return response.json();
        else throw new Error("Unable to create report. Your organisation's credits balance is too low, or your user account doesn't have permission to spend credits. To add more credits, please contact christopher.bax@amey.co.uk")
      })
      .then((data) => console.log(data.result));
  },
  requestImmediately: false,
  errorModal: true
});

const router = useRouter();
const submitReportRequest = async (userReportDetails: UserRequestedReport) => {
  await requestReportRequest.invoke(userReportDetails);
  router.push({ path: "/My_Datasets" });
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DataFetchDisplay, {
    requests: [_unref(getReportRequest), _unref(datasetDetailsRequest), _unref(requestReportRequest)]
  }, {
    default: _withCtx(() => [
      _createVNode(WizardStepper, {
        currentStep: _unref(currentWizardStep),
        steps: _unref(wizardSteps)
      }, _createSlots({
        "0": _withCtx(() => [
          _createVNode(ProjectDetailsStep, { onNextStep: projectDetailsSubmit })
        ]),
        _: 2
      }, [
        (_unref(userReportDetails))
          ? {
              name: "1",
              fn: _withCtx(() => [
                _createVNode(LocationStep, {
                  onBack: back,
                  onNextStep: locationSubmit
                })
              ])
            }
          : undefined,
        (_unref(userReportDetails).pois.length > 0 || _unref(userReportDetails).studyAreaWkt || _unref(userReportDetails).selectedZones.length > 0)
          ? {
              name: "2",
              fn: _withCtx(() => [
                _createVNode(ConfirmStep, {
                  onBack: back,
                  onNextStep: _cache[0] || (_cache[0] = ($event: any) => (submitReportRequest(_unref(userReportDetails))))
                })
              ])
            }
          : undefined
      ]), 1032, ["currentStep", "steps"])
    ]),
    _: 1
  }, 8, ["requests"]))
}
}

})