import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, PropType } from "vue";
import Map from "ol/Map";
import { Vector as VectorSource } from "ol/source";
import { FitOptions } from "ol/View";
import proj4 from "proj4";
import { addWktFeature } from "@/util/maps/mapUtils";
import { register } from "ol/proj/proj4";
import { basemapsGroup } from "@/util/maps/mapLayers";
import { defaults } from "ol/interaction";
import { Circle } from "ol/geom";
import Feature from "ol/Feature";
import * as olProj from "ol/proj";
import { Coordinate } from "ol/coordinate";


export default _defineComponent({
  props: {
  studyAreaWkt: {
    type: String,
    required: false
  },
  poiCoords: {
    type: Array as PropType<Coordinate>,
    required: false
  }
},
  setup(__props) {

const props = __props


proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717" + " +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
register(proj4);

const source = new VectorSource();
if (props.studyAreaWkt) {
  addWktFeature(props.studyAreaWkt, source);
}
else if (props.poiCoords) {

  const point = olProj.transform([props.poiCoords[0], props.poiCoords[1]], "EPSG:4326", "EPSG:3857")

  const pointGeom = new Circle(point, 10000);
  const pointFeature = new Feature({
    geometry: pointGeom
  });

  source.addFeature(pointFeature);
}
const mapRef = ref<HTMLElement>();

onMounted(() => {
  const mapElem = mapRef.value as HTMLElement;

  const map = new Map({
    layers: [basemapsGroup.getLayersArray()[basemapsGroup.getLayersArray().length-1]],
    target: mapElem,
    interactions: defaults({
      doubleClickZoom: false,
      dragPan: false,
      mouseWheelZoom: false,
    })
  });

  map.getView().fit(source.getExtent(), map.getSize() as FitOptions);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "study-area-readonly-display-container",
    ref: mapRef,
    style: {"width":"150px","height":"150px"}
  }, null, 512))
}
}

})