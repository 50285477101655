<template>
  <div style="padding:0 5px">

    <p>All figures in this dataset relate specifically to EVs - non-EV trips are excluded. EVs refer to any <span
        style="font-weight:bold">plug-in
        electric vehicle</span>. EV trips are derived using the home location of the device and the EV registration
      data.</p>

    <h2>Definitions</h2>
    <hr>

    <h3>EV Analysis</h3>
    <hr>

    <h3>Visits</h3>
    <p>A visit is defined as a trip with a destination at the point or area
      of interest. One person can make multiple
      visits a day. Intra-zonal trips at the point of interest are discarded.</p>

    <p><span style="font-weight:bold">Average daily visits</span> The average number of visits (trip destinations) made
      to the point of interest on a particular day.</p>

    <p><span style="font-weight:bold">Average hourly visits</span> The average number of visits (trip destinations) made
      to the point of interest at a particular hour of the day.
    </p>

    <p><span style="font-weight:bold">Repeat visit</span> If a person makes more than one visit to the point of interest
      on the same calendar week, all visits made by that
      person on that week are categorised as repeated.</p>

    <p><span style="font-weight:bold">Visitors by journey distance</span> The total number of visitors in each distance
      band.</p>
    <hr>

    <h3>Traversing</h3>
    <hr>

    <h3>Dwell</h3>
    <p>A dwell is defined as a stop in a person's journey itinerary that is longer than 30 minutes. <span
        style="font-weight:bold">Residents of the area are excluded from the dwelling analysis.</span></p>

    <p><span style="font-weight:bold">Average dwell time by day</span> The average number of hours dwelled at the point
      or area of interest on a particular day of the week.</p>

    <p><span style="font-weight:bold">Average dwell time by start time</span> The average number of hours dwelled at the
      point or area of interest, grouped by the starting hour of the dwell.
    </p>

    <p><span style="font-weight:bold">Average number of dwellers by hour</span> The average number of people dwelling at
      the point or area of interest in each of the hours of the day.</p>

    <hr>
  </div>
</template>