import { DatasetFilter } from "@/models/mapViewerModels";
import { computed, ref } from "vue";
import { AnalysisMode } from "./types";


const daytypeControl = ref<DatasetFilter>({
  name: "Day Type",
  multiSelect: true,
  options: [
    { value: '1', label: "Weekday", },
    { value: '0', label: "Weekend", }
  ],
  selectedValues: []
});

const traversingControl = ref<DatasetFilter>({
  name: "Traversing",
  multiSelect: true,
  options: [
    {value: '1', label: 'Yes'},
    {value: '0', label: 'No'},
  ],
  selectedValues: []
});

export const allFilters = [daytypeControl, traversingControl]

const getFilterValues = (filter: DatasetFilter) => filter.selectedValues.length > 0 ? filter.selectedValues : filter.options.map(o => o.value);

export const analysisModes: AnalysisMode[] = [
  new AnalysisMode(
    "Origins",
    "Journey Origins",
    [daytypeControl, traversingControl],
    "journeyorigins",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      const traversing = getFilterValues(traversingControl.value);
      return `?daytype=${days}&traversing=${traversing}`;
    },
    true
  ),
  new AnalysisMode(
    "Destinations",
    "Journey Destinations",
    [daytypeControl, traversingControl],
    "journeydestinations",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      const traversing = getFilterValues(traversingControl.value);
      return `?daytype=${days}&traversing=${traversing}`;
    },
    true
  ),
  new AnalysisMode(
    "Dwell Time",
    "Origins by Average Dwell Time (minutes)",
    [daytypeControl],
    "dwellorigins",
    computed(() => "Average Dwell Time"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      return `?daytype=${days}`;
    },
    false
  ),
];