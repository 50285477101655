import { useModals } from "@/composables/modalComposables";
import envConfig from "@/config/envConfig";
import { Map } from "ol";
import Control from "ol/control/Control";
import { transform } from "ol/proj";
import { authenticatedRequest } from "@/composables/requestComposables";

const { openInfoModal } = useModals();

function addPostcodeSearch(panel: HTMLDivElement, searchByPostcode: (postcode: string) => void) {
  const inputChanged = (postcode: string, goButton: HTMLInputElement) => {
    const regexWithSpace = /^([a-zA-Z]{1,2}[a-zA-Z\d]{1,2})\s(\d[a-zA-Z]{2})$/;
    const regexWithoutSpace = /^([a-zA-Z]{1,2}[a-zA-Z\d]{1,2})(\d[a-zA-Z]{2})$/;

    if (!regexWithSpace.test(postcode) && !regexWithoutSpace.test(postcode))
      goButton.disabled = true;
    else
      goButton.disabled = false;
  }

  const wrapper = document.createElement('div');
  wrapper.classList.add('input-group');
  wrapper.style.marginBottom = "10px";

  const label = document.createElement('label');
  label.classList.add('input-group-addon');
  label.innerHTML = "Postcode";

  const input = document.createElement('input');
  input.classList.add('form-control');
  input.placeholder = 'Search...';

  const goButtonSpan = document.createElement('span');
  goButtonSpan.classList.add('input-group-btn');

  const goButton = document.createElement('input'); //Button element not visible. Cant figure out why so using input because that works for some reason.
  goButton.classList.add('btn', 'btn-rosie');
  goButton.value = "Go";
  goButton.type = 'button';
  goButton.disabled = true;

  goButtonSpan.appendChild(goButton);

  input.addEventListener('input', () => inputChanged(input.value, goButton));
  goButton.addEventListener('click', () => searchByPostcode(input.value));

  wrapper.appendChild(label);
  wrapper.appendChild(input);
  wrapper.appendChild(goButtonSpan);

  panel.appendChild(wrapper);
}

function addLatLongSearch(panel: HTMLDivElement, searchByLatLong: (lat: number, long: number) => void) {
  const inputsChanged = (lat: string, long: string, goButton: HTMLInputElement) => {
    const regex = /^(-)?\d+((.\d+)?)$/;

    if (!regex.test(lat) || !regex.test(long))
      goButton.disabled = true;
    else
      goButton.disabled = false;
  }

  const wrapper = document.createElement('div');
  wrapper.classList.add('input-group');

  const label = document.createElement('label');
  label.classList.add('input-group-addon');
  label.innerHTML = "Coordinates";

  const latInput = document.createElement('input');
  latInput.classList.add('form-control');
  latInput.placeholder = 'Latitude';
  latInput.style.width = '50%';

  const longInput = document.createElement('input');
  longInput.classList.add('form-control');
  longInput.placeholder = 'Longitude';
  longInput.style.width = '50%';

  const goButtonSpan = document.createElement('span');
  goButtonSpan.classList.add('input-group-btn');

  const goButton = document.createElement('input'); //Button element not visible. Cant figure out why so using input because that works for some reason.
  goButton.classList.add('btn', 'btn-rosie');
  goButton.value = "Go";
  goButton.type = 'button';
  goButton.disabled = true;

  goButtonSpan.appendChild(goButton)

  latInput.addEventListener('input', () => inputsChanged(latInput.value, longInput.value, goButton));
  longInput.addEventListener('input', () => inputsChanged(latInput.value, longInput.value, goButton));

  goButton.addEventListener('click', () => searchByLatLong(parseFloat(latInput.value), parseFloat(longInput.value)));

  wrapper.appendChild(label);
  wrapper.appendChild(latInput);
  wrapper.appendChild(longInput);
  wrapper.appendChild(goButtonSpan);

  panel.appendChild(wrapper);

}

class SearchControl extends Control {
  constructor(opt_options?: any) {
    const options = opt_options || {};

    const panel = document.createElement('div');
    panel.classList.add('map-search-panel');
    panel.innerHTML = '<label style="font-weight:bold">Search</label>'
    panel.style.display = 'none';


    const button = document.createElement('button');
    button.classList.add('map-search-button');
    button.innerHTML = '<i class="fa fa-magnifying-glass"></i>';

    const element = document.createElement('div');
    element.className = 'map-search-control ol-unselectable ol-control';

    element.appendChild(button);
    element.appendChild(panel);

    element.addEventListener('mouseover', () => {
      panel.style.display = 'block';
      button.style.display = 'none';
    });
    element.addEventListener('mouseleave', () => {
      panel.style.display = 'none';
      button.style.display = 'block';
    });

    super({
      element: element,
      target: options.target,
    });

    addPostcodeSearch(panel, this.searchByPostcode.bind(this));
    addLatLongSearch(panel, this.searchByLatLong.bind(this));
  }

  searchByLatLong(lat: number, long: number) {
    const coords = transform([long, lat], "EPSG:4326", "EPSG:3857")
    this.getMap()!.getView().setCenter(coords);
    this.getMap()!.getView().setZoom(12);
  }

  searchByPostcode(postcode: string) {
    const postcodeSanitized = postcode.replace(/ /g, '').toUpperCase();

    const url = `${envConfig.api_address}/datasetsapi/postcodelocation?postcode=${postcodeSanitized}`;
    const headers = new Headers();

    const _map = this.getMap()!;
    authenticatedRequest(url, { headers })
      .then(res => {
        if (res.status === 200) return res.json();
        else throw new Error("Postcode search failed");
      })
      .then(data => {
        if (data.Results.length > 0) {
          const location = data.Results[0];
          const coords = transform([location.long, location.lat], "EPSG:4326", "EPSG:3857")

          _map.getView().setCenter(coords);
          _map.getView().setZoom(15);
        } else {
          throw new Error("No matching postcodes found");
        }
      }).catch((e: Error) =>
        openInfoModal("Error", `<p>${e.message}</p>`)
      );
  }
}

export function addSearch(map: Map) {
  const searchControl = new SearchControl();

  map.addControl(searchControl);
}