import { useModals } from "@/composables/modalComposables";
import { useRequest, authenticatedRequest } from "@/composables/requestComposables";
import envConfig from "@/config/envConfig";
import { RequestedReportDto, dtoToUserRequestedReport, UserRequestedReport, UserReportGroup, CustomerReport } from "@/models/customerReportsModels";
import { h, ref } from "vue";
import ChangeOrganisationModal from "../components/Modals/ChangeOrganisationModal.vue";
import AddToGroupModal from "../components/Modals/AddToGroupModal.vue";
import DatasetInfoModal from "../components/Modals/DatasetInfoModal.vue";
import DatasetDownloadsModal from "@/components/Modals/DatasetDownloadsModal.vue";

const { pushModal } = useModals();

const openDownloadsModal = (report: RequestedReportDto) =>
  pushModal(h(DatasetDownloadsModal, { datasetId: report.id! }));

export const openDatasetInfoModal = (report: UserRequestedReport & RequestedReportDto, displayMap: boolean) =>
  pushModal(h(DatasetInfoModal, { report, displayMap: displayMap, onDownloadButtonClicked: () => openDownloadsModal(report) }));


//Controls

const searchFilter = ref("");
const groupIdFilter = ref<number>();
const organisationFilter = ref<string>();
const templateFilter = ref<string>('-1');
const displayMode = ref(0);

export const useControls = () => {
  return {
    searchFilter,
    groupIdFilter,
    organisationFilter,
    templateFilter,
    displayMode
  }
};

//Requests

const reloadReportsAndGroups = () => {
  getRequestedReports.invoke();
  getReportGroups.invoke()
}

const openAddToGroupModal = (groups: UserReportGroup[], datasetId: number) =>
  pushModal(h(AddToGroupModal, { groups, datasetId, onSuccess: reloadReportsAndGroups }));

const openChangeOrganisationModal = (datasetId: number, currentOrganisationId: number, datasetName: string) =>
  pushModal(h(ChangeOrganisationModal, { datasetId, currentOrganisationId, datasetName, onSuccess: reloadReportsAndGroups }));

const removeReportFromGroup = useRequest({
  requestCallback: (datasetId: number) => {
    const url = `${envConfig.admin_address}/api/services/app/CustomerReport/UpdateReportGroup?requestedReportId=${datasetId}`;
    const headers = new Headers();

    return authenticatedRequest(url, { headers, method: "POST" })
      .then((response) => {
        if (response.status != 200) { throw new Error("request failed") }
        else return
      })
  }
});
const getRequestedReports = useRequest({
  requestCallback: async () => {
    const getRequestedReportsUrl = `${envConfig.admin_address}/api/services/app/CustomerReport/GetRequestedReports`;
    const headers = new Headers();

    const requestedReports = await authenticatedRequest(getRequestedReportsUrl, { headers })
      .then((response) => response.json())
      .then((data) => data.result.map((item: RequestedReportDto) => {
        if (item.groupName == null) item.groupName = "";

        const details = dtoToUserRequestedReport(item);

        const groupButton = item.groupId ?
          {
            title: "",
            type: "button",
            style: "font-size:15px;color:white;padding:6px 10px;border-radius:40px;max-width:35px",
            class: "fa fa-minus btn btn-danger",
            tooltip: "Remove this dataset from its current dataset group",
            clickFn: (event: Event) => {
              event.stopPropagation()
              removeReportFromGroup.invoke(item.id!);
              getRequestedReports.invoke()
            },
          } : {
            title: "",
            type: "button",
            class: "fa fa-plus btn btn-success",
            tooltip: "Add this dataset to a dataset group",
            style: "font-size:15px;color:white;padding:6px 10px;border-radius:40px;max-width:35px",
            clickFn: (event: Event) => {
              event.stopPropagation()
              if (getReportGroups.data) {
                openAddToGroupModal(getReportGroups.data!, item.id!)
              }
            }
          }

        const viewButton = {
          title: "",
          type: "button",
          style: "font-size:15px;color:white;padding:6px 10px;border-radius:40px;max-width:35px",
          class: "fa fa-magnifying-glass btn blue ",
          tooltip: "View more details for this dataset and access analysis",
          clickFn: () => { }
        }

        const changeOrgButton = {
          title: "Change Org",
          type: "button",
          class: "btn btn-success",
          style: "border-radius: 40px;",
          tooltip: "Change the organisation",
          clickFn: (event: Event) => {
            event.stopPropagation();
            openChangeOrganisationModal(item.id!, item.organisationId, details.clientProjectName);
          }
        }

        return {
          ...item,
          ...details,
          viewButton,
          groupButton,
          changeOrgButton
        }
      }) as (RequestedReportDto & UserRequestedReport)[]);

    //Only ask for relevant dataset scores so we dont send a mass of json through API gateway param
    const datasetsToGetScore = ["EV Chargepoint Insights"];
    const getDatasetScoresParams = requestedReports.filter(rr => datasetsToGetScore.includes(rr.reportName)).map(rr => ({ id: rr.id, type: rr.reportName }))
    
    const getDatasetScoresUrl = `${envConfig.api_address}/datasetsapi/datasetsscores?datasets=${encodeURIComponent(JSON.stringify(getDatasetScoresParams))}`
    const scoreResults = await authenticatedRequest(getDatasetScoresUrl, { headers }).then((response) => response.json()).then(data => data.Results).catch(() => []);

    return requestedReports.map(rr => ({...rr, score: scoreResults.filter((sr: any) => sr.id === rr.id)[0]?.score}))
  }
});

const getReportGroups = useRequest({
  requestCallback: () => {
    const url = `${envConfig.admin_address}/api/services/app/CustomerReport/ListAllGroups`;
    const headers = new Headers();

    return authenticatedRequest(url, { headers })
      .then((response) => response.json())
      .then((data) => data.result as UserReportGroup[]);
  }
});

const getReportConfig = useRequest({
  requestCallback: (reportId: number) => {
    const url = `${envConfig.admin_address}/api/services/app/CustomerReport/GetCustomerReport?id=${reportId}`;
    const headers = new Headers();

    return authenticatedRequest(url, { headers })
      .then((response) => response.json())
      .then((data) => data.result as CustomerReport);
  }
});

export const useMyDatasetsRequests = () => {
  getRequestedReports.invoke();
  getReportGroups.invoke();

  return {
    requests: {
      removeReportFromGroup,
      getRequestedReports,
      getReportGroups,
      getReportConfig
    },
    reloadReportsAndGroups
  }
}
