// @ts-nocheck
import MapPinIcon0 from "@/assets/images/map_pin_icon_0.png";
import MapPinIcon1 from "@/assets/images/map_pin_icon_1.png";
import MapPinIcon2 from "@/assets/images/map_pin_icon_2.png";
import MapPinIcon3 from "@/assets/images/map_pin_icon_3.png";
import MapPinIcon4 from "@/assets/images/map_pin_icon_4.png";

export const mapPinsRAG = [
  MapPinIcon0,
  MapPinIcon1,
  MapPinIcon2,
  MapPinIcon3,
  MapPinIcon4
]