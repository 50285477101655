import { reactive, ref } from "vue";
import { useModals } from "@/composables/modalComposables";
import { getCookie } from "../util/cookieHelpers";
import { checkAuthenticated } from "./appComposables";
import envConfig from "../config/envConfig";

const openInfoModal = useModals().openInfoModal;


const ongoingRequestUrls = ref<string[]>([]);

function initRequest(url: string) {
  document.body.style.cursor = "wait";
  ongoingRequestUrls.value.push(url);
}

function finRequest(url: string) {
  ongoingRequestUrls.value = ongoingRequestUrls.value.filter(x => x !== url);
  if (ongoingRequestUrls.value.length === 0) {
    document.body.style.cursor = "auto";
  }
}

export const authenticatedRequest = (url: string, requestInit: RequestInit) => {
  const requestConfig = { ...requestInit };
  requestConfig.headers = new Headers();
  if (requestInit.headers !== undefined) {
    for (const pair of (requestInit.headers.entries as () => IterableIterator<[string, string]>)()) {
      requestConfig.headers.append(pair[0], pair[1]);
    }
  }
  const tokenId = getCookie("token_id");
  if (!tokenId) {
    checkAuthenticated();
    return Promise.reject(401);
  }

  if (url.includes(envConfig.admin_address)) {
    requestConfig.headers.append("Authorization", `Bearer ${tokenId}`);
  } else {
    requestConfig.headers.append("AuthToken", tokenId);
  }

  initRequest(url);
  return fetch(url, requestConfig)
    .then(res => {
      if (res.status == 401 || res.status == 403) {
        checkAuthenticated();
        return Promise.reject(res.status);
      }
      return Promise.resolve(res);
    })
    .catch(err => {
      console.error(err);
      return Promise.reject(err);
    }).finally(() => {
      finRequest(url);
    });
};

interface RequestOptions<A extends any[], R> {
  requestCallback(...x: A): Promise<R>;
  errorModal?: boolean;
  successModalBody?: string;
  requestImmediately?: boolean;
  requestParams?: A;
}

export class ApiRequest<A extends any[], R> {
  loading: boolean;
  error?: Error;
  data?: R;

  private options: RequestOptions<A, R>;

  constructor(options: RequestOptions<A, R>) {
    this.options = options;
    this.loading = false;
    this.error = undefined;
    this.data = undefined;
  }
  async invoke(...requestParams: A) {
    this.error = undefined;
    this.loading = true;

    try {
      this.data = await this.options.requestCallback(...requestParams);

      if (this.options.successModalBody) {
        openInfoModal("Success", this.options.successModalBody);
      }
    } catch (e: any) {
      this.data = undefined;

      console.error(e);
      this.error = e

      if (this.options.errorModal) {
        openInfoModal("Error", `<p>${e.message}</p>`);
      }
    } finally {
      this.loading = false;
    }
    return this.data;
  }
}

export function useRequest<R, A extends any[]>(options: RequestOptions<A, R>): ApiRequest<A, R> {
  const request = reactive(new ApiRequest<A, R>(options));

  if (options.requestImmediately) {
    if (options.requestParams) {
      request.invoke(...options.requestParams);
    } else {
      //@ts-ignore-line
      request.invoke();
    }
  }

  return request as ApiRequest<A, R>;
}