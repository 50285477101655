import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { onMounted, provide, ref, watch, watchEffect } from "vue";
import Map from "ol/Map";
import * as olProj from "ol/proj";
import { defaults } from "ol/control";
import PreventInteraction from "@/components/PreventInteraction.vue";
import MapGradientLegend from "@/components/Maps/MapGradientLegend.vue";
import MapControlMenu from "./components/MapControlMenu.vue";
import { addLayerSwitcher, basemapsGroup } from "@/util/maps/mapLayers";
import View from "ol/View";
import { addOverlayPopup, removeAllOverlays } from "@/util/maps/mapOverlayUtils";
import { createZonesLayer, MapZoneMagnitude, setAvailableZones, setZoneMagnitudes } from "@/util/maps/mapZoneUtils";
import VectorImageLayer from 'ol/layer/VectorImage';
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { Stage } from "./util/types";
import { clearAllFeatureParams, makePoiLayer } from "@/util/maps/mapHelpers";
import { useStdLocationMapRequest, useAnalysisFilter, useValuesSlider, StdLocationRequestResponse } from "./util/composables";
import VectorSource from "ol/source/Vector";
import { getQueryParams } from "@/util/urlHelpers";
import { addLoadingZonesText } from "@/util/maps/loadingZonesTextControl";
import { createZonesSourceForDataset } from '@/util/maps/mapZoneUtils';
import { WKT } from 'ol/format';
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { addZoomRangeText } from "@/util/maps/zoomRangeTextControl";
import { useGetRequestedReportRequest } from "@/composables/data/reportComposables";

type Zone = {
  objectid: string,
  code: string,
  name: string,
  geometry27700: string,
  extents: string
}

export default _defineComponent({
  setup(__props) {

proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717" + " +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
register(proj4);

const canInteract = ref(true);

const mapRef = ref<HTMLElement>();
const popupWrapper = ref<HTMLDivElement>();
const stage = ref(Stage.Selecting);

const featureIdProperty = "objectid"

const focusedZones = ref<number[]>([])
provide("focusedZones", focusedZones);

if (featureIdProperty === undefined) throw "Must specify a featureIdProperty";
const selectedZoneIds = ref<number[]>([]);

const { analysisMode, canFilterByZone } = useAnalysisFilter();
const { request } = useStdLocationMapRequest();
const { valuesSlider } = useValuesSlider();

onMounted(() => delete (request.data));

provide("selectedZones", selectedZoneIds);
provide("stage", stage);

const resetMapMode = () => {
  clearAllFeatureParams(zonesLayer, "Selected", "Available");
  selectedZoneIds.value = [];

  if (canFilterByZone.value) {
    const features = zonesLayer.getSource().getFeatures();
    const availableZones = analysisMode.value.getSelectableZoneIds!(features)
    setAvailableZones(zonesLayer, availableZones, featureIdProperty!);
  }
}

let zonesLayer: VectorImageLayer<VectorSource<Geometry>>;

const startColour = [187, 50, 65];
const endColour = [0, 100, 36];
const numGroups = 10;

function makeColourset(x: number, y: number) {
  const getVal = (i: number, j: number) => {
    if (x - y === 0) return endColour[j]
    return startColour[j] + (((endColour[j] - startColour[j]) / (y - x)) * (i - x));
  }

  const c: string[] = new Array(numGroups);
  for (let i = x; i < y + 1; i++) {
    c[i] = `hsla(${getVal(i, 0)},${getVal(i, 1)}%,${getVal(i, 2)}%,0.6)`
  }
  return c;
}
let colours = makeColourset(0, numGroups);
const shadeFunction = ref((_: number, displayGroup: number) => colours[displayGroup]);

function displayZoneData(zones?: MapZoneMagnitude[]) {
  if (!zones) throw "No zones loaded...";
  setZoneMagnitudes(zonesLayer, zones, featureIdProperty!);
  return zones;
}
async function updateMap() {
  clearAllFeatureParams(zonesLayer, "Available", "Magnitude");
  stage.value = Stage.Viewing;

  const response = await request.invoke(selectedZoneIds.value);
  displayZoneData(response?.Items);
}
function updateGroupsSlider(response: StdLocationRequestResponse) {
  valuesSlider.value.max = response.Classes - 1;
  valuesSlider.value.min = 0;

  return { min: valuesSlider.value.min, max: valuesSlider.value.max }
}
watchEffect(() => {
  if (!request.data?.Items.length) return;
  updateGroupsSlider(request.data);
  colours = makeColourset(valuesSlider.value.low, valuesSlider.value.high)
  clearAllFeatureParams(zonesLayer, "Magnitude");
  displayZoneData(request.data.Items.filter(z => z.displayGroup >= valuesSlider.value.low && z.displayGroup <= valuesSlider.value.high));
});

function resetFilters() {
  clearAllFeatureParams(zonesLayer, "Available", "Magnitude", "Selected");
  selectedZoneIds.value = [];

  stage.value = Stage.Selecting;
  resetMapMode();
}

function zoneClickedCallback(selected: Feature<Geometry>) {
  if (selected.get("Available") !== undefined || selected.get("Magnitude") !== undefined) {

    const alreadySelected = selected.get("Selected") as boolean | undefined;
    selected.set("Selected", !alreadySelected);

    if (alreadySelected) selectedZoneIds.value = selectedZoneIds.value.filter(z => z !== selected.get(featureIdProperty!));
    else selectedZoneIds.value.push(selected.get(featureIdProperty!));
  }
}
function selectAllZones() {
  if (selectedZoneIds.value.length > 0) {
    selectedZoneIds.value = [];
    clearAllFeatureParams(zonesLayer, "Selected");
  } else {
    (zonesLayer.getSource() as VectorSource<Geometry>).getFeatures().forEach(feature => {
      if (feature.get("Magnitude")) zoneClickedCallback(feature)
    })
  }
}

function zoneHoveredCallback(currentlySelected: Boolean, selected: Feature<Geometry>) {
  const hoveredZoneId = selected.get(featureIdProperty) as number;
  if (!selectedZoneIds.value.includes(hoveredZoneId)) return;

  if (currentlySelected) focusedZones.value.push(hoveredZoneId);
  else focusedZones.value = focusedZones.value.filter(z => z != hoveredZoneId);
}

watchEffect(() => {
  if (stage.value === Stage.Viewing && request.data) {
    clearAllFeatureParams(zonesLayer, "Magnitude");
  }
});

const reportDetailsRequest = useGetRequestedReportRequest(getQueryParams()["datasetid"])


provide("reportDetailsRequest", reportDetailsRequest);

const format = new WKT();
function createFeatureFromZone(zone: Zone) {
  const wkt = zone.geometry27700

  const feature = format.readFeature(wkt, {
    dataProjection: "EPSG:27700",
    featureProjection: "EPSG:3857"
  });


  feature.set("objectid", zone.objectid);
  feature.set("code", zone.code);
  feature.set("Name", zone.name);

  return feature;
}

watch(reportDetailsRequest, async () => {
  if (!reportDetailsRequest.data) return;


  const map = new Map({
    layers: [basemapsGroup],
    target: mapRef.value as HTMLElement,
    view: new View({
      center: olProj.transform([-2.547859, 54.003644], "EPSG:4326", "EPSG:3857"),
      zoom: 13
    }),
    controls: defaults({
      attribution: true,
      zoom: true
    })
  });
  addLayerSwitcher(map);


  const zonesLoadingControl = addLoadingZonesText(map);

  const zonesSource = await createZonesSourceForDataset(reportDetailsRequest.data!, createFeatureFromZone, zonesLoadingControl);
  zonesSource.on("featuresloadstart", () => {
    canInteract.value = false
  });
  zonesSource.on("featuresloadend", () => {
    canInteract.value = true;

    //Set selected zones after map move
    zonesSource.getFeatures().forEach(zone => {
      const match = selectedZoneIds.value.includes(zone.get(featureIdProperty));
      if (match) {
        zone.set("Selected", true);
      }
    });

    //Re-do the heatmap
    if (Array.isArray(request.data?.Items)) {
      colours = makeColourset(valuesSlider.value.low, valuesSlider.value.high)
      clearAllFeatureParams(zonesLayer, "Magnitude");
      displayZoneData(request.data?.Items.filter(z => z.displayGroup >= valuesSlider.value.low && z.displayGroup <= valuesSlider.value.high));
    }

    if (reportDetailsRequest.data?.details?.selectedZones.length ?? 0 > 0) {
      //Set selected zones after map move
      zonesSource.getFeatures().forEach(zone => {
        const match = reportDetailsRequest.data?.details.selectedZones.includes(zone.get(featureIdProperty));
        if (match) {
          zone.set("Selected", true);
          const view = map.getView();
          view.fit(zone.getGeometry()!.getExtent());
          view.setZoom(view.getZoom()! - 1);
        }
      });
    }
  });

  const minZoom = 10;
  zonesLayer = createZonesLayer({ zonesSource, map, getShade: shadeFunction, zoneClickedCallback, zoneHoveredCallback, zonesLoadingControl, minZoom });
  map.addLayer(zonesLayer);
  addZoomRangeText(map, "Zoom in to show zones", 0, minZoom)

  if (reportDetailsRequest.data.details.pois.length > 0) {
    const poiCoords = olProj.transform(reportDetailsRequest.data.details.pois[0].coords, "EPSG:4326", "EPSG:3857");

    const { poiSource, poiLayer } = makePoiLayer();
    poiSource.addPoint(reportDetailsRequest.data.details.clientProjectName, poiCoords);
    map.getView().setCenter(poiCoords);
    map.getView().setZoom(13);
    map.addLayer(poiLayer);
  }

  watch(focusedZones, (newVal) => {
    zonesLayer.getSource().getFeatures().forEach(f => {
      if (newVal.includes(f.get(featureIdProperty))) {
        f.set("Focused", true);
      }
      else f.set("Focused", false);
    });
    zonesSource.dispatchEvent('change');
  }, { deep: true })

  addOverlayPopup(map, [{
    title: "Name",
    featureProperty: "name"
  }], popupWrapper.value!);

  const makePopup = (displayTitle?: string) => {
    removeAllOverlays(map);

    const fields = [{
      title: "Zone ID",
      featureProperty: featureIdProperty!
    },
    {
      title: "Name",
      featureProperty: "name"
    }];
    if (displayTitle) {
      fields.push(
        {
          title: displayTitle,
          featureProperty: "Magnitude"
        })
    }
    addOverlayPopup(map, fields, popupWrapper.value!);
  }

  makePopup(analysisMode.value?.magnitudeDisplayTitle?.value);

  watch(analysisMode, (newVal) => {
    resetMapMode();
    removeAllOverlays(map);
    if (!newVal) return;
    makePopup(newVal.magnitudeDisplayTitle.value);
  })
}, { deep: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!canInteract.value)
      ? (_openBlock(), _createBlock(PreventInteraction, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(MapControlMenu, {
        onSubmit: updateMap,
        onReset: resetFilters,
        onSelectAllZones: selectAllZones
      }),
      _createElementVNode("div", {
        ref: mapRef,
        class: "cl-ol-map"
      }, null, 512),
      _createElementVNode("div", { ref: popupWrapper }, null, 512),
      _createVNode(MapGradientLegend)
    ])
  ], 64))
}
}

})