import envConfig from "@/config/envConfig";
import { GetCurrentOrganisationDetailsResponse, Organisation } from "@/models/organisationsModels";
import { authenticatedRequest } from "@/composables/requestComposables";
import { computed } from "vue";
import { useRequest } from "../requestComposables";

const getOrgDetailsRequest = useRequest({
  requestCallback: () => {
    const url = `${envConfig.admin_address}/api/services/app/UserOrganisations/GetCurrentOrganisationDetails`;
    const headers = new Headers();

    return authenticatedRequest(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) throw new Error(data.error.details);
        return data.result as GetCurrentOrganisationDetailsResponse;
      });
  }
});

export const useOrganisationDetailsRequest = (triggerImmidately?: boolean) => {
  if (triggerImmidately) getOrgDetailsRequest.invoke();

  return {
    getOrgDetailsRequest
  }
}
export const useGetAllOrganisationsRequest = () => {
  const getAllOrganisationsRequest = useRequest({
    requestCallback() {
      const url = `${envConfig.admin_address}/api/services/app/Organisations/GetAllOrganisations`;
      const headers = new Headers();

      return authenticatedRequest(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) throw new Error(data.error.details);
          return data.result as Organisation[];
        }
        );
    },
    requestImmediately: true
  });

  return getAllOrganisationsRequest;
}

export const useOrganisationCreditsDisplay = () => {
  const credits = computed(() => getOrgDetailsRequest.data?.credits ?? '?');

  return {
    credits,
    refresh: () => getOrgDetailsRequest.invoke()
  }
}