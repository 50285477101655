import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"font-size":"xx-large","margin":"25px 10px"} }

import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import LineTabbedPanel from "@/components/LineTabbedPanel.vue";
import DataOverview from "./components/DataOverview/DataOverview.vue";
import Demographics from "./components/Demographics/Demographics.vue";
import Summary from "./components/Summary/Summary.vue";
import Visitors from "./components/Visitors/Visitors.vue";
import Journeys from "./components/Journeys/Journeys.vue";
import Mode from "./components/Mode/Mode.vue";
import Purpose from "./components/Purpose/Purpose.vue";
import Dwells from "./components/Dwells/Dwells.vue";
import Definitions from "./components/Definitions/Definitions.vue";
import Traversing from "./components/Traversing/Traversing.vue";
import { getQueryParams } from "@/util/urlHelpers";
import { computed, provide } from "vue";
import { useGetRequestedReportRequest } from "@/composables/data/reportComposables";



export default _defineComponent({
  setup(__props) {

const reportDetailsRequest = useGetRequestedReportRequest(getQueryParams()["datasetid"])

const availableTabs = computed(() => {
  if (!reportDetailsRequest.data) return [];

  const tabs = [
    { name: 'Data Overview', component: DataOverview },
    { name: 'Summary', component: Summary },
    { name: 'Visits', component: Visitors },
    { name: 'Journeys', component: Journeys }
  ];

  function optionSelected(name: string) {
    return reportDetailsRequest.data!.details.reportInputs.filter(ri => ri.name === name && ri.selectedOptions.filter(so => so.name === "Yes").length > 0).length > 0;
  }

  if (optionSelected("IncludeJourneyPurpose?")) tabs.push({ name: 'Purpose', component: Purpose });
  if (optionSelected("IncludeModeAnalysis?")) tabs.push({ name: 'Mode', component: Mode });
  if (optionSelected("IncludeDwellTimes?")) tabs.push({ name: 'Dwells', component: Dwells });
  if (optionSelected("IncludeDemographics?")) tabs.push({ name: 'Demographics', component: Demographics });
  if (optionSelected("IncludeDemographics?")) tabs.push({ name: 'Traversing', component: Traversing });

  tabs.push({ name: 'Definitions', component: Definitions });

  return tabs;
});


provide("reportDetailsRequest", reportDetailsRequest)
provide("minZoom", 10);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DataFetchDisplay, { request: _unref(reportDetailsRequest) }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, "Location Insights - " + _toDisplayString(_unref(reportDetailsRequest).data?.details.clientProjectName), 1),
      _createVNode(LineTabbedPanel, {
        "indexed-by-tab-name": true,
        tabs: _unref(availableTabs).map(tab => tab.name)
      }, _createSlots({ _: 2 }, [
        _renderList(_unref(availableTabs), (tab, index) => {
          return {
            name: tab.name,
            fn: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component)))
            ])
          }
        })
      ]), 1032, ["tabs"])
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})