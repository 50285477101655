import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"font-size":"large"} }
const _hoisted_2 = ["value"]

import envConfig from '@/config/envConfig';
import { authenticatedRequest } from "@/composables/requestComposables";
import Modal from "@/components/Modals/Modal.vue";
import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import { ref } from 'vue';
import { useModals } from "@/composables/modalComposables";
import { useGetAllOrganisationsRequest } from '@/composables/data/organisationComposables';


export default _defineComponent({
  props: {
    datasetId: {
        required: true,
        type: Number
    },
    currentOrganisationId: {
        required: true,
        type: Number
    },
    datasetName: {
        required: true,
        type: String
    }
},
  emits: ["success"],
  setup(__props, { emit }) {

const props = __props
const { openInfoModal, popModal } = useModals();




const selectedOrganisationId = ref(props.currentOrganisationId)

const getAllOrganisationsRequest = useGetAllOrganisationsRequest();

function changeOrganisation(newOrganisationId: number) {
    const url = `${envConfig.admin_address}/api/services/app/CustomerReport/UpdateReportOrganisation`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const body = JSON.stringify({
        newOrganisationId,
        requestedReportId: props.datasetId
    });

    return authenticatedRequest(url, { headers, method: "POST", body })
        .then((response) => response.json())
        .then((data) => {
            popModal();

            if (!data.success) {
                openInfoModal("Error", "Unable to change report organisation")
            } else
                emit("success");
        });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, { title: "Change organisation" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(__props.datasetName), 1),
      _createVNode(DataFetchDisplay, { request: _unref(getAllOrganisationsRequest) }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (selectedOrganisationId.value = $event)),
            class: "form-control",
            style: {"margin":"5px"}
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getAllOrganisationsRequest).data, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                key: item.id,
                value: item.id
              }, _toDisplayString(item.name), 9, _hoisted_2))
            }), 128))
          ], 512), [
            [_vModelSelect, selectedOrganisationId.value]
          ]),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (changeOrganisation(selectedOrganisationId.value))),
            class: "btn btn-success",
            style: {"margin":"5px"}
          }, "Submit")
        ]),
        _: 1
      }, 8, ["request"])
    ]),
    _: 1
  }))
}
}

})