import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import Modal from "./Modal.vue";


export default _defineComponent({
  props: {
  title: {
    type: String,
    required: true
  },
  body: {
    type: String,
    required: true
  }
},
  emits: ["update:open"],
  setup(__props, { emit }) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    title: __props.title,
    open: true,
    "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => (emit('update:open')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: {"white-space":"pre-wrap"},
        innerHTML: __props.body
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})