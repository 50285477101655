import { UserRequestedReport, RequestedReportDto, dtoToUserRequestedReport } from "@/models/customerReportsModels";
import { getFeatureFromWktFile } from "@/util/maps/mapUtils";
import { Feature } from "ol";
import { Polygon, Geometry, Point } from "ol/geom";
import * as olProj from "ol/proj";

const getPoiLocation = (reportRequest: UserRequestedReport) => {
  if (reportRequest.pois.length > 0) return { coords: reportRequest.pois[0].coords, epsg: "EPSG:4326" };
  else if (reportRequest.studyAreaWkt) {
    const studyAreaFeature = getFeatureFromWktFile(reportRequest.studyAreaWkt);
    const studyAreaPolygon = studyAreaFeature.getGeometry() as Polygon;
    return { coords: studyAreaPolygon.getInteriorPoint().getCoordinates(), epsg: "EPSG:3857" };
  } else throw "No location available";
}

type ReportWithScore = RequestedReportDto & {score?:number}
export const createReportMapFeatures = (reports: ReportWithScore[]) =>
  reports.map(r => {
    const reportRequest = dtoToUserRequestedReport(r);

    try {
      const { coords: pinLocation, epsg } = getPoiLocation(reportRequest);

      const pointGeom = new Point(olProj.transform(pinLocation, epsg, "EPSG:3857"));
      const pointFeature = new Feature({
        geometry: pointGeom,
        size: 1000,
      });

      pointFeature.set("Name", reportRequest.clientProjectName);
      pointFeature.set("ReportType", reportRequest.reportName);
      pointFeature.set("DatasetId", r.id);
      pointFeature.set("ZIndex", r.id);
      pointFeature.set("Score", r.score)

      if (r.customerReport?.icon) {
        pointFeature.set('FaIcon', r.customerReport?.icon)
      }

      return pointFeature;

    } catch (e) {
      console.error(`No location found for report ${reportRequest.clientProjectName}`)
      return null
    }
  }).filter(x => x !== null) as Feature<Geometry>[];
