import envConfig from "@/config/envConfig";
import { useRequest } from "@/composables/requestComposables";
import { DatasetFilter } from "@/models/mapViewerModels";
import { MapZoneMagnitude } from "@/util/maps/mapZoneUtils";
import { authenticatedRequest } from "@/composables/requestComposables";
import { computed, ref } from "vue";
import {  analysisModes } from "./analysisModes";
import { getQueryParams } from "@/util/urlHelpers";


const analysisModeControl = ref<DatasetFilter>({
  name: "Analysis Mode",
  multiSelect: false,
  options: analysisModes.map((am, i) => ({
    value: i,
    label: am.name,
    description: am.description
  })),
  selectedValues: []
});

const analysisMode = computed(() => analysisModes[analysisModeControl.value.selectedValues[0] as number]);
const canFilterByZone = computed(() => {
  if (!analysisMode.value) return false;
  return analysisMode.value.getSelectableZoneIds !== undefined
});

export function useAnalysisFilter() {
  return {
    analysisModeControl,
    analysisMode,
    canFilterByZone
  }
}

const valuesSlider = ref({
  low: 0,
  high: 9,
  min: 0,
  max: 9
});

export function useValuesSlider() {
  return { valuesSlider };
}

export type StdLocationRequestResponse = { Items: MapZoneMagnitude[], Classes: number }
const request = useRequest({
  requestCallback: (selectedZoneIds: number[]) => {
    const endpoint = analysisMode.value.endpoint;
    const queryParams = analysisMode.value.getQueryParams(selectedZoneIds) + `&datasetid=${getQueryParams()['datasetid']}`;

    const url = encodeURI(`${envConfig.api_address}/datasetsapi/locationinsightsmap/${endpoint}${queryParams}`);

    return authenticatedRequest(url, { headers: new Headers() })
      .then((response) => response.json())
      .then((data) => data.Results as StdLocationRequestResponse)
      .catch(() => { throw new Error("There was a problem with the request") });
  },
  errorModal: true,
});


export function useStdLocationMapRequest() {
  return {
    request
  }
}

const findGroupIndex = (groups: number[], mag: number) => {
  let index = 0;
  groups.forEach((p, i) => {
    const lower = p;
    const upper = groups[i + 1] !== undefined ? groups[i + 1] : Infinity;
    if (mag >= lower && mag <= upper) {
      index = i
    }
  });
  return index;
}