import { createApp } from "vue";
import App from "@/App.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import pages, { getAllPages } from "./pages/pageConfig";
import validationDirectives from "./util/directives/validationDirectives";
import envConfig from "./config/envConfig";
import { getCookie } from "./util/cookieHelpers";

import { tooltipDirective } from "./util/directives/tooltipDirective";
import { checkAuthenticated } from "./composables/appComposables";

const subPages = pages.flatMap(p => p.subPages).filter(p => p !== undefined);
const pagesAndSubPages = [...pages, ...subPages]

const routes = pagesAndSubPages.map(page => ({
    path: `/${page!.title.replace(/ /g, "_")}`,
    name: page!.title,
    component: page!.view
  }));

const router = createRouter({ history: createWebHistory(), routes: routes as RouteRecordRaw[] });
const app = createApp(App);
app.use(router);

const paths = routes.map(r => r.path)

if (paths.length !== new Set(paths).size) throw "It seems there are multiple pages with the same name. Please correct this.";

for (const directiveName of Object.keys(validationDirectives)) {
  app.directive(directiveName, validationDirectives[directiveName])
}
app.directive("tooltip", tooltipDirective);
app.mount("#app");

if (envConfig.environment === "Production") {
  app.config.errorHandler = () => null;
  app.config.warnHandler = () => null;
} else {
  app.config.errorHandler = (err) => console.error(err);
}

router.beforeEach((to, _from, next) => {
  if (to.path === "/Reset" || to.path === "/Login") next();

  checkAuthenticated();
  const pages = getAllPages(getCookie("userLevel"));
  if (pages.length === 0) next("No_Access");

  if (!pages.map(p => "/" + p.title.replace(/ /g, "_")).includes(to.path)) {
    const firstPage = pages.filter(p => p.menuItem === true)[0];
    next(firstPage.title.replace(/ /g, "_"));
  } else {
    next();
  }
});
