<template>
  <div class="portlet light">
    <div class="portlet-body">
      <h2>Mobile Network Data</h2>
      <p>Mobile network data is the largest sensor network in the UK that few people think about. Every mobile device
        acts
        as a sensor, providing information on its owner's movements, continuously, as long as the device is on.</p>
      <p>Read more about MND here: <a href="https://www.citilogik.com/mobile-network-data">The Ultimate Guide to Mobile
          Network Data</a></p>
      <p>The guide below gives a brief overview of the methodology used to process mobile network data. Citi Logik has
        pioneered the use of this technology since 2012. Through hundreds of projects, the data and approaches have been
        validated by numerous engineering consultancies. Citi Logik also co-authored the Department for Transport's
        paper on
        Utilising Mobile Network Data for Transport Modelling.</p>
      <p>Please note, in most cases this guide offers a simplified explanation of the methodology and algorithms to
        protect
        Citi Logik's intellectual property.</p>

      <h2>Mobile Phone Data Collection</h2>

      <h3>Data Collection Period</h3>
      <p>Mobile network data (MND) is collected on a whole calendar month basis. Typically, Citi Logik receives data
        from
        the network operator around ten days after the end of the previous month. The data must then be processed before
        it
        can be used in the portal, which usually takes a few more days.</p>

      <h3>Total Events Observed</h3>
      <p>Mobile devices communicate their positions with the network of cells every time they are used to text or make a
        call and through ad-hoc events generated through applications running on smartphone devices, such as web
        searching,
        location services etc. Each such communication, made by a unique and anonymous user ID, is registered by one of
        the
        network cells as an 'event' with a timestamp, a local area code and the cell's ID being attributed to it.</p>
      <p>Each device generates hundreds of events per day, which equates to billions of events across the country and
        all
        devices.</p>

      <h3>Cell Types</h3>
      <table class="datatable-monospaced" style="max-width:fit-content;margin-bottom:15px">
        <tr>
          <th>Urbanity</th>
          <th>Average cell coverage (metres)</th>
          <th>Proportion of total</th>
        </tr>
        <tr>
          <td>Dense Urban</td>
          <td>488</td>
          <td>9%</td>
        </tr>
        <tr>
          <td>Rural</td>
          <td>8,055</td>
          <td>18%</td>
        </tr>
        <tr>
          <td>Suburban</td>
          <td>837</td>
          <td>18%</td>
        </tr>
        <tr>
          <td>Peri-Urban</td>
          <td>1,639</td>
          <td>55%</td>
        </tr>
      </table>

      <h2>Trip Definition</h2>
      <p>This section describes how MND raw events are analysed to convert these into trips for each device.</p>

      <h3>Dwell Time</h3>
      <p>Dwell time refers to the time during which events for a particular mobile device are registered by the same
        cell,
        before transitioning to a different cell - i.e., they are stationary within a cell. If the stationary time for a
        certain mobile device exceeds a minimum time threshold (currently set to 30 minutes), then its user experiences
        a
        'dwell' and is deemed to be 'static' within the coverage area of the 'dwell cell'. </p>
      <p>Therefore, a 'trip' (or a 'movement') for a mobile device's user is measured between any two of its adjacent
        dwells
        - from the time of the last event registered in the trip starting dwell cell until the time of the first event
        registered within the finishing dwell cell.</p>
      <img src="@/assets/images/methodology_diagram_1.png" />
      <p>Although it is acknowledged that the use of a blanket minimum dwell time threshold may have its limitations for
        counting trips, it is not currently feasible to effectively establish and process such thresholds for thousands
        of
        individual cells and different types of travellers. The most appropriate threshold was selected as a result of
        many
        tests run by Citi Logik.</p>
      <p>These tests consisted of following specific, volunteered, Citi Logik staff devices over a 2-month period,
        providing
        statistically robust results, and comparing trip patterns from the point of view of trip generation,
        distribution of
        origins and destinations and identification of home and work locations. The data were processed under several
        dwell
        time thresholds with findings showing that a 30-minute dwell time provided the closest results to observations.
      </p>
      <p>There could be instances in which users of mobile devices are constantly moving within the coverage area of the
        same cell and are therefore perceived as 'static', or users may stop their journeys for shorter periods than the
        identified thresholds. These are respectively limitations of MND and the current methodology.</p>

      <h3>Base Cell Journey Start/ Finish Catchment Radii</h3>
      <p>Citi Logik infers the home and work location for each mobile device based on patterns of dwell behaviour during
        the
        month (see the section on Purpose below for more information).</p>
      <p>Origins and destinations of journeys are registered by a single cell and located under its coverage area.
        Coverage
        areas of different cells overlap to provide sufficient bandwidth signal. The fact that a dwell was captured by
        one
        cell or another overlapping one should not interfere with the home and trip end identification. For the purposes
        of
        home and work location, and purpose identification (home-based trip or work-related trip), a 1500m catchment
        area is
        defined around the centroid of the area defined by the cells that make up the home and work locations. Any trip
        or
        dwell identified within that area will be labelled accordingly as home or work.</p>

      <h2>Mode Identification</h2>
      <h3>Mode Categories</h3>
      <p>The first step of trip-mode allocations splits the trips into one of the following three categories based on
        journey lengths, travel speed, and more:</p>
      <ul>
        <li>
          <span style="font-weight:bold">Static trips.</span> These refer to mobile devices not moving for more than 30
          minutes or moving within the coverage area of a single cell.
        </li>
        <li>
          <span style="font-weight:bold">Active-mode trips.</span> These refer to mobile devices that travel between the
          trip starting and trip-finishing cells at low speeds (distance based on cell to cell path, including
          intermediate
          cells) and are likely to represent movements made by pedestrians and cyclists.
        </li>
        <li>
          <span style="font-weight:bold">Motorised trips.</span> These relate to mobile devices travelling between
          trip-starting and trip-finishing cells at higher speeds or for long distances and are likely to represent
          movements by motorised vehicles such as car, LGV, HGV, bus, coach or rail. Motorised trips are then split into
          rail-based and road-based trips. Further details on the mode allocation are given below.
        </li>
      </ul>

      <h3>Active Mode Trips</h3>
      <p>Internal research undertaken by Citi Logik to calibrate the proportion of active and motorised trips against
        national travel survey (NTS), led to the development of a classifier for active modes based on the following
        methodology:</p>
      <ul>
        <li>
          <span style="font-weight:bold">Mode behaviour analysis</span> was performed for walkers, cyclists in
          opposition to
          motorists and rail riders based on speed, trip length, and trip duration.
        </li>
        <li>
          The probability distributions based on the three data points above were estimated to assign an <span
            style="font-weight:bold">active-trip probability</span> to each of the trip observations.
        </li>
        <li>
          <span style="font-weight:bold">Acceptance probability thresholds</span> were worked out by density area and
          region
          in order to allocate the expected number of active trips for a neutral month in a certain region, in
          accordance
          with NTS.
        </li>
        <li>
          These thresholds were then applied to all months and all years, capturing the expected trend and seasonality
          changes.
        </li>
      </ul>

      <p>For completeness, density categories are defined according to the population and with cell proximity, as
        follows:
      </p>
      <ul>
        <li>Urban</li>
        <li>Peri-Urban</li>
        <li>Suburban</li>
        <li>Rural</li>
      </ul>
      <p>Trips not identified as active mode, are considered to be motorised trips, to be further split into rail-based
        and road-based trips.</p>

      <h3>Rail Trips</h3>
      <p>Citi Logik has developed a methodology to separate rail trips from other motorised trips through the analysis
        of the frequency devices interact with cells around the rail network and rail stations throughout their journey.
        As part of this process, buffer regions are defined around the rail network and train stations, to facilitate
        the identification of cells with which devices traversing the network, or at train stations, are most likely to
        interact. Such buffer regions are treated as 'zones' and play a crucial role in the identification of rail
        trips, as follows:</p>

      <ul>
        <li>A process similar to snap-to-zone (see Cell to Location Conversion chapter) is run on the rail network and
          train station 'zones', respectively, in order to identify sets of 'railway cells' and 'station cells' with
          which devices traversing along the rail network, as well as those in the vicinity of rail stations, are likely
          to interact.</li>
        <li>A preliminary set of rail trips is selected based on the interaction of journey legs with the aforementioned
          railway and station cells, as well as the distance and duration of each trip. The minimum distance and
          duration have been calibrated to 5 minutes and 1 kilometre, respectively, but these can be updated if
          necessary.</li>
        <li>The preliminary set of rail trips are subsequently refined to remove redundant and invalid trips. The degree
          of refinement varies by locations and project since cell density and coverage are not uniformly distributed
          throughout the rail network. In general, the refinements done are intended to:
          <ol>
            <li>Minimise to the number of flip-flops (i.e. where consecutive legs of a journey interact with a few
              cells) between railway and station cells along rail journeys</li>
            <li>Ensure that identified rail journeys interact with at least two sets of station cells</li>
            <li>Ensure that two consecutive stations with which a trip interacts exceed a minimum distance.
              This helps to reduce incidences of short trips, most likely to be done by active modes and not likely to
              be rail related.</li>
          </ol>
        </li>
        <li> Finally, the refined set of trips are classified as rail trips. The remaining trips, i.e., those not
          classified as active mode or rail, are classified as road-based trips.</li>
      </ul>

      <h2>Purpose Identification</h2>
      <p>Purpose is one of the key elements attached to a trip; it explains to a certain extent why a trip is made. This
        section details how a purpose is calculated for a trip.</p>

      <h3>Purpose Categories</h3>
      <p>The inferred work and home location of a device is used to assign the trip origin and destination (OD) to home,
        work or other.</p>
      <p>The trip's purpose is derived from rules relating to the trip OD combinations, such as home to work, other to
        home, etc. All trips have been allocated one of the following four trip purposes:</p>

      <ol>
        <li>Home ⇔ Work</li>
        <li>Home ⇔ Other</li>
        <li>Work ⇔ Other</li>
        <li>Other ⇔ Other</li>
      </ol>
      <p>During this process, the trip directionality has also been added for the home-based trips (from home/to home).
        This can be found in the spreadsheet attached to the dataset.</p>
      <p>The inference of home and work are thus critical to the correct definition of purposes. People have
        increasingly complex working patterns - partly working from home or working at different locations, hence access
        to a single work location (or cell) is not sufficient.</p>
      <p>The home and work locations are defined as the sets of cells clustering within 1500m of the most common home
        and work cells respectively. The most common home and work cells are derived from night and day dwelling
        patterns.</p>

      <h2>Trips Expansion</h2>
      <p>Expanding the trips is the exercise of generalising the sample trips (i.e., devices trips) to full population
        trips. Each device represents a certain number of people depending on the ratio found in the residency area
        between population and local devices. This ratio is calculated taking into account the mobile phone penetration
        by age band. According to Ofcom data, people in certain age bands are more likely to own (carry) a mobile device
        than other age bands like elderly people who are less likely to be carrying one. To achieve better-expanded
        data, areas of the country where the elderly make a greater proportion of the population would need to have a
        higher expansion factor.</p>

      <h2>Verifications</h2>
      <p>As part of Citi Logik's quality assurance processes, a number of verifications have been undertaken on the OD
        matrix trips - derived from MND movements - to confirm that the outputs given at a zone level are suitable. The
        purpose of these verifications is to check that the Citi Logik processes have been applied correctly and to
        highlight any deficiencies owing to the underlying MND. Verifications include the following:</p>

      <ul>
        <li>Average 24hr Unexpanded Day Travel Flow</li>
        <li>Device Trip Rates</li>
        <li>Average 24hr Day Total Travel Flow</li>
        <li>Mode Symmetry (slow modes symmetry, motorised symmetry, etc.)</li>
        <li>Purpose Symmetry (symmetry test for all Home ⇔ Work trips, etc.)</li>
        <li>Trip Length Distributions</li>
        <li>Home ⇔ Work Outbound Versus Inbound by Time of Day (and other purposes)</li>
        <li>All Purpose Trips by Time of Day</li>
        <li>Correlation between Trips and Population</li>
        <li>HGV</li>
      </ul>
    </div>
  </div>
</template>