import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1412b30f")
const _hoisted_1 = ["min", "max", "value", "disabled"]
const _hoisted_2 = ["min", "max", "value", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "range_min light left"
}
const _hoisted_4 = {
  key: 1,
  class: "range_max light right"
}
_popScopeId()

import { computed, PropType, ref, watch, watchEffect } from 'vue';

type MinMaxProp = {
  value: number,
  tag?: string
}

export default _defineComponent({
  props: {
  min: {
    type: Object as PropType<MinMaxProp>,
    required: true
  },
  max: {
    type: Object as PropType<MinMaxProp>,
    required: true
  },
  low: {
    type: Number,
    required: true
  },
  high: {
    type: Number,
    required: true
  },
  noLabel: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
},
  emits: ["update:low", "update:high"],
  setup(__props, { emit }) {

const props = __props



const val1 = ref(props.min.value);
const val2 = ref(props.max.value);

const lowVal = computed(() => Math.min(val1.value, val2.value))
const highVal = computed(() => Math.max(val1.value, val2.value))

watch(props, () => {
  val1.value = props.low;
  val2.value = props.high;
});

watchEffect(() => {
  const flip = val1.value < val2.value;
  const lowVal = flip ? val1.value : val2.value;
  const highVal = flip? val2.value : val1.value;

  emit("update:low", lowVal);
  emit("update:high", highVal);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rangeslider", {'rangeslider-disabled': __props.disabled}])
  }, [
    _createElementVNode("input", {
      class: "min",
      name: "range_1",
      type: "range",
      min: __props.min.value,
      max: __props.max.value,
      value: val1.value,
      disabled: __props.disabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (val1.value = parseInt(($event.target as HTMLInputElement).value)))
    }, null, 40, _hoisted_1),
    _createElementVNode("input", {
      class: "max",
      name: "range_2",
      type: "range",
      min: __props.min.value,
      max: __props.max.value,
      value: val2.value,
      disabled: __props.disabled,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (val2.value = parseInt(($event.target as HTMLInputElement).value)))
    }, null, 40, _hoisted_2),
    (!__props.noLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.min.tag || _unref(lowVal)), 1))
      : _createCommentVNode("", true),
    (!__props.noLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.max.tag || _unref(highVal)), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})