export function getDatasetAccessDisplay(datasetAccess: number) {
  return {
    1: "View Own",
    2: "View All"
  }[datasetAccess] || "";
}
export interface Organisation {
  id: number,
  name: string
}

interface UserOrganisation {
  userId: number,
  organisationId: number,
  organisation: Organisation,
  datasetAccess: number,
  canSpendCredits: boolean,
  isPrimaryUser: boolean
}

export interface SubUser {
  name: string,
  emailAddress: string,
  status: string,
  userOrganisation: UserOrganisation
}

export interface GetCurrentOrganisationDetailsResponse {
  organisationName: string,
  isPrimaryUser: boolean,
  subUsers: SubUser[],
  credits: number
}