import { DefineComponent, markRaw, reactive, readonly, h, VNode } from "vue";
import ChangePasswordModal from "@/components/Modals/ChangePasswordModal.vue";
import TermsAndConditionsModal from "@/components/Modals/TermsAndConditionsModal.vue";
import InfoModal from "@/components/Modals/InfoModal.vue";

const modalComponents = reactive<(VNode | DefineComponent<{}, {}, any>)[]>([]);

export function useModals() {
  const pushModal = (component: any) => modalComponents.push(markRaw(component));
  const popModal = () => modalComponents.pop();

  const openInfoModal = (title: string, body: string) => pushModal(h(InfoModal, { title, body }));
  const openChangePasswordModal = () => pushModal(ChangePasswordModal);
  const openTermsAndConditionsModal = () => pushModal(TermsAndConditionsModal);

  return {
    modalComponents: readonly(modalComponents),
    pushModal,
    popModal,
    openInfoModal,
    openChangePasswordModal,
    openTermsAndConditionsModal
  }
}