import { Color, Container, iRGB, Slice, } from "@amcharts/amcharts4/core";
import { ColumnSeries, LineSeries, PieSeries } from "@amcharts/amcharts4/charts";

export const rosieIRGB: iRGB = { r: 222, g: 22, b: 82 };
export const burgundyIRGB: iRGB = { r: 177, g: 17, b: 65 };
export const matisseIRGB: iRGB = { r: 25, g: 101, b: 160 };
export const pickedBluewoodIRGB: iRGB = { r: 50, g: 65, b: 88 };
export const glacierIRGB: iRGB = { r: 109, g: 177, b: 191 };
export const limeadeIRGB: iRGB = { r: 83, g: 180, b: 3 };
export const seaBuckthorn: iRGB = { r: 245, g: 143, b: 41 };

export const multipleSeriesColors: Color[] = [
  new Color(rosieIRGB),
  new Color(matisseIRGB),
  new Color(limeadeIRGB),
  new Color(seaBuckthorn),
  new Color(pickedBluewoodIRGB),
  new Color(glacierIRGB),
  new Color(burgundyIRGB)
];

//Creates a colour palette from a range of darker & lighter values around a base colour
export function getColorScaleFunction(seriesLength: number, startColor: iRGB = rosieIRGB) {
  const interval = (1 / (seriesLength + 1));
  const scaledInterval = interval * 2;

  return (_: unknown, target: Container) => {
    const index = target!.dataItem!.index;
    const scaledIndex = seriesLength - index;

    return new Color(startColor).lighten((scaledIndex * scaledInterval) - 1);
  }
}

export function getColorScale(seriesLength: number, startColor: iRGB = rosieIRGB) {
  const interval = (1 / (seriesLength + 1));
  const scaledInterval = interval * 2;

  const colors: Color[] = [];

  for (let index = 0; index < seriesLength; index++) {
    const scaledIndex = index + 1;
    colors.push(new Color(startColor).lighten((scaledIndex * scaledInterval) - 1));
  }

  return colors
}


export function setPortalColumnSeriesStyle(series: ColumnSeries, color: iRGB = rosieIRGB) {
  series.stroke = new Color(color);
  series.fill = new Color(color);
  series.strokeWidth = 3;
  series.fillOpacity = 1;
}

export function setPortalPieSeriesStyle(series: PieSeries, seriesLength: number) {
  series.slices.template.adapter.add("fill", getColorScaleFunction(seriesLength));
  series.slices.template.stroke = new Color({ r: 255, g: 255, b: 255 });
  series.slices.template.strokeWidth = 5;
  series.slices.template.strokeOpacity = 1;
}