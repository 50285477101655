import { DatasetFilter } from "@/models/mapViewerModels";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { computed, ref } from "vue";
import { AnalysisMode } from "./types";


const periodsControl = ref<DatasetFilter>({
  name: "Period Selection",
  multiSelect: true,
  options: [
    { value: 1, label: "Weekday - Off Peak", },
    { value: 2, label: "Weekday - AM", },
    { value: 3, label: "Weekday - Inter Peak", },
    { value: 4, label: "Weekday - PM", },
    { value: 5, label: "Weekend - Off Peak", },
    { value: 6, label: "Weekend - AM", },
    { value: 7, label: "Weekend - Inter Peak", },
    { value: 8, label: "Weekend - PM", },
  ],
  selectedValues: []
});

const daytypeControl = ref<DatasetFilter>({
  name: "Day type",
  multiSelect: true,
  options: [
    { value: 0, label: "Weekday" },
    { value: 1, label: "Weekend" }
  ],
  selectedValues: []
});

const dwellTimeControl = ref<DatasetFilter>({
  name: "Dwell Time",
  multiSelect: true,
  options: [
    { value: 0, label: "Short Visit" },
    { value: 1, label: "Medium Visit" },
    { value: 2, label: "Long Visit" }
  ],
  selectedValues: []
});

const industryControl = ref<DatasetFilter>({
  name: "Industry",
  multiSelect: true,
  options: [
    { value: "Total Retail", label: "Total Retail" },
    { value: "Eating Places", label: "Eating Places" },
    { value: "Total Apparel", label: "Total Apparel" }
  ],
  selectedValues: []
});

const paymentsTypeControl = ref<DatasetFilter>({
  name: "Payments Type",
  multiSelect: false,
  options: [
    {value: 0, label: "Count"},
    {value: 1, label: "Amount"}
  ],
  selectedValues: [0],
  required: true
})

const studyAreaZones = Array.from([...Array(1102 - 119).keys()].map(v => v + 119));
const getFilterValues = (filter: DatasetFilter) => filter.selectedValues.length > 0 ? filter.selectedValues : filter.options.map(o => o.value);

export const analysisModes: AnalysisMode[] = [
  new AnalysisMode(
    "Total Visitors",
    "Shows the total number of people in each zone. Data: MND",
    [periodsControl],
    "totaltourists",
    () => {
      const periods = getFilterValues(periodsControl.value);
      return `?timeperiod=${periods}`;
    },
    computed(() => "Tourists")
  ),

  new AnalysisMode(
    "Origins",
    "Shows the origin zones of journeys to selected zone(s) within the study area. Data: MND",
    [periodsControl],
    "origins",
    (zoneIds: number[]) => {
      const periods = getFilterValues(periodsControl.value);
      return `?timeperiod=${periods}&zones=${zoneIds}`;
    },
    computed(() => "Tourists"),
    () => studyAreaZones
  ),

  new AnalysisMode(
    "Destinations",
    "Shows the destination zones of journeys from selected zone(s) outside the study area. Data: MND",
    [periodsControl],
    "destinations",
    (zoneIds: number[]) => {
      const periods = getFilterValues(periodsControl.value);
      return `?timeperiod=${periods}&zones=${zoneIds}`;
    },
    computed(() => "Tourists"),
    (features: Feature<Geometry>[]) => features.map(f => f.get("NCZID"!) as number).filter(x => !studyAreaZones.includes(x))
  ),

  new AnalysisMode(
    "Dwell Time",
    "Shows the number of people in each zone by the length of time they dwelled. Data: MND",
    [periodsControl, dwellTimeControl],
    "dwelltime",
    () => {
      const periods = getFilterValues(periodsControl.value);
      const dwellTimes = getFilterValues(dwellTimeControl.value);
      return `?timeperiod=${periods}&dwelltime=${dwellTimes}`;
    },
    computed(() => "Tourists"),
  ),

  new AnalysisMode(
    "Mastercard Payments",
    "Shows payment transaction data as an index against a baseline average. Data: Payments",
    [industryControl, daytypeControl, paymentsTypeControl],
    "totalpayments",
    () => {
      const industries = getFilterValues(industryControl.value);
      const dayTypes = getFilterValues(daytypeControl.value);
      return `?industries=${industries}&daytypes=${dayTypes}&paymentstype=${paymentsTypeControl.value.selectedValues}`
    },
    computed(() => "Payments"),
    undefined
  ),

  new AnalysisMode(
    "Blended Hotspots",
    "Shows where a combined index of payments and people data has high values. i.e., high payments + high people Data: MND & Payments Combined",
    [industryControl, daytypeControl, paymentsTypeControl],
    "blendedhotspots",
    () => {
      const industries = getFilterValues(industryControl.value);
      const dayTypes = getFilterValues(daytypeControl.value);
      return `?industries=${industries}&daytypes=${dayTypes}&paymentstype=${paymentsTypeControl.value.selectedValues}`
    },
    computed(() => "Value"),
    undefined
  ),

  new AnalysisMode(
    "Blended Correlation",
    "Shows where payments and people indexes are highly correlated. i.e., low payments + low people OR high payments + high people Data: MND & Payments Combined",
    [industryControl, daytypeControl, paymentsTypeControl],
    "blendedcorrelation",
    () => {
      const industries = getFilterValues(industryControl.value);
      const dayTypes = getFilterValues(daytypeControl.value);
      return `?industries=${industries}&daytypes=${dayTypes}&paymentstype=${paymentsTypeControl.value.selectedValues}`
    },
    computed(() => "Value"),
    undefined
  ),

  new AnalysisMode(
    "Blended High Spend Ratio",
    "Shows where there is a high ratio of payments to people data. i.e., high payments + low people Data: MND & Payments Combined",
    [industryControl, daytypeControl, paymentsTypeControl],
    "blendedhighspendratio",
    () => {
      const industries = getFilterValues(industryControl.value);
      const dayTypes = getFilterValues(daytypeControl.value);
      return `?industries=${industries}&daytypes=${dayTypes}&paymentstype=${paymentsTypeControl.value.selectedValues}`
    },
    computed(() => "Value"),
    undefined
  ),

  new AnalysisMode(
    "Blended Low Spend Ratio",
    "Shows where there is a low ratio of payments to people data. i.e., low payments + high people Data: MND & Payments Combined",
    [industryControl, daytypeControl, paymentsTypeControl],
    "blendedlowspendratio",
    () => {
      const industries = getFilterValues(industryControl.value);
      const dayTypes = getFilterValues(daytypeControl.value);
      return `?industries=${industries}&daytypes=${dayTypes}&paymentstype=${paymentsTypeControl.value.selectedValues}`
    },
    computed(() => "Value"),
    undefined
  )
];