import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

import "ol/ol.css?vue&type=style&index=0&lang=css"
import "ol-layerswitcher/src/ol-layerswitcher.css?vue&type=style&index=1&lang=css"
import "@vueform/multiselect/themes/default.css?vue&type=style&index=2&lang=css"
import "./App.vue?vue&type=style&index=3&id=12dc0664&lang=css"

const __exports__ = script;

export default __exports__