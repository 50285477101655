<template>
  <div class="portlet light">
    <div class="portlet-title">
      <h1>No Access</h1>
    </div>
    <div class="portlet-body">
      <p>It seems that you have an account but you haven't been granted access to the People Movement Portal.</p>
      <p>
        Perhaps you are looking for our other web platform
        <a href="https://web.peoplemovementportal.com" target="_blank">CitiAnalytics</a>.
      </p>
      <p>
        If not, please contact
        <a href="mailto:christopher.bax@amey.co.uk">christopher.bax@amey.co.uk</a>.
      </p>
    </div>
  </div>
</template>