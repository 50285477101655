<template>
  <div style="padding:0 5px">
    <h2>Definitions</h2>
    <hr>

    <h3>Visits</h3>
    <p>A visit is defined as a trip with a destination at the point or area
      of interest. One person can make multiple
      visits a day. Intra-zonal trips at the point of interest are discarded.</p>

    <p><span style="font-weight:bold">Average daily visits</span> The average number of visits (trip destinations) made
      to the point of interest on a particular day.</p>

    <p><span style="font-weight:bold">Average hourly visits</span> The average number of visits (trip destinations) made
      to the point of interest at a particular hour of the day.
    </p>

    <p><span style="font-weight:bold">Repeat visit</span> If a person makes more than one visit to the point of interest
      on the same calendar week, all visits made by that
      person on that week are categorised as repeated.</p>

    <p><span style="font-weight:bold">Visitors by journey distance</span> The total number of visitors in each distance
      band.</p>
    <hr>

    <h3>Purpose</h3>
    <p>The purpose of the visit classifies the visit as Home ⇔ Work, Home ⇔ Other, Work ⇔ Other, Other ⇔ Other.</p>

    <p><span style="font-weight:bold">Home ⇔ Work</span> A Home ⇔ Work visit is defined as a visit from
      home to work and from work to home.</p>

    <p><span style="font-weight:bold">Home ⇔ Other</span> A Home ⇔ Other visit is defined as a visit with
      the home location at either end of the trip.</p>

    <p><span style="font-weight:bold">Work ⇔ Other</span> A Work ⇔ Other visit is defined as a
      visit to or from the work location.</p>

    <p><span style="font-weight:bold">Other ⇔ Other</span> An Other ⇔ Other visit is defined as a
      visit to and from a non-work, non-home location.</p>
    <hr>

    <h3>Mode</h3>
    <p>The mode of transport used to pay the visit to the point or area of interest are the following.</p>

    <p><span style="font-weight:bold">Active</span> Active mode is primarily made up of on foot visits and cyclists.
      Other types of slow speed mode make up the
      remainder of the active trips e.g. electric scooters.</p>

    <p><span style="font-weight:bold">Road</span> Visits classified as road are primarily made up of light
      transportation vehicles (cars and light goods vehicles)
      as well as bus and public transport modes except for rail trips.</p>

    <p><span style="font-weight:bold">Rail</span> Rail visits are visits that used the National Rail infrastructure for
      at least 10% of the trip.</p>

    <p><span style="font-weight:bold">HGV</span>Visits made by heavy goods vehicles (HGV).</p>
    <hr>

    <h3>Dwell</h3>
    <p>A dwell is defined as a stop in a person's journey itinerary that is longer than 30 minutes. <span
        style="font-weight:bold">Residents of the area are excluded from the dwelling analysis.</span></p>

    <p><span style="font-weight:bold">Average dwell time by day</span> The average number of hours dwelled at the point
      or area of interest on a particular day of the week.</p>

    <p><span style="font-weight:bold">Average dwell time by start time</span> The average number of hours dwelled at the
      point or area of interest, grouped by the starting hour of the dwell.
    </p>

    <p><span style="font-weight:bold">Average number of dwellers by hour</span> The average number of people dwelling at
      the point or area of interest in each of the hours of the day.</p>

    <hr>

    <h3>Demographics</h3>

    <p><span style="font-weight:bold">Average visits by age band</span>The average number of visits grouped by each of
      the age bands available.</p>

    <p><span style="font-weight:bold">Visitors by gender</span> The total number of visits by each gender definition.
    </p>
  </div>
</template>