import { Component } from "vue";
import { UserLevel } from "@/composables/appComposables";

//View components
import NoAccess from "./NoAccess/NoAccess.vue";
import DatasetConfigurator from "./DatasetConfigurator/DatasetConfigurator.vue";
import OrganisationCredits from "./OrganisationCredits/OrganisationCredits.vue";
import CreateDataset from "./CreateDataset/CreateDataset.vue";
import MyDatasets from "./MyDatasets/MyDatasets.vue";
import DatasetWizard from "./DatasetWizard/DatasetWizard.vue";
import MyAccount from "./MyAccount/MyAccount.vue";

import ContactSupport from "./HelpAndSupport/ContactSupport/ContactSupport.vue";
import Welcome from "./HelpAndSupport/Welcome/Welcome.vue";
import MobileNetworkDataMethodology from "./HelpAndSupport/MobileNetworkDataMethodology/MobileNetworkDataMethodology.vue";
import ReleaseNotes from "./HelpAndSupport/ReleaseNotes/ReleaseNotes.vue";

import TourismAndEconomyMap from "./DatasetViewers/TourismAndEconomy/Map/TourismAndEconomyMap.vue";
import LocationInsightsReport from "./DatasetViewers/LocationInsights/Report/LocationInsightsReport.vue";
import LocationInsightsMap from "./DatasetViewers/LocationInsights/Map/LocationInsightsMap.vue";
import FootfallAnalysisReport from "./DatasetViewers/FootfallAnalysis/Report/FootfallAnalysisReport.vue";
import EVChargepointInsightsReport from "./DatasetViewers/EVChargepointInsights/Report/EVChargepointInsightsReport.vue";
import EVChargepointInsightsMap from "./DatasetViewers/EVChargepointInsights/Map/EVChargepointInsightsMap.vue";

export interface PortalPage {
  view?: Component;
  link?: string;
  icon: string;
  title: string;
  menuItem: boolean;
  level: UserLevel;
  subPages?: PortalPage[]
}

const pages: PortalPage[] = [
  {
    view: TourismAndEconomyMap,
    icon: "fa fa-map",
    title: "Tourism And Economy Viewer Map",
    menuItem: false,
    level: "User"
  },
  {
    view: LocationInsightsReport,
    icon: "fa fa-location-dot",
    title: "Location Insights Report",
    menuItem: false,
    level: "User"
  },
  {
    view: LocationInsightsMap,
    icon: "fa fa-location-dot",
    title: "Location Insights Map",
    menuItem: false,
    level: "User"
  },
  {
    view: FootfallAnalysisReport,
    icon: "fa fa-location-dot",
    title: "Footfall Analysis",
    menuItem: false,
    level: "User"
  },
  {
    view: EVChargepointInsightsReport,
    icon: "fa fa-chart-simple",
    title: "EV Chargepoint Insights Report",
    menuItem: false,
    level: "User"
  },
  {
    view: EVChargepointInsightsMap,
    icon: "fa fa-chart-simple",
    title: "EV Chargepoint Insights Map",
    menuItem: false,
    level: "User"
  },
  {
    view: DatasetWizard,
    icon: "fa fa-map-location-dot",
    title: "Dataset Wizard",
    menuItem: false,
    level: "User"
  },
  {
    view: CreateDataset,
    icon: "fa fa-wand-magic-sparkles",
    title: "Create Dataset",
    menuItem: true,
    level: "User"
  },
  {
    view: MyDatasets,
    icon: "fa fa-database",
    title: "My Datasets",
    menuItem: true,
    level: "User"
  },
  {
    view: MyAccount,
    icon: "fa fa-user",
    title: "My Account",
    menuItem: true,
    level: "User"
  },
  {
    view: OrganisationCredits,
    icon: "fa fa-wallet",
    title: "Credit Management",
    menuItem: true,
    level: "Admin"
  },
  {
    view: DatasetConfigurator,
    icon: "fa fa-wrench",
    title: "Dataset Configurator",
    menuItem: true,
    level: "Admin"
  },
  {
    icon: "fa fa-info",
    title: "Help & Support",
    menuItem: true,
    level: "User",
    subPages: [
      {
        view: Welcome,
        icon: "fa fa-door-open",
        title: "Welcome",
        menuItem: true,
        level: "User"
      },
      {
        view: ReleaseNotes,
        icon: "fa fa-book",
        title:"Release Notes",
        menuItem: true,
        level: "User"
      },
      {
        view: ContactSupport,
        icon: "fa fa-circle-question",
        title: "Contact Support",
        menuItem: true,
        level: "User"
      },
      {
        view: MobileNetworkDataMethodology,
        icon: "fa fa-tower-cell",
        title: "Mobile Network Data Methodology",
        menuItem: true,
        level: "User"
      }
    ]
  },
  {
    view: NoAccess,
    icon: "fa fa-shield",
    title: "No Access",
    menuItem: false,
    level: ""
  }
];

export function getTopLevelPages(level: string | undefined) {
  if (level === "Admin") return pages.filter(p => p.level === "Admin" || p.level === "User" || p.level === "");
  else if (level === "User") return pages.filter(p => p.level === "User" || p.level === "");
  else if (level === "" || level === undefined) return pages.filter(p => p.level === "")
  else return [];
}
export function getAllPages(level: string | undefined) {
  const subPages = pages.flatMap(p => p.subPages).filter(p => p !== undefined);
  const pagesAndSubPages = [...pages, ...subPages] as PortalPage[]
  
  if (level === "Admin") return pagesAndSubPages.filter(p => p.level === "Admin" || p.level === "User" || p.level === "");
  else if (level === "User") return pagesAndSubPages.filter(p => p.level === "User" || p.level === "");
  else if (level === "" || level === undefined) return pagesAndSubPages.filter(p => p.level === "")
  else return [];
}

export default pages;