import { DatasetFilter } from "@/models/mapViewerModels";
import { computed, ref } from "vue";
import { AnalysisMode } from "./types";


const daytypeControl = ref<DatasetFilter>({
  name: "Day Type",
  multiSelect: true,
  options: [
    { value: '1,2,3,4,5', label: "Weekday", },
    { value: '6,0', label: "Weekend", }
  ],
  selectedValues: []
});

const purposesControl = ref<DatasetFilter>({
  name: "Purposes",
  multiSelect: true,
  options: [
    { value: 1, label: 'Home ⇔ Work' },
    { value: 2, label: 'Home ⇔ Other' },
    { value: 3, label: 'Work ⇔ Other' },
    { value: 4, label: 'Other ⇔ Other' }
  ],
  selectedValues: []
});

const modesControl = ref<DatasetFilter>({
  name: "Modes",
  multiSelect: true,
  options: [
    { value: 0, label: 'Rail' },
    { value: 1, label: 'Road' },
    { value: 3, label: 'Active' }
  ],
  selectedValues: []
});

const agesControl = ref<DatasetFilter>({
  name: "Ages",
  multiSelect: true,
  options: [
    { value: 'NA', label: 'Unknown' },
    { value: '1', label: '18-24' },
    { value: '2', label: '25-34' },
    { value: '3', label: '35-44' },
    { value: '4', label: '45-54' },
    { value: '5', label: '55-64' },
    { value: '6', label: '65+' }
  ],
  selectedValues: []
});

const gendersControl = ref<DatasetFilter>({
  name: "Genders",
  multiSelect: true,
  options: [
    { value: 'NA', label: 'Unknown' },
    { value: 'F', label: 'Female' },
    { value: 'M', label: 'Male' }
  ],
  selectedValues: []
});

export const allFilters = [daytypeControl, purposesControl, modesControl, agesControl, gendersControl]

const getFilterValues = (filter: DatasetFilter) => filter.selectedValues.length > 0 ? filter.selectedValues : filter.options.map(o => o.value);

export const analysisModes: AnalysisMode[] = [
  new AnalysisMode(
    "Origins",
    "Journey Origins",
    [daytypeControl],
    "journeyorigins",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      return `?daytype=${days}`;
    }
  ),
  new AnalysisMode(
    "Destinations",
    "Journey Destiantions",
    [daytypeControl],
    "journeydestinations",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      return `?daytype=${days}`;
    }
  ),
  new AnalysisMode(
    "Purpose",
    "Origins by Purpose",
    [daytypeControl, purposesControl],
    "purposeorigins",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      const purposes = getFilterValues(purposesControl.value);
      return `?daytype=${days}&purposes=${purposes}`;
    }
  ),
  new AnalysisMode(
    "Mode",
    "Origins by Mode",
    [daytypeControl, modesControl],
    "modeorigins",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      const modes = getFilterValues(modesControl.value);
      return `?daytype=${days}&modes=${modes}`;
    }
  ),
  new AnalysisMode(
    "Dwell Time",
    "Origins by Average Dwell Time (minutes)",
    [daytypeControl],
    "dwellorigins",
    computed(() => "Average Dwell Time"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      return `?daytype=${days}`;
    }
  ),
  new AnalysisMode(
    "Demographics",
    "Origins by Age & Gender",
    [agesControl, gendersControl],
    "demographicsorigins",
    computed(() => "Visitor count"),
    () => {
      const ages = getFilterValues(agesControl.value);
      const genders = getFilterValues(gendersControl.value);
      return `?ages=${ages}&genders=${genders}`;
    }
  ),
  new AnalysisMode(
    "Combined",
    "Combine all filters",
    [daytypeControl, purposesControl, modesControl, agesControl, gendersControl],
    "combined",
    computed(() => "Visitor count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      const purposes = getFilterValues(purposesControl.value);
      const modes = getFilterValues(modesControl.value);
      const ages = getFilterValues(agesControl.value);
      const genders = getFilterValues(gendersControl.value);
      return `?daytype=${days}&purposes=${purposes}&modes=${modes}&ages=${ages}&genders=${genders}`;
    }
  ),
  new AnalysisMode(
    "Traversing Origins",
    "Origin zones of people traversing the area of interest",
    [daytypeControl],
    "traversingorigins",
    computed(() => "Traversers count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      return `?daytype=${days}`;
    }
  ),
  new AnalysisMode(
    "Traversing Destinations",
    "Destination zones of people traversing the area of interest",
    [daytypeControl],
    "traversingdestinations",
    computed(() => "Traversers count"),
    () => {
      const days = getFilterValues(daytypeControl.value);
      return `?daytype=${days}`;
    }
  ),
];