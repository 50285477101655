import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "line-tabbed-panel" }
const _hoisted_2 = { class: "line-tabbed-panel-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "caption-subject line-tab-inner",
  style: {"font-weight":"500"}
}
const _hoisted_5 = { class: "line-tabbed-panel-body" }

import { PropType, ref } from "vue";


export default _defineComponent({
  props: {
  tabs: {
    type: Array as PropType<string[]>,
    required: true
  },
  indexedByTabName: {
    type: Boolean,
    required: false
  }
},
  setup(__props) {

const props = __props


const selectedTab = ref(0);

function updateSelectedTab(index: number) {
  if (selectedTab.value != index) {
    selectedTab.value = index;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabs, (col, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "line-tab-container",
          key: index
        }, [
          _createElementVNode("button", {
            onClick: _withModifiers(($event: any) => (updateSelectedTab(index)), ["prevent"]),
            class: _normalizeClass(["line-tab", { 'selected-line-tab': index === selectedTab.value }])
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(col), 1)
          ], 10, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabs, (col, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
          (__props.indexedByTabName)
            ? _renderSlot(_ctx.$slots, col, { key: 0 })
            : _renderSlot(_ctx.$slots, index, { key: 1 })
        ], 512)), [
          [_vShow, index === selectedTab.value]
        ])
      }), 128))
    ])
  ]))
}
}

})