import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "custom-modal-title" }
const _hoisted_4 = {
  key: 0,
  id: "modal-close",
  class: "fa fa-close custom-modal-close",
  style: {"color":"black"},
  tabindex: "1"
}
const _hoisted_5 = { class: "modal-body" }

import { ref } from 'vue';


export default _defineComponent({
  props: {
  title: {
    type: String,
    required: true
  },
  disableClosing: Boolean
},
  setup(__props) {

const modalContent = ref<HTMLDivElement>();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-dialog",
    ref: modalContent
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _createTextVNode(_toDisplayString(__props.title) + " ", 1),
          (!__props.disableClosing)
            ? (_openBlock(), _createElementBlock("button", _hoisted_4))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 512))
}
}

})