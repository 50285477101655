import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  style: {"font-size":"large"}
}

import envConfig from "@/config/envConfig";
import { authenticatedRequest } from "@/composables/requestComposables";
import Modal from "@/components/Modals/Modal.vue";
import DataFetchDisplay from "../DataFetchDisplay.vue";
import { useRequest } from "@/composables/requestComposables";
import { ref } from "vue";


export default _defineComponent({
  props: {
  datasetId: {
    required: true,
    type: Number
  },
},
  setup(__props) {

const props = __props
const hiddenDownload = ref<HTMLAnchorElement>();



const listDownloadsRequest = useRequest({
  requestImmediately: true,
  requestCallback() {
    const url = `${envConfig.api_address}/datasetsapi/downloads/list?datasetid=${props.datasetId}`;
    const headers = new Headers()

    return authenticatedRequest(url, { headers })
      .then(res => res.json());
  }
});

function downloadFile(file: { Key: string }) {
  const url = `${envConfig.api_address}/datasetsapi/downloads/downloadlink?datasetid=${props.datasetId}&fileKey=${file.Key}`;
  const headers = new Headers()

  return authenticatedRequest(url, { headers })
    .then(res => res.json())
    .then(data => {
      hiddenDownload.value!.href = data;
      hiddenDownload.value!.click();
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, { title: "Dataset downloads" }, {
    default: _withCtx(() => [
      _createVNode(DataFetchDisplay, { request: _unref(listDownloadsRequest) }, {
        default: _withCtx(() => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listDownloadsRequest).data, (item, key) => {
              return (_openBlock(), _createElementBlock("li", { key: key }, [
                _createElementVNode("a", {
                  onClick: _withModifiers(() => downloadFile(item), ["prevent"])
                }, _toDisplayString(item.Key.split("/")[item.Key.split("/").length - 1]), 9, _hoisted_1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["request"]),
      (_unref(listDownloadsRequest).data && _unref(listDownloadsRequest).data.length === 0)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "No downloads available"))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        ref: hiddenDownload,
        target: "_blank",
        style: {"display":"none"}
      }, null, 512)
    ]),
    _: 1
  }))
}
}

})