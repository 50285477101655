import { Coordinate } from "ol/coordinate";

export type CustomerReportInputOption = {
  id?: number;
  name: string;
  credits: number;
}

export interface CustomerReportInput {
  id?: number;
  name: string;
  order: number;
  displayTitle: string;
  tooltip: string;
  type: number;
  options: CustomerReportInputOption[];
  active: boolean,
  internal: number,
}

export interface CustomerReportDataPeriod {
  id?: number;
  displayName: string;
  credits: number;
}

export interface CustomerReportPopulationFactor {
  id?: number;
  population: number;
  credits: number;
}

export interface CustomerReportAreaFactor {
  id?: number;
  area: number;
  credits: number;
  name: string;
}

export interface CustomerReportKeyword {
  id?: number;
  name: string;
}

export interface CustomerReport extends Record<string, any> {
  id?: number;
  name: string;
  baseCredits: number;
  inputs: CustomerReportInput[];
  geofenceExtensionSize?:  number;
  populationCountArea: number;
  maxPointsOfInterest?: number;
  canDrawStudyArea: boolean;
  canSelectZones: boolean;
  mapViewer: string;
  reportViewer: string;
  internalDescription: string;
  externalDescription: string;
  fixedRadiusSize?: number;
  icon?: string;
  earliestDate: Date | string;
  latestDate?: Date | string;
  daysBehindCurrentDate?: number;
  bespokeConfiguration: string;
  dataPeriods: CustomerReportDataPeriod[];
  selectedDataPeriod?: CustomerReportDataPeriod;
  populationFactors: CustomerReportPopulationFactor[];
  areaFactors: CustomerReportAreaFactor[];
  details: string;
  customerReportKeywords: CustomerReportKeyword[];
  order: number;
  isDraft: boolean;
  sampleReportId?: number;
}


export interface UserRequestedReportInput {
  id: number;
  displayTitle: string;
  name: string;
  selectedOptions: CustomerReportInputOption[]
}

export interface PointOfInterest {
  coords: Coordinate;
  radius: number;
}

export interface UserRequestReportDataPeriod {
  id: number;
  name: string;
  credits: number;
}

export interface UserRequestedReport {
  reportId: number;
  reportName: string;
  clientProjectName: string;
  startDate?: Date;
  endDate?: Date;
  pois: PointOfInterest[];
  selectedZones: number[];
  dataPeriod?: UserRequestReportDataPeriod;
  reportInputs: UserRequestedReportInput[];
  zoneSystem?: string;
  zoneSystemId?: number;
  geofenceWkt?: string;
  studyAreaWkt?: string;
  area?: number;
}

export interface UserReportGroup {
  name: string;
  id: number;
}

export interface RequestedReportDto {
  id?: number;
  customerReportId: number;
  userId: number;
  clientId: number;
  requestedReportOptionsJSON: string;
  requestedByUserName?: string;
  populationCount: number;
  reportStatus?: number;
  organisationId: number;
  organisationName: string;
  reportViewer?: string;
  mapViewer?: string;
  groupId: number;
  groupName?: string;
  creationTime?: string;
  customerReport?: CustomerReport
}

export interface UserReportRequestWithDetails extends RequestedReportDto {
  details: UserRequestedReport
}

export const ReportStatuses: {[key: number]: string}  = {
  0: "Requested",
  1: "InProgress",
  2: "Complete"
}

export function dtoToUserRequestedReport(dto: RequestedReportDto): UserRequestedReport {
  const result = JSON.parse(dto.requestedReportOptionsJSON);
  result.startDate = new Date(JSON.parse(dto.requestedReportOptionsJSON).startDate);
  result.endDate = new Date(JSON.parse(dto.requestedReportOptionsJSON).endDate);

  return result as UserRequestedReport;
} 

export interface CustomerReportZoneSystem {
  id: number;
  name: string;
  zoneSystemId: number;
  organisationId: number;
  zoneSystemFileName: string;
}