import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  class: "caption-subject",
  style: {"font-weight":"500"}
}

import { useLazyLoading } from '@/composables/uiComposables';
import { nextTick, PropType, ref } from 'vue';


export default _defineComponent({
  props: {
  tabs: {
    type: Array as PropType<string[]>,
    required: true
  }
},
  setup(__props, { expose }) {

const props = __props


const tabRefs = ref<HTMLButtonElement[]>([]);
function addRef(el: unknown) {
  if (el instanceof HTMLButtonElement) {
    tabRefs.value.push(el);
  }
}

const selectedTab = ref<number>();
function updateSelectedTab(target: HTMLButtonElement | HTMLSpanElement | null, index: number) {
  let element: HTMLButtonElement;

  if (target?.nodeName === "SPAN")
    element = (target as HTMLSpanElement).parentElement as HTMLButtonElement;
  else
    element = target as HTMLButtonElement;

  if (selectedTab.value != index) {
    selectedTab.value = index;
  } else {
    selectedTab.value = -1;
  }

  tabRefs.value.forEach(tf => {
    const panel = tf.nextElementSibling as HTMLElement;
    panel.style.maxHeight = '0';
  });

  if (selectedTab.value !== -1) {
    nextTick(() => {
      const panel = element.nextElementSibling as HTMLDivElement;
      if (selectedTab.value === index) {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
      window.setTimeout(() => {
        panel.style.maxHeight = "max-content"
      }, 300);
    });
  }
}

const root = ref<HTMLElement>();
useLazyLoading(root, () => {
  const visible = root.value!.offsetWidth > 0 && root.value!.offsetHeight > 0;
  if (visible) {
    const firstTab = tabRefs.value[0];
    firstTab.style.maxHeight = "";
    updateSelectedTab(firstTab, 0);
  }
});

function getAccordianTabStyle(index: number) {
  const bottomBorder = index !== props.tabs.length - 1 || index === selectedTab.value ? "1px solid rgba(0,0,0,0.2)" : "none";
  const topBorder = index !== 0 && selectedTab.value === index - 1 ? "1px solid rgba(0,0,0,0.2)" : "none";
  const borderRadius = index === 0 && selectedTab.value === index ? "5px 5px 0 0" : "";

  const style = `border-bottom:${bottomBorder};border-top:${topBorder}border-radius:${borderRadius}`
  return style
}

expose({ selectedTab });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "cl-accordian",
    ref: root
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabs, (col, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "cl-accordian-tab-container",
        key: index
      }, [
        _createElementVNode("button", {
          onClick: (evt) => updateSelectedTab(evt.target as HTMLButtonElement, index),
          class: _normalizeClass(["cl-accordian-tab", { 'selected-cl-accordian-tab': index === selectedTab.value }]),
          ref: addRef,
          style: _normalizeStyle(getAccordianTabStyle(index))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(col), 1)
        ], 14, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass(["cl-accordian-body", { 'cl-accordian-body-hidden': index !== selectedTab.value }])
        }, [
          _renderSlot(_ctx.$slots, index)
        ], 2)
      ]))
    }), 128))
  ], 512))
}
}

})