import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "map-control-menu" }
const _hoisted_2 = { id: "menu-body" }
const _hoisted_3 = { id: "menu-toggle" }

import { ref, watch } from "vue";
import TabbedMenu from "@/components/TabbedMenu.vue";
import MenuFilters from "./MenuFilters.vue";
import MenuData from "./MenuData.vue";
import MenuInfo from "./MenuInfo.vue";


export default _defineComponent({
  emits: ["submit", "reset", "selectAllZones"],
  setup(__props, { emit }) {



const menuOpen = ref(true);
const menuCloserText = ref("<");

function toggleMenu() {
  menuOpen.value = !menuOpen.value;
}

watch(menuOpen, (newVal: boolean) => {
  if (newVal) menuCloserText.value = "<";
  else menuCloserText.value = "Menu";
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(TabbedMenu, { tabs: ['Filters', 'Data', 'Info'] }, {
        "0": _withCtx(() => [
          _createVNode(MenuFilters, {
            onSubmit: _cache[0] || (_cache[0] = ($event: any) => (emit('submit'))),
            onReset: _cache[1] || (_cache[1] = ($event: any) => (emit('reset')))
          })
        ]),
        "1": _withCtx(() => [
          _createVNode(MenuData, {
            onSelectAllZones: _cache[2] || (_cache[2] = ($event: any) => (emit('selectAllZones')))
          })
        ]),
        "2": _withCtx(() => [
          _createVNode(MenuInfo)
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, menuOpen.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        style: {},
        onClick: toggleMenu,
        class: _normalizeClass({ 'toggle-closed': !menuOpen.value })
      }, _toDisplayString(menuCloserText.value), 3)
    ])
  ]))
}
}

})