import { Directive, DirectiveBinding } from "vue";

type ElementWithValidation = HTMLElement & { validationFunctions: { [index: string]: any } }
export function getFormValidationFunctions(el: ElementWithValidation) {
  let currentNode = el;

  while (currentNode.nodeName !== "FORM") {
    currentNode = currentNode.parentElement!.childNodes[0].parentElement! as ElementWithValidation;
    if (currentNode === undefined || currentNode === null) return undefined;
  }

  if (currentNode.validationFunctions === undefined) currentNode.validationFunctions = {};

  return currentNode.validationFunctions;
}

class Validator {
  validate: (value: any) => boolean;
  invalidText: string;

  constructor(params: Validator) {
    this.validate = params.validate,
    this.invalidText = params.invalidText
  }
}

export const isNullOrWhitespaceValidator = new Validator({
  validate(value: string | null | undefined) {
    return !!value && !!value.trim(); //isNullOrWhitespace equivalent
  },
  invalidText: "Enter a value"
})

export const integerValidator = new Validator({
  validate(value: string) {
    const regex = /^-?(0|[1-9]\d*)$/;
    return regex.test(value);
  },
  invalidText: "Enter a whole number"
});

export const positiveintegerValidator = new Validator({
  validate: (value: string) => {
    const regex = /^[1-9]\d*$/
    return regex.test(value);
  },
  invalidText: "Enter a positive whole number"
});

export const wholeNumberValidator = new Validator({
  validate: (value: string) => {
    const regex = /^\d+$/
    return regex.test(value);
  },
  invalidText: "Enter a whole number"
});

export const phoneNumberValidator = new Validator({
  validate: (value: string) => {
    const regex = /^\+(?:[0-9]●?){6,14}[0-9]$/
    return regex.test(value);
  },
  invalidText: "Enter a phone number"
});

export const emailValidator = new Validator({
  validate: (value: string) => {
    const regex = /^\S+@\S+.\S+$/;
    return regex.test(value);
  },
  invalidText: "Enter an email address"
});

// Letters, numbers, hyphens, underscores, spaces
export const alphaNumericExtendedValidator = new Validator({
  validate: (value: string) => {
    const regex = /^[A-Za-z0-9_\-\s]+$/;
    return regex.test(value);
  },
  invalidText: "Only letters, numbers, spaces, dashes, and underscores are permitted"
})

export function createDecimalValidator(maxDp: number): Validator {
  const pattern = new RegExp(`^-?\\d+(\\.\\d{1,${maxDp}})?$`, "g");
  return new Validator({
    validate: (value: string) => {
      return value.match(pattern)?.length! > 0;
    },
    invalidText: `Enter a number with up to ${maxDp} decimal places`
  })
}

const getValidationFunction = (binding: DirectiveBinding): Validator => {
  const type = typeof binding.value;

  switch (type) {
    case 'undefined':
      return isNullOrWhitespaceValidator;
    case 'string':
      if (binding.value === 'integer') {
        return integerValidator;
      } else if (binding.value === 'positive-integer') {
        return positiveintegerValidator;
      } else if (binding.value === 'whole-number') {
        return wholeNumberValidator;
      } else if (binding.value === "email") {
        return emailValidator;
      } else if (binding.value === "phone") {
        return phoneNumberValidator;
      } else if (binding.value === "alpha-numeric-extended") {
        return alphaNumericExtendedValidator;
      } else if (binding.value.includes('float')) {
        if (binding.value.includes('-')) {
          const decimalPlaces = parseInt(binding.value.split('-').pop());
          return createDecimalValidator(decimalPlaces);
        } else {
          //Default 16 decimal places
          return createDecimalValidator(16);
        }
      } else {
        throw "No validator match";
      }
    case 'object':
      if (typeof binding.value.validationFunction != 'function' || typeof binding.value.invalidText != 'string') {
        return isNullOrWhitespaceValidator;
      }
      return binding.value as Validator;
    case 'function':
      return {
        validate: binding.value,
        invalidText: ""
      } as Validator
  }

  throw "No validator match";
}

const directives: Record<string, Directive> = {
  "validation-submit": {
    mounted(el: ElementWithValidation, binding) {
      if (!el.parentElement) throw "validation-submit directive cannot be placed on root";

      el.addEventListener("click", (e: any) => {
        const validationFunctions = getFormValidationFunctions(el);

        if (validationFunctions === undefined) {
          console.error("Please add a form parent");
          return;
        }

        e.preventDefault();

        let formValid = true;

        for (const index in validationFunctions) {
          const currentValid = validationFunctions[index].validationCallback(validationFunctions[index].element.disabled);
          formValid = formValid && currentValid;
        }
        binding.value(formValid);
      });
    }
  },
  "validate": {
    mounted: (el: ElementWithValidation, binding) => {
      if (!el.parentElement) throw "validate directive cannot be placed on root";
      const validationFunctions = getFormValidationFunctions(el);

      let styleElem = el;
      if (typeof binding.value == "object" && binding.value.styleElem) styleElem = binding.value.styleElem;
      if (validationFunctions === undefined) {
        console.error("Please add a form parent");
        return;
      }
      const index = (Object.keys(validationFunctions).length > 0 ? (
        Math.max(
          ...Object.keys(validationFunctions)
            .map(key => parseInt(key))
        )
        + 1
      ) : 1).toString();

      el.dataset.validationFunctionIndex = index;

      const validationCallback = (forceValid = false) => {
        styleElem.style.border = "";
        delete el.parentElement!.dataset.validationtooltip;

        const valid = forceValid || getValidationFunction(binding).validate(((el as HTMLElement) as HTMLInputElement).value);

        if (!valid) {
          styleElem.style.border = "solid 2px var(--rosie)";
          if (getValidationFunction(binding).invalidText) {
            el.parentElement!.dataset.validationtooltip = getValidationFunction(binding).invalidText;
          }
        }

        return valid;
      };

      el.addEventListener("blur", () => validationCallback(false));
      el.addEventListener("input", () => validationCallback(false));

      validationFunctions[index] = { validationCallback, element: el };
    },
    beforeUnmount(el) {
      const validationFunctions = getFormValidationFunctions(el);

      if (validationFunctions === undefined) {
        console.error("Please add a form parent");
        return;
      }

      delete validationFunctions[el.dataset.validationFunctionIndex];
    }
  }
}

export default directives;
