import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Map from "ol/Map";
import { addLayerSwitcher, basemapsGroup } from "@/util/maps/mapLayers";
import { Group as LayerGroup } from "ol/layer";
import { Point } from "ol/geom";
import View from "ol/View";
import { Draw } from "ol/interaction";
import * as olProj from "ol/proj";
import { inject, watch, ref, onMounted, Ref } from "vue"
import { Coordinate } from "ol/coordinate";
import { CustomerReport, UserRequestedReport } from "@/models/customerReportsModels";
import GeometryType from "ol/geom/GeometryType";
import { addShowHideObserver, makePoiLayer } from "@/util/maps/mapHelpers";
import { addSearch } from "@/util/maps/mapSearchControl";


export default _defineComponent({
  emits: ["update:clear"],
  setup(__props, { emit }) {




const userReportDetails = inject<UserRequestedReport>("userReportDetails")!;
const reportConfig = inject<Ref<CustomerReport>>("reportConfig")!;

const { poiSource, poiLayer } = makePoiLayer();


const mapCenter = inject<Ref<Coordinate>>("mapCenter")!;
const mapZoom = inject<Ref<number>>("mapZoom")!;

const mapView = new View({
  center: mapCenter.value,
  zoom: mapZoom.value
});

const mapRef = ref<HTMLElement | undefined>(undefined);

onMounted(() => {
  const featureGroup = new LayerGroup({
    //title: "Features",
    layers: [poiLayer]
  });

  const map = new Map({
    layers: [basemapsGroup, featureGroup],
    target: mapRef.value,
    view: mapView
  });

  addLayerSwitcher(map);
  addSearch(map);

  map.on('moveend', () => {
    mapZoom.value = map.getView().getZoom()!;
    mapCenter.value = map.getView().getCenter()!;
  });

  watch(mapZoom, (newVal) => {
    map.getView().setZoom(newVal);
  });
  watch(mapCenter, (newVal) => {
    map.getView().setCenter(newVal);
  });

  addShowHideObserver(mapRef.value!.parentElement!, map);

  if (userReportDetails) {
    userReportDetails.pois.forEach(poi => {
      const coords = olProj.transform(poi.coords, "EPSG:4326", "EPSG:3857");
      poiSource.addPoint("Point of interest", coords);

    });
  }

  const draw = new Draw({
    type: GeometryType.POINT,
  });

  draw.on("drawend", (event) => {
    const feature = event.feature;

    const pointCoords = (feature.getGeometry()! as Point).getCoordinates();
    const pointLatLon = olProj.transform(pointCoords, "EPSG:3857", "EPSG:4326");

    poiSource.addPoint("Point of interest", pointCoords);
    userReportDetails.pois.push({ coords: pointLatLon, radius: reportConfig.value.fixedRadiusSize! });
  });

  const enableDrawing = () => {
    if (!draw) return;

    map.removeInteraction(draw);
    map.addInteraction(draw);
  }

  const disableDrawing = () => {
    if (!draw) return;

    map.removeInteraction(draw);
  }

  enableDrawing();

  watch(() => userReportDetails.pois, (newVal) => {
    if (newVal.length >= reportConfig.value.maxPointsOfInterest!) disableDrawing();
    else enableDrawing();
  }, {
    deep: true,
  });

  watch(() => userReportDetails.pois, (newVal) => {
    if (newVal.length === 0 || !newVal) {
      poiSource.clear();
      emit("update:clear", false);
    }
  }, { deep: true });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: mapRef,
    style: {"height":"600px","border":"2px rgba(0,0,0,0.5) solid"}
  }, null, 512))
}
}

})