import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]

import { useModals } from "@/composables/modalComposables";


export default _defineComponent({
  props: {
  title: {
    type: String,
    default: "Info"
  },
  message: {
    type: String,
    required: true
  },
  iconStyle: {
    type: Object,
    default: () => ({
      'display': 'inline',
      'margin-left': '4px',
      'color': 'var(--rosie)',
      'font-size': '20px'
    })
  },
  customHtml: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props
const { openInfoModal } = useModals();



function iconClicked() {
  const body = props.customHtml ? `<p>${props.message}</p>` : props.message;
  openInfoModal(props.title, body);
}

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("i", {
    class: "fa-regular fa-circle-question",
    onClick: _withModifiers(iconClicked, ["prevent"]),
    style: _normalizeStyle([__props.iconStyle, {"cursor":"pointer"}])
  }, null, 12, _hoisted_1)), [
    [_directive_tooltip, __props.message]
  ])
}
}

})