import { Overlay } from "ol";
import Feature from "ol/Feature";
import { Geometry } from "ol/geom";
import { Layer } from "ol/layer";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import { Vector } from "ol/source";

export type PopupItem = {
  title: string,
  featureProperty?: string
}
export function addOverlayPopup(map: Map, popupItems: PopupItem[], parent: HTMLDivElement) {
  const overlay = new Overlay({
    element: parent,
    autoPan: true,
    autoPanAnimation: {
      duration: 150
    }
  });
  map.addOverlay(overlay);
  map.on("pointermove", (e) => {
    parent.replaceChildren();
    let selected: Feature<Geometry>;

    map.forEachFeatureAtPixel(e.pixel, (feature) => {
      selected = feature as Feature<Geometry>;
      return true;
    });

    if (selected!) {
      overlay.setPosition(e.coordinate);
      map.getTargetElement().style.cursor = 'pointer';
    } else {
      map.getTargetElement().style.cursor = '';
      return;
    }

    const div = document.createElement('div');
    div.classList.add("ol-popup");

    popupItems.forEach(item => {
      if (item.featureProperty) {
        const property = selected.get(item.featureProperty);
        if (property) {
          const h5 = document.createElement('h5');
          h5.innerHTML = `<label>${item.title}</label>: ${property}`;
          div.appendChild(h5);
        }
      }
    });

    if (div.children.length > 0)
      parent.appendChild(div);
  })
}

export function removeAllOverlays(map: Map) {
  map.getOverlays().forEach(o => {
    map.removeOverlay(o);
  })
}