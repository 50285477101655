import { ref, Ref, watch, readonly } from "vue"

const sidebarClosed = ref(false);
export const useSidebar = () => {
  const toggleSidebar = () => sidebarClosed.value = !sidebarClosed.value;

  return {
    closed: sidebarClosed,
    toggleSidebar
  }
};

//Lazy loading - run a callback function only when a component is shown on the page for the first time
//works nicely with v-show child components
export const useLazyLoading = (element: Ref<HTMLElement | undefined>, callback: Function) => {
  if (!('ResizeObserver' in window)) {
    callback();
    return;
  }
  let isLoaded = false;

  const observer = new ResizeObserver((_, self) => {
    if (!element.value) {
      self.disconnect();
      return;
    }

    const visible = element.value!.offsetWidth > 0 && element.value!.offsetHeight > 0;
    if (visible && !isLoaded) {
      isLoaded = true;
      callback();
    }
  });

  watch(element, () => {
    if (!element.value) {
      observer.disconnect();
    }
    else {
      observer.observe(element.value);
    }
  });
}


export const useElementVisiblity = (element: Ref<HTMLElement | undefined>) => {
  if (!('ResizeObserver' in window)) {
    return {
      visible: readonly(ref(false))
    };
  }

  const visible = ref(true);
  const observer = new ResizeObserver((_, self) => {
    if (!element.value) {
      self.disconnect();
      return;
    }

    visible.value = element.value!.offsetWidth > 0 && element.value!.offsetHeight > 0;
  });
  
  watch(element, () => {
    if (!element.value) {
      observer.disconnect();
    }
    else {
      observer.observe(element.value);
    }
  });

  return {
    visible: readonly(visible)
  }
}

const refreshKey = ref(false);
export const usePageRefresh = () => {
  const refreshPage = () => refreshKey.value = !refreshKey.value;

  return { refreshKey: readonly(refreshKey), refreshPage }
}