<template>
  <h2>New Features</h2>
  <ul>
    <li>CL-436 Added a new accordion to the Dwells tab in the Location Insights dataset report, showing cumulative Dwellers by Time </li>
    <li>CL-307 Redesigned the way map pins work in the map view of My Datasets to improve usability:
      <ul>
        <li>Pins now show the dataset icon</li>
        <li>Pins now cluster together depending on zoom level </li>
        <li>If a pin represents multiple datasets, a number now shows how many where previously only the top pin would be visible</li>
        <li>If a pin represents multiple datasets, clicking on it now shows a list of those datasets</li>
      </ul>
    </li>
  </ul>

  <h2>Changes</h2>
  <ul>
    <li>CL-435 Changed the default sort order for some charts & tables to descending by value</li>
    <li>CL-433 Re-named Standard Location Analysis to Location Insights</li>
    <li>CL-432 Improved some styling for data tables</li>
  </ul>

  <h2>Fixes</h2>
  <ul>
    <li>CL-430 Fixed an issue where zones were not rendering in the wizard</li>
    <li>CL-428 Fixed an issue where only primary users could see or use credits</li>
    <li>CL-425 Fixed an issue where the 'Zoom in to show zones' message appeared incorrectly</li>
  </ul>
</template>
<script>
  export default {
    name: "0.129"
  }
</script>