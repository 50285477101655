import { Map } from "ol";
import Control from "ol/control/Control";

export class LoadingZonesTextControl extends Control {
  element: HTMLDivElement;
  constructor(opt_options?: any) {
    const options = opt_options || {};

    const element = document.createElement('div');
    element.className = 'map-zones-loading-text-control ol-unselectable ol-control';

    super({
      element: element,
      target: options.target,
    });


    const label = document.createElement('label');
    label.innerHTML = "Loading zones....";
    element.appendChild(label);

    this.element = element;

    this.enable();
  }

  enable() {
    this.element.style.setProperty("display", "block");
  }

  disable() {
    this.element.style.setProperty("display", "none");
  }
}

export function addLoadingZonesText(map: Map) {
  const zoomRangeTextControl = new LoadingZonesTextControl();
  map.addControl(zoomRangeTextControl);
  return zoomRangeTextControl;
}