import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "tabbed-menu" }
const _hoisted_2 = { class: "tabbed-menu-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  class: "caption-subject",
  style: {"font-weight":"500"}
}
const _hoisted_5 = { class: "tabbed-menu-body" }

import { PropType, ref } from "vue";


export default _defineComponent({
  props: {
  tabs: {
    type: Array as PropType<string[]>,
    required: true
  }
},
  setup(__props) {

const props = __props


const selectedTab = ref(0);

function updateSelectedTab(index: number) {
  if (selectedTab.value != index) {
    selectedTab.value = index;
  }
}

function getMenuTabStyle(index: number) {
  const rightBorder = "10px";
  const leftBorder = index !== 0 ? "10px" : "0px";
  let style = `border-radius:${leftBorder} ${rightBorder} 0px 0px;`

  return style
}
function getMenuTabContainerStyle() {
  return `width:${100 / props.tabs.length}%;display:inline-block;`
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabs, (col, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "menu-tab-container",
          key: index,
          style: _normalizeStyle(getMenuTabContainerStyle())
        }, [
          _createElementVNode("button", {
            onClick: ($event: any) => (updateSelectedTab(index)),
            class: _normalizeClass(["menu-tab", { 'selected-menu-tab': index === selectedTab.value }]),
            style: _normalizeStyle(getMenuTabStyle(index))
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(col), 1)
          ], 14, _hoisted_3)
        ], 4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tabs, (col, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
          _renderSlot(_ctx.$slots, index)
        ], 512)), [
          [_vShow, index === selectedTab.value]
        ])
      }), 128))
    ])
  ]))
}
}

})