import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import { Feature, Map } from "ol";
import { Select } from "ol/interaction";
import { pointerMove } from "ol/events/condition";
import Geometry from "ol/geom/Geometry";
import { Ref } from "vue";
import VectorImageLayer from "ol/layer/VectorImage";
import { UserReportRequestWithDetails, UserRequestedReport } from "@/models/customerReportsModels";
import envConfig from "@/config/envConfig";
import { Polygon } from "ol/geom";
import { fromExtent } from "ol/geom/Polygon";
import { Zone } from "../../models/mapViewerModels";
import { authenticatedRequest } from "@/composables/requestComposables";
import { generateWKT } from "./mapUtils";
import { bbox } from 'ol/loadingstrategy';
import { LoadingZonesTextControl } from "./loadingZonesTextControl";

type ShadeFunction = (magnitude: number, displayGroup: number) => string;
const defaultShadeFunction: ShadeFunction = (magnitude: number, _: number) => "rgba(225," + Math.max(0, 255 - magnitude) + ", 0, 0.50)";

export function createZoneStyle(displayGroup: number, magnitude?: number, selected?: boolean, hover?: boolean, available?: boolean, getShade?: Ref<ShadeFunction> | ShadeFunction) {
  const selectedZoneStroke = new Stroke({
    color: "rgba(0,0,0,0.7)", //Zone outline colour default #f00
    width: 4
  });

  const unselectedZoneStroke = new Stroke({
    color: "rgba(0,0,0,0.7)", //Zone outline colour default #f00
    width: 1
  });


  let fill, stroke;

  if (magnitude !== undefined) {
    let shadeFn;
    if (getShade !== undefined && typeof getShade === "function") shadeFn = getShade;
    else if (getShade !== undefined && typeof getShade.value === "function") shadeFn = getShade.value;
    else shadeFn = defaultShadeFunction;

    const shade = shadeFn(magnitude, displayGroup);
    fill = new Fill({
      color: shade
    });
  } else {
    fill = new Fill({
      color: "rgba(0,0,0,0.01)"
    })
  }

  stroke = unselectedZoneStroke;
  if (available) {
    fill.setColor("rgba(10,255,20,0.50)")
  }
  if (hover) {
    stroke = selectedZoneStroke
  }
  if (selected) {
    fill.setColor("rgba(0,0,255,0.50)")
  }


  return new Style({
    stroke,
    fill
  })
}

type CreateZonesLayerParams = {
  map: Map;
  zonesSource: VectorSource<Geometry>;
  getShade?: Ref<ShadeFunction> | ShadeFunction;
  zoneClickedCallback?: (selected: Feature<Geometry>) => any,
  zoneHoveredCallback?: (currentlyHovered: boolean, selected: Feature<Geometry>) => any,
  zonesLoadingControl?: LoadingZonesTextControl,
  minZoom?: number
}

export function createZonesLayer(params: CreateZonesLayerParams) {
  if (params.zonesLoadingControl !== undefined)
    params.zonesSource.on("featuresloadend", () => params.zonesLoadingControl!.disable());

  const zonesLayer = new VectorImageLayer({
    visible: true,
    //layerKey: "ZONES",
    source: params.zonesSource,
    style: (feature) => {
      return createZoneStyle(feature.get("DisplayGroup"), feature.get("Magnitude") as number, feature.get("Selected"), feature.get("Focused"), feature.get("Available"), params.getShade);
    }
  })

  if(params.minZoom)
    zonesLayer.setMinZoom(params.minZoom);

  const hoverSelect = new Select({
    condition: pointerMove,
    layers: [zonesLayer],
    style: (feature) => createZoneStyle(feature.get("DisplayGroup"), feature.get("Magnitude") as number, feature.get("Selected"), true, feature.get("Available"), params.getShade)
  });

  if (params.zoneHoveredCallback) hoverSelect.on("select", evt => {
    evt.selected.forEach(x => params.zoneHoveredCallback!(true, x));
    evt.deselected.forEach(x => params.zoneHoveredCallback!(false, x));
  })

  params.map.addInteraction(hoverSelect);

  if (params.zoneClickedCallback !== undefined) {
    params.map.on("click", (e) => {
      let selected: Feature<Geometry>;
      params.map.forEachFeatureAtPixel(e.pixel, (feature) => {
        selected = feature as Feature<Geometry>;
        return true;
      });

      if (selected! !== undefined) {
        params.zoneClickedCallback!(selected);
      }
    })
  }

  return zonesLayer;
}

export type MapZoneMagnitude = {
  zoneId: number,
  magnitude: number,
  displayGroup: number
};

export function setZoneMagnitudes(zonesLayer: VectorImageLayer<VectorSource<Geometry>>, zones: MapZoneMagnitude[], featureIdProperty: string) {
  zonesLayer
    .getSource()
    .getFeatures()
    .forEach((feature) => {
      const matchedZone = zones.find(x => x.zoneId == feature.get(featureIdProperty));

      if (typeof matchedZone !== "undefined") {
        feature.set("Magnitude", matchedZone.magnitude);
        feature.set("DisplayGroup", matchedZone.displayGroup);
      }
    });
}

export function setAvailableZones(zonesLayer: VectorImageLayer<VectorSource<Geometry>>, zoneIds: number[], featureIdProperty: string) {
  zonesLayer
    .getSource()
    .getFeatures()
    .forEach((feature) => {
      if (zoneIds.includes(feature.get(featureIdProperty) as number)) {
        feature.set("Available", true);
      }
    });
}

export function isStandardZonesSystem(userRequestedReport: UserRequestedReport) {
  return userRequestedReport.zoneSystem === "Standard";
}

export async function createZonesSourceForDataset(userReport: UserReportRequestWithDetails, createFeatureFromZone: (zone: any) => any, zonesLoadingControl?: LoadingZonesTextControl) {
  if (isStandardZonesSystem(userReport.details)) {
    const endpoint = `${envConfig.api_address}/datasetsapi/datasetzonesystem`;
    const headers = new Headers();

    const zonesSource = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection, success, fail) => {
        const extentPolygon = fromExtent(extent);
        const extentWkt = generateWKT(new Feature<Polygon>({ geometry: extentPolygon }));

        const queryParams = `&extentwkt=${encodeURIComponent(extentWkt.split(";")[2])}&datasetid=${userReport.id}`;

        const url = `${endpoint}?${queryParams}`;

        if (zonesLoadingControl)
        zonesLoadingControl.enable();

        zonesSource.clear();
        authenticatedRequest(url, { headers })
          .then(res => res.json())
          .then(data => {
            const geoFeatures = data.Results[0]
              .map((zone: Zone) => createFeatureFromZone(zone))
              .map((feature: any) => {
                return feature
              });

            zonesSource.clear();
            zonesSource.addFeatures(geoFeatures);
            if(success != undefined)
              success(geoFeatures);
          })
          .catch(e => {
            console.error("failed:", e);
            zonesSource.removeLoadedExtent(extent);
            if(fail != undefined)
              fail();
          })
          .finally(() => {
            if (zonesLoadingControl)
              zonesLoadingControl.disable();
              
          });
      },
      strategy: bbox,
    });
    return zonesSource;
  } else {
    const zonesGeoJson = (() => {
      const url = `${envConfig.api_address}/datasetsapi/customzonesystem?zonesystemid=${userReport.details.zoneSystemId}&zonesystemfilename=${userReport.details.zoneSystem!}`;
      return authenticatedRequest(encodeURI(url), { headers: new Headers() })
        .then(res => res.json())
        .then(data => data.signedUrl as string);
    })();
    const url = await zonesGeoJson;
    console.log("zones url: ", url);

    const zonesSource = new VectorSource({
      url,
      format: new GeoJSON()
    });
    if (zonesLoadingControl)
      zonesSource.on("featuresloadend", () => zonesLoadingControl.disable())

    return zonesSource;
  }
}