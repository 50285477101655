<template>
  <h2>New Features</h2>
  <ul>
    <li>CL-344, CL-345, CL-373, CL-459, CL-466, CL-474</li>
    <li>Added a new dataset template to the portal; EV Chargepoint Insights
      <ul>
        <li>Dataset based on finding the best location to install EV chargers</li>
        <li>Shows details of visits, journeys, traversing trips and dwell times</li>
        <li>Includes Citi Logik’s machine learning model for rapid chargers with a forecast site performance represented as a score from 0-100</li>
      </ul>
    </li>
    <li>CL-438 Added a welcome page with a text overview of features</li>
  </ul>

  <h2>Changes</h2>
  <ul>
    <li>CL-466 Made some minor changes to wording in the Footfall Counter dataset</li>
    <li>Other changes include improvements to back-end automation and other non-user-facing tasks.</li>
  </ul>
</template>
<script>
  export default {
    name: "0.143"
  }
</script>