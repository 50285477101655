<template>
  <h2>New Features</h2>
  <ul>
    <li>CL-383 | Added a new Help page to the navigation menu. The help page contains a form to contact support.</li>
    <li>CL-380 | Added visibility of your organisation’s current credit balance to the top of the page (next to the username)</li>
    <li>CL-347 | Added a filter for ‘Dataset Template’ to My Datasets which is available in both the table and map view</li>
  </ul>

  <h2>Changes</h2>
  <ul>
    <li>CL-422 | The My Datasets page will now save any search or filtering parameters meaning the user does not have to keep re-entering the same parameters when returning to the page</li>
    <li>CL-370 | Changed the order of the left navigation menu</li>
    <li>CL-303 | Improved some of the styling and usability around the password change/logout interaction</li>
  </ul>

  <h2>Fixes</h2>
  <ul>
    <li>CL-421 | Fixed an issue in the map explorer where the rendered heatmap would persist when opening a new dataset</li>
    <li>CL-420 | Fixed an issue where column sorting didn’t work as intended</li>
    <li>CL-409 | Fixed an issue preventing some users from viewing the sample datasets</li>
    <li>CL-389 | Fixed an issue in My Datasets where dates were alphabetically and not chronologically</li>
    <li>CL-387 | Fixed an issue in the map explorer where very narrow filter criteria would cause an error</li>
  </ul>
</template>
<script>
  export default {
    name: "0.118"
  }
</script>