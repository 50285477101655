export function getQueryParams() {
  const params: { [key: string]: string } = {};
  const pairs = location.search.substring(1).split("&");

  for (let i = 0; i < pairs.length; i++) {
    const key = decodeURIComponent(pairs[i].split("=")[0]);
    const value = decodeURIComponent(pairs[i].split("=")[1]);
    params[key] = value;
  }

  return params;
}