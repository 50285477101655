import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"row","justify-content":"space-between","flex-wrap":"wrap"} }
const _hoisted_4 = ["disabled"]

import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import { useRequest } from "@/composables/requestComposables";
import { useModals } from "@/composables/modalComposables";
import envConfig from "@/config/envConfig";
import { setCookie, getCookie } from "@/util/cookieHelpers";
import { getAllPages } from "@/pages/pageConfig";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { checkAuthenticated, Service, updateServices, checkReleaseNotes } from "@/composables/appComposables";
import { isNullOrWhitespaceValidator } from "@/util/directives/validationDirectives";


export default _defineComponent({
  emits: ["reset"],
  setup(__props, { emit }) {



const router = useRouter();
const { openTermsAndConditionsModal } = useModals();

const username = ref("");
const password = ref("")

const loginRequest = useRequest({
  requestCallback(username: string, password: string) {
    const body = {
      UserNameOrEmailAddress: username,
      Password: password
    };

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Abp.TenantId", "1");
    headers.append("Cache-Control", "no-cache");

    const url = envConfig.admin_address + "/api/TokenAuth/Authenticate";

    return fetch(url, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      if (res.ok) return res.json();
      else throw new Error(res.statusText);
    }).then(data => {
      const expirySeconds = data.result.expireInSeconds;
      const expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + expirySeconds * 1000);
      setCookie("expiryDate", expiryDate.getTime(), expirySeconds);

      const accessToken = data.result.accessToken;
      setCookie("token_id", accessToken, expirySeconds);
      setCookie("userId", data.result.userId, expirySeconds);
      setCookie("userName", data.result.userName, expirySeconds);
      setCookie("firstName", data.result.firstName, expirySeconds);
      setCookie("surname", data.result.surname, expirySeconds);
      setCookie("emailAddress", data.result.emailAddress, expirySeconds);
      setCookie("tenancyId", data.result.tenancyId, expirySeconds);

      getPermissionsRequest.invoke(accessToken, expirySeconds);
    }).catch(e => {
      console.error(e.stack);
      throw new Error("Login failed");
    });
  },
  errorModal: true
});

const getPermissionsRequest = useRequest({
  requestCallback(token: string, expirySeconds: number) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(envConfig.admin_address + "/api/services/app/UserProfile/getCurrentPermissions", {
      headers: headers,
      method: "GET",
      cache: "no-store"
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Unable to get permissions: ${response.status}`)
        }
      })
      .then(async (data) => {
        const services = data.result.filter((item: any) => item.clientName === "Citi Logik")[0]?.services as Service[];
        updateServices(services);

        const serviceNames = services?.map((s: { name: string }) => s.name);
        const isAdmin = serviceNames?.includes("CitiLogik Portal Management") ? true : false;
        const isUser = serviceNames?.includes("CitiLogik Portal") ? true : false;

        if (isAdmin) setCookie("userLevel", "Admin", expirySeconds);
        else if (isUser) setCookie("userLevel", "User", expirySeconds);

        checkAuthenticated();
        checkReleaseNotes();

        const filteredServices = Object.values(data.result).flatMap((client: any) => client.services).filter((item: any) => item.name === "CitiLogik Portal")
        if (filteredServices.length === 0) {
          router.push("/No_Access");
          return;
        }

        const availablePages = getAllPages(getCookie("userLevel"));

        const portalService = filteredServices[0];
        if (portalService?.hasAcceptedTAndC === false && portalService.shouldAcceptTAndC) {
          openTermsAndConditionsModal()
          if(availablePages.some(p => p.title === "Welcome")) {
            router.push("/Welcome");
            return;
          }
        }
        else if (getAllPages(getCookie("userLevel")).length > 0)
          router.push("/" + availablePages?.filter(p => p.menuItem)[0]?.title.replace(/ /g, "_"));
      });
  },
  errorModal: true
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        id: "username",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (username.value = $event)),
        placeholder: "Username or Email",
        type: "text",
        class: "form-control"
      }, null, 512), [
        [_vModelText, username.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: "password",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (password.value = $event)),
        placeholder: "Password",
        type: "password",
        class: "form-control"
      }, null, 512), [
        [_vModelText, password.value]
      ])
    ]),
    _createVNode(DataFetchDisplay, {
      requests: [_unref(loginRequest), _unref(getPermissionsRequest)],
      ignoreError: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            id: "forgot-password",
            class: "btn btn-outline btn-outline-rosie",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('reset')))
          }, "Forgot password"),
          _createElementVNode("button", {
            disabled: !_unref(isNullOrWhitespaceValidator).validate(username.value) || !_unref(isNullOrWhitespaceValidator).validate(password.value),
            id: "submit-login",
            class: "btn btn-rosie",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(loginRequest).invoke(username.value, password.value)), ["prevent"]))
          }, "Submit", 8, _hoisted_4)
        ])
      ]),
      _: 1
    }, 8, ["requests"])
  ], 64))
}
}

})