import WKT from "ol/format/WKT";
import Vector from "ol/source/Vector";
import * as olProj from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import MultiPolygon from "ol/geom/MultiPolygon";
import { Coordinate } from "ol/coordinate";
import Geometry from "ol/geom/Geometry";
import { getArea } from 'ol/sphere';

export const MAP_ZOOM = 6;
export const MAP_CENTER = olProj.transform([-1.4, 53.5508], "EPSG:4326", "EPSG:3857");


class GeomLine {
  point1: number[];
  point2: number[];

  constructor(point1: number[], point2: number[]) {
    this.point1 = point1;
    this.point2 = point2;
  }
  gradient() {
    return (this.point1[0] - this.point2[0]) / (this.point1[1] - this.point2[1]);
  }
  angle1() {
    return (Math.atan2(this.point2[0] - this.point1[0], this.point2[1] - this.point1[1]) * 180) / Math.PI;
  }
  angle2() {
    return (Math.atan2(this.point1[0] - this.point2[0], this.point1[1] - this.point2[1]) * 180) / Math.PI;
  }
}

export function getAreaKm2(feature: Feature<Geometry>) {
  const area = getArea(feature.getGeometry()!);
  const areaKm2 = Math.round((area / 1000000) * 100) / 100;
  return areaKm2;
}

export function getFeatureFromWktFile(file: string) {
  const input = file.split("\n")[1].split(";");

  const system = `EPSG:${input[0]}`;
  const wkt = input[1];

  const format = new WKT();
  const feature = format.readFeature(wkt, {
    dataProjection: system,
    featureProjection: "EPSG:3857"
  });

  return feature;
}

export function addWktFeature(file: string, layerSource: Vector<Geometry>): Feature<Geometry> {
  const feature = getFeatureFromWktFile(file);
  layerSource.addFeature(feature);
  return feature;
}

export function generateGeofence(studyAreaFeature: Feature<Geometry>, geofenceBoundMetres: number) {
  const geomType = studyAreaFeature.getGeometry()!.getType();

  if (geomType == "MultiPolygon") {
    return expandMultiPolygonCoords(studyAreaFeature.getGeometry() as MultiPolygon, geofenceBoundMetres);
  } else if (geomType == "Polygon") {
    return expandPolygonCoords(studyAreaFeature.getGeometry() as Polygon, geofenceBoundMetres);
  } else {
    console.error("Unexpected geom type ", geomType);
    throw "Unexpected geom type " + geomType;
  }
}

//The following "expand" functions are used to expand a study area into a geofence
function expandPolygonCoords(poly: Polygon, geofenceBoundMetres: number) {
  const newPoly = poly.clone();
  const coords = newPoly.getCoordinates()[0];

  const newCoords = expandCoords(coords, poly, geofenceBoundMetres);

  return new Feature({
    geometry: new Polygon([newCoords])
  });
}

function expandMultiPolygonCoords(multiPolygon: MultiPolygon, geofenceBoundMetres: number) {
  const newMultiPolygon = multiPolygon.clone();
  const coordSet = newMultiPolygon.getCoordinates();

  const newCoordsSet = coordSet.map((coords) => {
    const currentPoly = new Polygon([coords[0]]);
    return expandCoords(coords[0], currentPoly, geofenceBoundMetres);
  });

  return new Feature({
    geometry: new MultiPolygon([newCoordsSet])
  });
}

function expandCoords(coords: Coordinate[], poly: Polygon, geofenceBoundMetres: number) {
  const rad = (angle: number) => (angle * Math.PI) / 180;
  const objectMatches = (a: object, b: object) => JSON.stringify(a) === JSON.stringify(b);

  const newCoords = [];
  for (let i = 0; i < coords.length; i++) {
    //for every point on the edge of the polygon, calculate the angle between the two lines that create it
    const previousPointIndex = i > 0 ? i - 1 : coords.length - 2;
    const nextPointIndex = i != coords.length - 1 ? i + 1 : 1;

    //These two lines meet to form the current vertex
    const line1 = new GeomLine(coords[previousPointIndex], coords[i]);
    const line2 = new GeomLine(coords[i], coords[nextPointIndex]);

    const averageAngle = (line1.angle1() + line2.angle2()) / 2;

    const x = coords[i][0];
    const y = coords[i][1];

    //First, we move 1 metre along the angle axis and check if that puts us inside or outside of the shape
    //This tells us whether or not we need to switch to the opposite direction
    let angleOffset = 0;
    const checkX = x + Math.sin(rad(averageAngle));
    const checkY = y + Math.cos(rad(averageAngle));

    if (poly.intersectsCoordinate([checkX, checkY])) {
      angleOffset = -180;
    }

    const newX = x + geofenceBoundMetres * Math.sin(rad(averageAngle + angleOffset));
    const newY = y + geofenceBoundMetres * Math.cos(rad(averageAngle + angleOffset));

    //const distance = Math.sqrt(Math.pow(newX - x, 2) + Math.pow(newY - y, 2));
    newCoords.push({
      newCoord: [newX, newY],
      originalCoord: [x, y],
      angles: [Math.sin(rad(averageAngle + angleOffset)), Math.cos(rad(averageAngle + angleOffset))]
    });
  }

  //Remove any overlapping coordinates
  let filteredCoords = newCoords.slice();

  document.body.style.cursor = "progress";
  newCoords.forEach((vertexSet) => {
    //Iterate back along the bound until you are no longer inside the geofence polygon
    let metres = geofenceBoundMetres + 1;
    let found = false;

    while (metres > 0 && found == false) {
      //Move back along the bound 1 metre and test if you're inside the geofencepolygon
      const testCoordX = vertexSet.originalCoord[0] + metres * vertexSet.angles[0];
      const testCoordY = vertexSet.originalCoord[1] + metres * vertexSet.angles[1];

      const testCoordX1 = vertexSet.originalCoord[0] + (metres - 1) * vertexSet.angles[0];
      const testCoordY1 = vertexSet.originalCoord[1] + (metres - 1) * vertexSet.angles[1];

      const intermediateCoords = filteredCoords.slice();
      intermediateCoords.filter((ic) => objectMatches(ic, vertexSet))[0].newCoord = [testCoordX1, testCoordY1];
      const intermediatePolygon = new Polygon([intermediateCoords.map((fc) => fc.newCoord)]);

      if (!intermediatePolygon.intersectsCoordinate([testCoordX, testCoordY])) {
        filteredCoords.filter((fc) => objectMatches(fc, vertexSet))[0].newCoord[0] = testCoordX;
        filteredCoords.filter((fc) => objectMatches(fc, vertexSet))[0].newCoord[1] = testCoordY;
        found = true;
      } else metres -= 10;
    }
    if (found == false) {
      filteredCoords = filteredCoords.filter((fc) => !objectMatches(fc, vertexSet));
    }
  });

  //Iterate again to check whether any points are within the study area
  filteredCoords.forEach((vertexSet) => {
    if (poly.intersectsCoordinate(vertexSet.newCoord)) {
      filteredCoords = filteredCoords.filter((fc) => !objectMatches(fc, vertexSet));
    }
  });

  document.body.style.cursor = "auto";
  return filteredCoords.map((fc) => fc.newCoord);
}


export function generateWKT(feature: Feature<Polygon> | Feature<MultiPolygon>) {
  const geomType = feature.getGeometry()!.getType();

  if (geomType === "MultiPolygon") {
    const coords = getMultiPolygonLatLonCoords(feature as Feature<MultiPolygon>);
    return generateWKTMultiPolygon(coords);
  } else if (geomType === "Polygon") {
    const coords = getPolygonLatLonCoords(feature as Feature<Polygon>);
    return generateWKTPolygon(coords);
  } else {
    console.error("Unexpected geom type ", geomType);
    throw "Unexpected geom type " + geomType;
  }
}

export function generateWKTPolygon(coords: Coordinate[]) {
  let wkt = "location_srid;location_wkt\n27700;POLYGON((";

  coords.forEach((point) => {
    const gridRefPoint = olProj.transform(point, "EPSG:4326", "EPSG:27700");
    wkt += `${gridRefPoint[0]} ${gridRefPoint[1]},`;
  });
  wkt = wkt.slice(0, -1) + `))`;

  return wkt;
}

export function generateWKTMultiPolygon(coordSet: Coordinate[][]) {
  let wkt = "location_srid;location_wkt\n27700;MULTIPOLYGON((";

  coordSet.forEach((coords) => {
    wkt += "(";
    coords.forEach((point) => {
      const gridRefPoint = olProj.transform(point, "EPSG:4326", "EPSG:27700");
      wkt += `${gridRefPoint[0]} ${gridRefPoint[1]},`;
    });
    wkt = wkt.slice(0, -1) + ")";
  });
  wkt += `))`;

  return wkt;
}

export function getPolygonLatLonCoords(feature: Feature<Polygon>) {
  const featureCoords = feature.getGeometry()!.getCoordinates()[0];
  const latLonCoords = [] as Coordinate[];

  featureCoords.forEach((coord) => {
    latLonCoords.push(olProj.transform(coord, "EPSG:3857", "EPSG:4326"));
  });

  return latLonCoords;
}

function getMultiPolygonLatLonCoords(feature: Feature<MultiPolygon>) {
  const featureCoordsSet = feature.getGeometry()!.getCoordinates()[0];
  const latLonCoordSet = [] as Coordinate[][];

  featureCoordsSet.forEach((featureCoords) => {
    const latLonCoords = [] as Coordinate[];
    featureCoords.forEach((coord) => {
      latLonCoords.push(olProj.transform(coord, "EPSG:3857", "EPSG:4326"));
    });
    latLonCoordSet.push(latLonCoords);
  });

  return latLonCoordSet;
}