import { Directive, DirectiveBinding } from "vue";

function getOffset(el: HTMLElement, tooltip: HTMLElement) {
  const rect = el.getBoundingClientRect();

  let left = rect.left + window.scrollX - tooltip.clientWidth / 2 + el.clientWidth / 2;

  //bounds check to see if the tooltip goes off the screen
  if (left + tooltip.clientWidth + 20 > screen.width)
    left = screen.width - tooltip.clientWidth - 20;

  return {
    left,
    top: rect.bottom + window.scrollY + 7
  };
}

let tooltipIndex = 0;

export const tooltipDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding<string>) {
    tooltipIndex += 1;
    const id = `tooltip-${tooltipIndex}`;

    const tooltipElem = document.createElement('div');
    tooltipElem.innerHTML = binding.value;
    tooltipElem.id = id
    tooltipElem.classList.add("cl-tooltip");

    el.addEventListener("mouseenter", () => {
      el.setAttribute("aria-describedby", id);
      document.getElementById('cl-tooltips')!.appendChild(tooltipElem);
      tooltipElem.style.transform = `translate(${getOffset(el, tooltipElem).left}px, ${getOffset(el, tooltipElem).top}px)`;
    });
    el.addEventListener("mouseleave", () => {
      el.removeAttribute("aria-describedby");
      try {
        document.getElementById('cl-tooltips')!.removeChild(tooltipElem);
      } catch (e) {
        return;
      }
    });
  },
  beforeUnmount(el: HTMLElement) {
    el.dispatchEvent(new MouseEvent('mouseleave'));
  }
}