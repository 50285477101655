import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"font-size":"xx-large","margin":"25px 10px"} }

import DataOverview from "./components/DataOverview/DataOverview.vue";
import Summary from "./components/Summary/Summary.vue";
import Visitors from "./components/Visitors/Visitors.vue"
import Journeys from "./components/Journeys/Journeys.vue";
//import EVAnalysis from "./components/EVAnalysis/EVAnalysis.vue";
import Traversing from "./components/Traversing/Traversing.vue";
import Dwells from "./components/Dwells/Dwells.vue";
import Definitions from "./components/Definitions/Definitions.vue";

import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import LineTabbedPanel from "@/components/LineTabbedPanel.vue";
import { useGetRequestedReportRequest } from "@/composables/data/reportComposables";
import { getQueryParams } from "@/util/urlHelpers";
import { provide } from "vue";


export default _defineComponent({
  setup(__props) {

const reportDetailsRequest = useGetRequestedReportRequest(getQueryParams()["datasetid"]);
provide("reportDetailsRequest", reportDetailsRequest)

const availableTabs = [
  { name: 'Data Overview', component: DataOverview },
  { name: 'Summary', component: Summary },
  //{ name: 'EV Analysis', component: EVAnalysis }, TODO: Add back in when the EV score model is back
  { name: 'Visits', component: Visitors },
  { name: 'Journeys', component: Journeys },
  { name: 'Traversing', component: Traversing },
  { name: 'Dwells', component: Dwells },
  { name: 'Definitions', component: Definitions }
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DataFetchDisplay, { request: _unref(reportDetailsRequest) }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, "EV Chargepoint Insights - " + _toDisplayString(_unref(reportDetailsRequest).data?.details.clientProjectName), 1),
      _createVNode(LineTabbedPanel, {
        "indexed-by-tab-name": true,
        tabs: availableTabs.map(tab => tab.name)
      }, _createSlots({ _: 2 }, [
        _renderList(availableTabs, (tab, index) => {
          return {
            name: tab.name,
            fn: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component)))
            ])
          }
        })
      ]), 1032, ["tabs"])
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})