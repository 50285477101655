
import { useDatatable, NormalizedColumn, DatatableColumn, DatatableOptions, SortOrder } from "../composables/globalComponents/datatableComposables";
import { defineComponent, PropType, ref, computed } from "vue";
import { StringIndexable } from "../models/appModels";

class DatatableFactory<T extends StringIndexable> {
  define() {
    return defineComponent({
      props: {
        data: {
          type: Array as PropType<T[]>,
          required: true
        },
        columns: {
          type: Array as PropType<DatatableColumn<T>[]>,
          required: true
        },
        options: {
          type: Object as PropType<DatatableOptions<T>>,
          required: false
        },
        focusedRow: {
          type: Number,
          required: false
        }
      },
      setup(props) {
        const composition = useDatatable({
          data: computed(() => props.data),
          columns: computed(() => props.columns),
          options: props.options
        });

        const formatNumber = (num: number) => {
          const numFormatter = new Intl.NumberFormat('en-GB')
          return numFormatter.format(num);
        };
        const formatCell = (text: string) => {
          if (parseInt(text).toString() == text)
            return formatNumber(parseInt(text));
          return text;
        };
        const getCellColor = (row: StringIndexable, colorField: string) => {
          if (!row[colorField]) return "inherit";
          return row[colorField] as string;
        };
        const getCellBackgroundColor = (row: StringIndexable, bgColorField: string) => {
          if (!row[bgColorField]) return "inherit";
          return row[bgColorField] as string;
        };
        const getHeaderClass = (col: NormalizedColumn<any>, currentSortCol: string) => {
          if (!col.sortable) return {
            'datatable-sorted': false,
            'datatable-sortable': false
          }
          else if (currentSortCol !== col.field) return {
            'datatable-sorted': false,
            'datatable-sortable': true
          }
          return {
            'datatable-sorted': true,
            'datatable-sortable': true
          }
        }
        const getSortStyle = (col: NormalizedColumn<any>, currentSortCol: string, currentSortOrder: SortOrder) => {
          if (!col.sortable) return "";

          const style = {
            "background-repeat": "no-repeat",
            "background-position": "center right",
            "background-image": ""
          };

          if (currentSortCol !== col.field) style["background-image"] = `url(${require("@/assets/images/sort_both.png")})`;
          if (currentSortCol === col.field && currentSortOrder === "asc") style["background-image"] = `url(${require("@/assets/images/sort_asc.png")})`;
          if (currentSortCol === col.field && currentSortOrder === "desc") style["background-image"] = `url(${require("@/assets/images/sort_desc.png")})`;

          return style;
        };

        function handleRowClick(row: T) {
          if (props.options?.rowClickCallback) props.options.rowClickCallback(row)
        }
        function handleMouseover(row: T) {
          if (props.options?.mouseoverCallback) props.options.mouseoverCallback(row)
        }
        function handleMouseleave(row: T) {
          if (props.options?.mouseleaveCallback) props.options.mouseleaveCallback(row)
        }

        const hiddenDownload = ref<HTMLAnchorElement>();
        const hiddenClipboard = ref<HTMLTextAreaElement>();
        const createCsv = () => {
          const header = props.data.map((col) => col.name).join(",") + "\r\n";
          const body = props.data.map((row) => composition.normalizedColumns.value.map((col) => row[col.field]).join(",")).join("\r\n");
          return header + body;
        }
        function exportToCsv() {
          if (!hiddenDownload.value) return;

          const csv = createCsv();
          hiddenDownload.value.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
          hiddenDownload.value.target = "_blank";
          if (props.options?.exports?.fileName) {
            hiddenDownload.value.download = props.options?.exports.fileName + ".csv";
          } else {
            hiddenDownload.value.download = "export.csv";
          }
          hiddenDownload.value.download = props.options?.exports?.fileName || "export.csv";
          hiddenDownload.value.click();
        }
        function exportToClipboard() {
          if (!hiddenClipboard.value) return;

          const csv = createCsv();
          hiddenClipboard.value.value = csv;
          hiddenClipboard.value.focus();
          hiddenClipboard.value.select();
          document.execCommand("copy");
        }

        return {
          ...composition,
          formatCell,
          getCellColor,
          getCellBackgroundColor,
          getHeaderClass,
          getSortStyle,
          handleRowClick,
          handleMouseover,
          handleMouseleave,
          exportToCsv,
          exportToClipboard
        }
      }
    })
  }
}

const main = new DatatableFactory().define();

export function GenericDatatable<T>() {
  return (main as unknown) as ReturnType<DatatableFactory<T>['define']>
}

export default main;
