import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"font-size":"xx-large","margin":"25px 10px"} }

import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import LineTabbedPanel from "@/components/LineTabbedPanel.vue";
import DataOverview from "./components/DataOverview/DataOverview.vue"
import Visitors from "./components/Visitors/Visitors.vue";
import Definitions from "./components/Definitions/Definitions.vue";
import { getQueryParams } from "@/util/urlHelpers";
import { provide } from "vue";
import { useGetRequestedReportRequest } from "@/composables/data/reportComposables";



export default _defineComponent({
  setup(__props) {

const reportDetailsRequest = useGetRequestedReportRequest(getQueryParams()["datasetid"])

provide("reportDetailsRequest", reportDetailsRequest)
provide("minZoom", 10);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DataFetchDisplay, { request: _unref(reportDetailsRequest) }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, "Footfall Analysis - " + _toDisplayString(_unref(reportDetailsRequest).data?.details.clientProjectName), 1),
      _createVNode(LineTabbedPanel, { tabs: ['Data Overview', 'Visits', 'Definitions'] }, {
        "0": _withCtx(() => [
          _createVNode(DataOverview)
        ]),
        "1": _withCtx(() => [
          _createVNode(Visitors)
        ]),
        "2": _withCtx(() => [
          _createVNode(Definitions)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})