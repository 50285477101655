import { Map } from "ol";
import LayerSwitcher from "ol-layerswitcher";
import { Tile as TileLayer, Group as LayerGroup } from "ol/layer";
import { Options } from "ol/layer/Base";
import {Stamen as StamenSource, XYZ as XYZSource, OSM as OSMSource} from "ol/source";

const stamenLight = new TileLayer({
  title: "Stamen Light",
  type: "base",
  source: new StamenSource({
    layer: "toner-lite"
  })
} as Options);

const stamenTerrain = new TileLayer({
  title: "Stamen Terrian",
  type: "base",
  source: new StamenSource({
    layer: "terrain"
  })
} as Options);

const token = "pk.eyJ1IjoiY2l0aWxvZ2lrIiwiYSI6ImNqN2dmNWx4cDE4OWcyd251aGwycGNwcWMifQ.3BVvChSSimvNwfslWdeMJw";

const mapboxLight = new TileLayer({
  title: "Mapbox Light",    
  type: "base",
  source: new XYZSource({
    url: "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=" + token,
    attributions:
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> ' +
        '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
        '<strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
  })
} as Options);

const mapboxDark = new TileLayer({
  title: "Mapbox Dark",    
  type: "base",
  source: new XYZSource({
    url: "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/256/{z}/{x}/{y}?access_token=" + token,
    attributions:
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> ' +
        '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
        '<strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
  })
} as Options);

const mapboxSatellite = new TileLayer({
  title: "Mapbox Satellite",    
  type: "base",
  source: new XYZSource({
    url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=" + token,
    attributions:
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> ' +
        '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> ' +
        '<strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
  })
} as Options);

const osmBasemap = new TileLayer({
  title: "OpenStreet Map",
  type: "base",
  source: new OSMSource()
} as Options);


export const basemapsGroup = new LayerGroup({
  title: "Background Maps",
  layers: [stamenTerrain, stamenLight, mapboxDark, mapboxLight, mapboxSatellite, osmBasemap ]
} as Options);


export function addLayerSwitcher(map: Map) {
  const layerSwitcher = new LayerSwitcher({
    groupSelectStyle: 'children'
  });

  map.addControl(layerSwitcher);
}