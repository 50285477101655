import { Map } from "ol";
import Control from "ol/control/Control";
import BaseEvent from "ol/events/Event";

class ZoomRangeTextControl extends Control {
  label: HTMLLabelElement;
  zoomListener: (evt: BaseEvent) => any;

  constructor(opt_options?: any) {
    const options = opt_options || {};

    const element = document.createElement('div');
    element.className = 'map-zoom-range-text-control ol-unselectable ol-control';

    super({
      element: element,
      target: options.target,
    });

    this.label = document.createElement('label');
    element.appendChild(this.label);

    this.zoomListener = () => { };
  }

  setZoomRange(minZoom: number, maxZoom: number) {
    const map = this.getMap()!

    this.zoomListener = () => {
      const mapZoom = map.getView().getZoom()!;
      if (mapZoom < minZoom || mapZoom > maxZoom) {
        this.element.style.setProperty("display", "none");
      }
      else {
        this.element.style.setProperty("display", "block");
      }

    }
  }

  enable(text: string) {
    const map = this.getMap()!;
    map.on("moveend", this.zoomListener);
    map.dispatchEvent("moveend");

    this.label.innerHTML = text;
  }

  disable() {
    this.zoomListener = () => { };
  }
}

export function addZoomRangeText(map: Map, text: string, minZoom: number, maxZoom: number) {
  const zoomRangeTextControl = new ZoomRangeTextControl();
  map.addControl(zoomRangeTextControl);

  zoomRangeTextControl.setZoomRange(minZoom, maxZoom);
  zoomRangeTextControl.enable(text);

  return zoomRangeTextControl;
}