import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "dataset-tourism-viewer" }

import { onMounted, provide, ref, watch, watchEffect } from "vue";
import Map from "ol/Map";
import * as olProj from "ol/proj";
import { defaults } from "ol/control";

import MapGradientLegend from "@/components/Maps/MapGradientLegend.vue";
import MapControlMenu from "./components/MapControlMenu.vue";
import { addLayerSwitcher, basemapsGroup } from "@/util/maps/mapLayers";
import View from "ol/View";
import { addOverlayPopup, removeAllOverlays } from "@/util/maps/mapOverlayUtils";
import { createZonesLayer, MapZoneMagnitude, setAvailableZones, setZoneMagnitudes } from "@/util/maps/mapZoneUtils";
import VectorImageLayer from 'ol/layer/VectorImage';
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { Stage } from "./util/types";
import { clearAllFeatureParams } from "@/util/maps/mapHelpers";
import { useTourismRequest, useAnalysisFilter, useValuesSlider } from "./util/composables";
import VectorSource from "ol/source/Vector";
import { getQueryParams } from "@/util/urlHelpers";
import envConfig from "@/config/envConfig";
import { authenticatedRequest } from "@/composables/requestComposables";
import { addLoadingZonesText } from "@/util/maps/loadingZonesTextControl";
import { GeoJSON } from "ol/format";


export default _defineComponent({
  setup(__props) {

const mapRef = ref<HTMLElement>();
const popupWrapper = ref<HTMLDivElement>();
const stage = ref(Stage.Selecting);

const datasetId = getQueryParams()["datasetid"]

function getDatasetViewerZonesPath(datasetId: string | number, zoneFileName: string) {
  const url = `${envConfig.api_address}/datasetsapi/zones?datasetid=${datasetId}&zonefile=${zoneFileName}`
  return authenticatedRequest(encodeURI(url), { headers: new Headers() })
    .then(res => res.json())
    .then(data => data.signedUrl as string);
}

const zonesGeoJson = getDatasetViewerZonesPath(datasetId, "MSOAZones.json");

const featureIdProperty = "NCZID"

const datasetInfo = {
  name: "-",
  reportTemplate: "-",
  description: "-",
  dateCreated: "-",
  createdBy: "-",
  type: "-"
};

const focusedZones = ref<string[]>([])
provide("focusedZones", focusedZones);

if (featureIdProperty === undefined) throw "Must specify a featureIdProperty";
const selectedZoneIds = ref<number[]>([]);

const { analysisMode, canFilterByZone } = useAnalysisFilter();
const { request } = useTourismRequest();
const { valuesSlider } = useValuesSlider();

provide("selectedZones", selectedZoneIds);
provide("stage", stage);
provide("info", datasetInfo);

const resetMapMode = () => {
  clearAllFeatureParams(zonesLayer, "Selected", "Available");
  selectedZoneIds.value = [];

  if (canFilterByZone.value) {
    const features = zonesLayer.getSource().getFeatures();
    const availableZones = analysisMode.value.getSelectableZoneIds!(features)
    setAvailableZones(zonesLayer, availableZones, featureIdProperty!);
  }
}

let zonesLayer: VectorImageLayer<VectorSource<Geometry>>;

const startColour = [187, 50, 65];
const endColour = [0, 100, 36];
const numGroups = 10;

function makeColourset(x: number, y: number) {
  const getVal = (i: number, j: number) => {
    if (x - y === 0) return endColour[j]
    return startColour[j] + (((endColour[j] - startColour[j]) / (y - x)) * (i - x));
  }

  const c: string[] = new Array(numGroups);
  for (let i = x; i < y + 1; i++) {
    c[i] = `hsla(${getVal(i, 0)},${getVal(i, 1)}%,${getVal(i, 2)}%,0.6)`
  }
  return c;
}
let colours = makeColourset(0, numGroups);
const shadeFunction = ref((_: number, displayGroup: number) => colours[displayGroup]);

function displayZoneData(zones?: MapZoneMagnitude[]) {
  if (!zones) throw "No zones loaded...";
  setZoneMagnitudes(zonesLayer, zones, featureIdProperty!);
  return zones;
}
function updateMap() {
  clearAllFeatureParams(zonesLayer, "Available", "Magnitude");
  stage.value = Stage.Viewing;

  request.invoke(datasetId, selectedZoneIds.value).then(displayZoneData);
}
watchEffect(() => {
  if (!request.data?.length) return;
  colours = makeColourset(valuesSlider.value.low, valuesSlider.value.high)
  clearAllFeatureParams(zonesLayer, "Magnitude");
  displayZoneData(request.data.filter(z => z.displayGroup >= valuesSlider.value.low && z.displayGroup <= valuesSlider.value.high));
});

function resetFilters() {
  clearAllFeatureParams(zonesLayer, "Available", "Magnitude", "Selected");
  selectedZoneIds.value = [];

  stage.value = Stage.Selecting;
  resetMapMode();
}

function zoneClickedCallback(selected: Feature<Geometry>) {
  if (selected.get("Available") !== undefined || selected.get("Magnitude") !== undefined) {

    const alreadySelected = selected.get("Selected") as boolean | undefined;
    selected.set("Selected", !alreadySelected);

    if (alreadySelected) selectedZoneIds.value = selectedZoneIds.value.filter(z => z !== selected.get(featureIdProperty!));
    else selectedZoneIds.value.push(selected.get(featureIdProperty!));
  }
}
function selectAllZones() {
  if (selectedZoneIds.value.length > 0) {
    selectedZoneIds.value = [];
    clearAllFeatureParams(zonesLayer, "Selected");
  } else {
    (zonesLayer.getSource() as VectorSource<Geometry>).getFeatures().forEach(feature => {
      if (feature.get("Magnitude")) zoneClickedCallback(feature)
    })
  }
}

function zoneHoveredCallback(currentlySelected: Boolean, selected: Feature<Geometry>) {
  const hoveredZoneId = selected.get(featureIdProperty) as number;
  if (!selectedZoneIds.value.includes(hoveredZoneId)) return;

  if (currentlySelected) focusedZones.value.push(hoveredZoneId.toString());
  else focusedZones.value = focusedZones.value.filter(z => z != hoveredZoneId.toString());
}

watchEffect(() => {
  if (stage.value === Stage.Viewing && request.data) {
    clearAllFeatureParams(zonesLayer, "Magnitude");
  }
});

onMounted(async () => {
  const map = new Map({
    layers: [basemapsGroup],
    target: mapRef.value as HTMLElement,
    view: new View({
      center: olProj.transform([-0.2, 51.5074], "EPSG:4326", "EPSG:3857"),
      zoom: 11
    }),
    controls: defaults({
      attribution: true,
      zoom: true
    })
  });
  addLayerSwitcher(map);


  const zonesLoadingControl = addLoadingZonesText(map);

  const zonesGeoJSONPath = await zonesGeoJson;
  const zonesSource = new VectorSource({
    url: zonesGeoJSONPath,
    format: new GeoJSON()
  });

  zonesLayer = createZonesLayer({ zonesSource, map, getShade: shadeFunction, zoneClickedCallback, zoneHoveredCallback, zonesLoadingControl });
  map.addLayer(zonesLayer);

  watch(focusedZones, (newVal) => {
    zonesLayer.getSource().getFeatures().forEach(f => {
      if (newVal.includes(f.get(featureIdProperty).toString())) {
        f.set("Focused", true);
      }
      else f.set("Focused", false);
    })
  }, { deep: true })

  watch(analysisMode, (newVal) => {
    resetMapMode();
    removeAllOverlays(map);

    addOverlayPopup(map, [{
      title: "Zone ID",
      featureProperty: featureIdProperty!
    },
    {
      title: newVal.magnitudeDisplayTitle.value,
      featureProperty: "Magnitude"
    }], popupWrapper.value!);
  })
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MapControlMenu, {
      onSubmit: updateMap,
      onReset: resetFilters,
      onSelectAllZones: selectAllZones
    }),
    _createElementVNode("div", {
      ref: mapRef,
      class: "cl-ol-map"
    }, null, 512),
    _createElementVNode("div", { ref: popupWrapper }, null, 512),
    _createVNode(MapGradientLegend)
  ]))
}
}

})