import { rosieIRGB, burgundyIRGB } from "@/util/chartHelpers";
import { jsPDF } from "jspdf";
import { Coordinate } from "ol/coordinate";
// @ts-ignore
import AmeyBanner from "@/assets/images/amey-banner.png";

export type LocationReportPDFParams = {
  title: string,
  coords?: Coordinate
}

function rosieText(doc: jsPDF) {
  doc.setTextColor(rosieIRGB.r, rosieIRGB.g, rosieIRGB.b);
}
function burgundyText(doc: jsPDF) {
  doc.setTextColor(burgundyIRGB.r, burgundyIRGB.g, burgundyIRGB.b);
}
function greyText(doc: jsPDF) {
  doc.setTextColor(106, 109, 113);
}
function blackText(doc: jsPDF) {
  doc.setTextColor(0, 0, 0);
}
function boldText(doc: jsPDF) {
  doc.setFont(doc.getFont().fontName, 'bold')
}
function normalText(doc: jsPDF) {
  doc.setFont(doc.getFont().fontName, 'normal')
}

function h1(doc: jsPDF) {
  doc.setFontSize(21);
}
function h2(doc: jsPDF) {
  doc.setFontSize(18);
}
function p(doc: jsPDF) {
  doc.setFontSize(11);
}

function makeHeightCursor() {
  let hCursor = 0;
  const addFn = (toAdd: number) => {
    hCursor += toAdd;
    return hCursor
  }
  return addFn;
}

function newPage(doc: jsPDF) {
  const page = doc.addPage();
  page.addImage(AmeyBanner, 0, 0, 210, 18);
  const h = makeHeightCursor();
  return {h, doc: page}
}

export async function createPDF(params: LocationReportPDFParams) {

  const doc = new jsPDF();

  const page1 = newPage(doc);
  doc.deletePage(1);

  doc.addImage(AmeyBanner, 0, 0, 210, 18);

  h1(page1.doc)
  boldText(page1.doc);
  greyText(page1.doc);
  page1.doc.text(`${params.title}`, 10, page1.h(30), { maxWidth: 190 });
  h2(page1.doc);
  normalText(page1.doc);
  page1.doc.text(`Executive Summary`, 10, page1.h(10), { maxWidth: 190 });
  p(page1.doc);
  blackText(page1.doc);

  //Visits
  h2(page1.doc);
  boldText(page1.doc);
  greyText(page1.doc);
  page1.doc.text('Visits', 10, page1.h(17));
  normalText(page1.doc);

  p(page1.doc);
  blackText(page1.doc);

  page1.doc.text("A visit is defined as a trip with a destination at the point or area of interest. One person can make multiple visits a day. Intra-zonal trips at the point of interest are discarded.", 10, page1.h(10), { maxWidth: 190 })
  page1.doc.text("This chart shows the average number of visits for each given day of the week. The dashed line shows the overall average.", 10, page1.h(12), { maxWidth: 190 })

  page1.doc.addImage(await svgToImg("#visits-chart-1 svg"), 50, page1.h(10), 105, 100);

  boldText(page1.doc);
  const visits1 = document.querySelector('#visits-insight-1') as HTMLParagraphElement;
  const visits2 = document.querySelector('#visits-insight-2') as HTMLParagraphElement;
  page1.doc.text(visits1?.innerText ?? "", 10, page1.h(60))
  page1.doc.text(visits2?.innerText ?? "", 10, page1.h(5))

  //Journeys
  h2(page1.doc);
  boldText(page1.doc);
  greyText(page1.doc);
  page1.doc.text('Journeys', 10, page1.h(12));

  p(page1.doc);
  blackText(page1.doc);
  normalText(page1.doc);
  page1.doc.text('A journey is a visit to the point or area of interest. In this section however, we are looking at the distance that visitors take in their journey to reach the study area.', 10, page1.h(10), { maxWidth: 190 });
  page1.doc.text("This chart shows the number of visits by the distance travelled to the study location.", 10, page1.h(12), { maxWidth: 190 })

  page1.doc.addImage(await svgToImg("#journeys-chart-1 svg"), 50, page1.h(10), 105, 100);

  boldText(page1.doc);
  const journeys1 = document.querySelector('#journeys-insight-1') as HTMLParagraphElement;
  const journeys2 = document.querySelector('#journeys-insight-2') as HTMLParagraphElement;
  page1.doc.text(journeys1?.innerText ?? "", 10, page1.h(60))
  page1.doc.text(journeys2?.innerText ?? "", 10, page1.h(5))


  const page2 = newPage(doc);

  //Purpose
  h2(doc);
  boldText(doc);
  greyText(doc);
  doc.text('Purpose', 10, page2.h(25));

  doc

  p(doc);
  blackText(doc);
  normalText(doc);
  doc.text('The purpose of the visit classifies the visit as Home <=> Work, Home <=> Other, Work <=> Other, Other <=> Other.', 10, page2.h(10), { maxWidth: 190 });
  doc.text("Home <=> Work: defined as a visit from home to work and from work to home.", 10, page2.h(12), { maxWidth: 190 })
  doc.text("Home <=> Other: defined as a visit with the home location at either end of the trip.", 10, page2.h(6), { maxWidth: 190 })
  doc.text("Work <=> Other: defined as a visit to or from the work location.", 10, page2.h(6), { maxWidth: 190 })
  doc.text("Other <=> Other: defined as a visit to and from a non-work, non-home location.", 10, page2.h(6), { maxWidth: 190 })

  doc.text("This chart shows the the number of journeys grouped by journey purpose.", 10, page2.h(12), { maxWidth: 190 })
  doc.addImage(await svgToImg("#purpose-chart-1 svg"), 50, page2.h(10), 105, 100);

  boldText(doc);
  const purpose1 = document.querySelector('#purpose-insight-1') as HTMLParagraphElement;
  doc.text(purpose1?.innerText?.replace(/⇔/, '<=>') ?? "", 10, page2.h(58))


  //Mode
  h2(doc);
  boldText(doc);
  greyText(doc);
  doc.text('Mode', 10, page2.h(14));

  doc

  p(doc);
  blackText(doc);
  normalText(doc);
  doc.text('The mode of transport used to pay the visit to the point or area of interest are the following.', 10, page2.h(10));
  doc.text("Active: primarily made up of on foot visits and cyclists. Other types of slow speed mode make up the remainder of the active trips e.g. electric scooters.", 10, page2.h(6), { maxWidth: 190 })
  doc.text("Road: visits classified as road are primarily made up of light transportation vehicles (cars and light goods vehicles) as well as bus and public transport modes except for rail trips.", 10, page2.h(12), { maxWidth: 190 })
  doc.text("Rail: visits that used the National Rail infrastructure for at least 10% of the trip.", 10, page2.h(12), { maxWidth: 190 })
  doc.text("HGV: visits made by heavy goods vehicles (HGV).", 10, page2.h(6), { maxWidth: 190 })
  
  doc.text("This chart shows the number of journeys by mode of transport.", 10, page2.h(12), { maxWidth: 190 })
  doc.addImage(await svgToImg("#mode-chart-1 svg"), 50, page2.h(10), 105, 100);

  boldText(doc);
  const mode1 = document.querySelector('#mode-insight-1') as HTMLParagraphElement;
  doc.text(mode1?.innerText ?? "", 10, page2.h(58))
  normalText(doc);

  //Dwells
  const page3 = newPage(doc);

  h2(doc);
  boldText(doc);
  greyText(doc);
  doc.text('Dwells', 10, page3.h(25));

  p(doc);
  blackText(doc);
  normalText(doc);
  doc.text("A dwell is defined as a stop in a person's journey itinerary that is longer than 30 minutes. Residents of the area are excluded from the dwelling analysis.", 10, page3.h(10));
  doc.text("Average dwell time by day refers to the average number of hours dwelled at the point or area of interest on a particular day of the week.", 10, page3.h(6), { maxWidth: 190 })
  
  doc.text("This chart shows the average dwell time by day.", 10, page3.h(12), { maxWidth: 190 })
  doc.addImage(await svgToImg("#dwells-chart-1 svg"), 50, page3.h(10), 105, 100);

  boldText(doc);
  const dwells1 = document.querySelector('#dwells-insight-1') as HTMLParagraphElement;
  doc.text(dwells1?.innerText ?? "", 10, page3.h(58))
  normalText(doc);
  

  //Demographics
  h2(doc);
  boldText(doc);
  greyText(doc);
  doc.text('Demographics', 10, page3.h(14));


  p(doc);
  blackText(doc);
  normalText(doc);
  doc.text('Average visits by age band: the average number of visits grouped by each of the age bands available.', 10, page3.h(10));
  doc.text("Visitors by gender: the estimated number of visits by each gender. ", 10, page3.h(6), { maxWidth: 190 })
  
  doc.text("These charts show distribution of visits by age band & gender", 10, page3.h(12), { maxWidth: 190 })
  doc.addImage(await svgToImg("#demographics-chart-1 svg"), 30, page3.h(10), 60, 100);
  doc.addImage(await svgToImg("#demographics-chart-2 svg"), 130, page3.h(0), 50, 100);

  const demographics1 = document.querySelector('#demographics-insight-1') as HTMLParagraphElement;
  doc.text(demographics1?.innerText ?? "", 10, page3.h(58))

  await new Promise(resolve => setTimeout(resolve, 100));
  doc.save(`${params.title}`);
}

async function svgToImg(querySelector: string) {
  const chart = document.querySelector(querySelector)! as SVGElement;
  return await base64SvgToBase64Png(chart) as string;
}
async function base64SvgToBase64Png(svg: SVGElement) {
  const svgClone = svg.cloneNode(true) as SVGElement;

  //Parse the svg text to find width & height
  const svgText = svgClone.outerHTML;
  const wMatch = svgText.match(/width="(\d+)/m);
  const width = wMatch && wMatch[1] ? parseInt(wMatch[1], 10) : 200;
  const hMatch = svgText.match(/height="(\d+)/m);
  const height = hMatch && hMatch[1] ? parseInt(hMatch[1], 10) * 2 : 200;

  svgClone.setAttribute('width', width.toString());
  svgClone.setAttribute('height', height.toString());

  const data = new XMLSerializer().serializeToString(svgClone);
  const blob = new Blob([data], { type: 'image/svg+xml' });
  const url = window.URL.createObjectURL(blob);

  return new Promise(resolve => {
    const img = document.createElement('img');
    img.onload = () => {
      document.body.appendChild(img);
      const canvas = document.createElement("canvas");
      document.body.removeChild(img);
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const data = canvas.toDataURL('image/png');
      resolve(data);
    };
    img.src = url;
    img.width = img.width * 0.1
    img.height = img.height * 0.1
  });
}