<template>
  <div style="padding:0 5px">
    <h2>Definitions</h2>
    <hr>

    <h3>Visits</h3>
    <p>A visit is defined as a trip with a destination at the point or area
      of interest. One person can make multiple
      visits a day. Intra-zonal trips at the point of interest are discarded.</p>

    <p><span style="font-weight:bold">Average daily visits</span> The average number of visits (trip destinations) made
      to the point of interest on a particular day.</p>

    <p><span style="font-weight:bold">Average hourly visits</span> The average number of visits (trip destinations) made
      to the point of interest at a particular hour of the day.
    </p>

    <p><span style="font-weight:bold">Repeat visit</span> If a person makes more than one visit to the point of interest
      on the same calendar week, all visits made by that
      person on that week are categorised as repeated.</p>

    <hr>

  </div>
</template>