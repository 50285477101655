import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-md-4" }
const _hoisted_2 = { class: "control-label input-group-text" }
const _hoisted_3 = { class: "col-md-5" }

import OptionSelect from "@/components/OptionSelect.vue";
import InfoPopup from "@/components/InfoPopup.vue";
import { computed, nextTick, onMounted, PropType, ref, watch } from "vue";

type OptionConfig = {
  id?: string|number,
  name: string
}

type Config = {
  id?: number;
  displayTitle: string;
  tooltip: string;
  type: number;
  options: OptionConfig[];
}


export default _defineComponent({
  props: {
  config: {
    type: Object as PropType<Config>,
    required: true
  },
  modelValue: {
    type: [Object, Array]
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit }) {

const props = __props



const multiSelectElem = ref<HTMLElement>();
const hiddenValidationInputElem = ref<HTMLInputElement>();
const results = ref<any>([]);

const mounted = ref(false);
onMounted(() => mounted.value = true);

const mode = computed(() => {
  const multiselect = props.config.type === 1;
  if (multiselect) return "tags";
  return "single";
})

onMounted(() => {
  const modelValue = (props.modelValue as any)
  if (modelValue?.selectedOptions?.length > 0)
    if (mode.value == "single") {
      results.value = modelValue.selectedOptions[0].id;
    } else {
      results.value = modelValue.selectedOptions.map((o: { id: any; }) => o.id);
    }
});

const options = computed(() => {
  return props.config.options.map(op => ({
    value: op.id,
    label: op.name
  }));
});

async function getValidationArgument() {
  if (!multiSelectElem.value?.children) return;
  const styleElem = multiSelectElem.value!.children[0];
  return {
    styleElem
  };
}

watch(results, (newVal) => {
  nextTick(() => {
    if (!hiddenValidationInputElem.value) return;

    hiddenValidationInputElem.value.value = newVal.toString();
    hiddenValidationInputElem.value.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      }));

    const selectedOptions = mode.value === "single" ?
      props.config.options.filter(o => o.id == (newVal as any)) :
      props.config.options.filter(o => (newVal as any[]).includes(o.id));


    emit("update:modelValue", {
      ...props.config,
      selectedOptions
    });
  });
}, { deep: true });

return (_ctx: any,_cache: any) => {
  const _directive_validate = _resolveDirective("validate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", null, [
        _createElementVNode("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(__props.config.displayTitle) + " ", 1),
          (__props.config.tooltip)
            ? (_openBlock(), _createBlock(InfoPopup, {
                key: 0,
                message: __props.config.tooltip,
                title: __props.config.displayTitle
              }, null, 8, ["message", "title"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(OptionSelect, {
        modelValue: results.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (results.value = $event)),
        mode: _unref(mode),
        createTag: false,
        options: _unref(options),
        ref: multiSelectElem
      }, null, 8, ["modelValue", "mode", "options"]),
      (mounted.value)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            ref: hiddenValidationInputElem,
            style: {"display":"none"}
          }, null, 512)), [
            [_directive_validate, getValidationArgument()]
          ])
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})