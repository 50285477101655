import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, isRef as _isRef, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"font-weight":"bold","margin-right":"4px"} }
const _hoisted_2 = {
  key: 0,
  style: {"color":"#ee2222","font-weight":"bold"}
}
const _hoisted_3 = { key: 2 }

import { computed, PropType, Ref, ref, watch } from "vue";
import { DatasetFilter, DatasetFilterOption } from "@/models/mapViewerModels";
import OptionSelect from "@/components/OptionSelect.vue";
import InfoPopup from "@/components/InfoPopup.vue";


export default _defineComponent({
  props: {
  filter: {
    type: Object as PropType<DatasetFilter>,
    required: true
  },
  modelValue: {
    type: Array,
    required: true
  },
  disabled: Boolean
},
  emits: ["update:modelValue"],
  setup(__props, { emit }) {

const props = __props




const mode = computed(() => {
  if (props.filter.multiSelect) return "tags";
  return "single";
});

let results: Ref<any>;
if (props.filter.multiSelect) {
  results = ref<any>(props.modelValue);
} else {
  results = ref<any>(props.modelValue[0]);
}


watch(results, () => {
  if (props.filter.multiSelect) emit("update:modelValue", results.value);
  else {
    if (results.value === null || results.value === "") emit("update:modelValue", []);
    else emit("update:modelValue", [results.value]);
  }
});

const selectedOptionDescription = computed(() => {
  if (props.filter.multiSelect || props.filter.selectedValues.length !== 1) return "";

  let selectedOption: DatasetFilterOption;
  if (typeof props.filter.selectedValues[0] === "string") selectedOption = props.filter.options.filter(o => o.value === props.filter.selectedValues[0])[0];
  else selectedOption = props.filter.options.filter(o => o.value === props.filter.selectedValues[0])[0];

  if (!selectedOption) return;

  if (selectedOption.description) return selectedOption.description;
  return "";
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(__props.filter.name), 1),
    (__props.filter.required && __props.modelValue.length === 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " *"))
      : _createCommentVNode("", true),
    (__props.filter.multiSelect)
      ? (_openBlock(), _createBlock(InfoPopup, {
          key: 1,
          message: "Select to filter data or leave blank to select all"
        }))
      : _createCommentVNode("", true),
    _createVNode(OptionSelect, {
      modelValue: _unref(results),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(results) ? results.value = $event : results = $event)),
      mode: _unref(mode),
      options: __props.filter.options,
      disabled: __props.disabled,
      placeholder: `Select ${__props.filter.name}`
    }, null, 8, ["modelValue", "mode", "options", "disabled", "placeholder"]),
    (_unref(selectedOptionDescription))
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_unref(selectedOptionDescription)), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})