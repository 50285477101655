<template>
  <h2>New Features</h2>
  <ul>
    <li>CL-374 Added the interactive map tool to the EV Chargepoint Insights datasets.</li>
    <li>CL-348 Implemented colour-coded map pins for EV Chargepoint Insights datasets when viewed in the map view, to
      aid navigation. </li>
    <li>CL-292 Added a new section under Help & Support containing the history of Portal release notes. Additionally,
      users will see a popup with the latest release notes upon login.</li>
    <li>CL-434 Added a new section under Help & Support containing a detailed outline of the methodology used in
      gathering mobile network data and generating insights.</li>
    <li>CL-403, CL-401 Implemented key automation to zone system generation and other aspects of dataset creation.
    </li>
  </ul>

  <h2>Changes</h2>
  <ul>
    <li>CL-456 Implemented some performance improvements to the API database.</li>
    <li>CL-442 Implemented a new version number system for releases.</li>
  </ul>

  <h2>Fixes</h2>
  <ul>
    <li>CL-453 Fixed an issue where users could see additional columns in the spreadsheet download.</li>
    <li>CL-453 Fixed an issue where users could select dates in the wizard where no data were available.</li>
  </ul>
</template>
<script>
export default {
  name: "1.0.0"
}
</script>