import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "portlet light" }
const _hoisted_2 = { class: "portlet-body" }

import releaseNotes from "@/components/ReleaseNotes/releases";
import { computed } from "vue";
import Accordion from "@/components/Accordion.vue";


export default _defineComponent({
  setup(__props) {

const reversedOrder = computed(() => releaseNotes.reverse())

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Accordion, {
        tabs: _unref(reversedOrder).map(item => `v${item.name}`)
      }, _createSlots({ _: 2 }, [
        _renderList(_unref(reversedOrder), (release, key) => {
          return {
            name: key,
            fn: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(release), null, null, 512)), [
                [_vShow, key === _unref(releaseNotes).length-1]
              ])
            ])
          }
        })
      ]), 1032, ["tabs"])
    ])
  ]))
}
}

})