export function getCookie(name: string) {
  const cookie: {[key:string]: string} = {};
  document.cookie.split(";").forEach((el) => {
    const [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function setCookie(cname: string, cvalue: any, expirySeconds: number) {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + expirySeconds * 1000);

  const expires = "expires=" + expiryDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires; // + ";path=/";
}

export function deleteCookie(name: string) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
