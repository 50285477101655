import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import Modal from "./Modal.vue";
import envConfig from "@/config/envConfig";
import DataFetchDisplay from "@/components/DataFetchDisplay.vue";
import { computed } from "vue";
import { useModals } from "@/composables/modalComposables";
import { getServices, logout } from "@/composables/appComposables";
import { authenticatedRequest, useRequest } from "@/composables/requestComposables";


export default _defineComponent({
  setup(__props) {

const { popModal } = useModals();
const portalService = computed(() => getServices().filter((s: any) => s.name === "CitiLogik Portal")[0]);

const getTAndCRequest = useRequest({
  requestCallback() {
    const headers = new Headers();
    const url = `${envConfig.admin_address}/api/services/app/UserProfile/GetServiceTermsAndConditions?serviceId=${portalService.value.id}`

    return authenticatedRequest(url, { headers })
      .then(response => response.json())
      .then(resJson => resJson.result as string);
  },
  requestImmediately: true
});

const acceptTermsRequest = useRequest({
  requestCallback() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const url = `${envConfig.admin_address}/api/services/app/UserProfile/AcceptTermsAndConditions`;

    const input = {
      serviceId: portalService.value.id
    };

    return authenticatedRequest(url, { headers, method: "POST", body: JSON.stringify(input) })
      .then(res => {
        if (res.ok) popModal();
        else {
          throw new Error("Unable to accept terms " + res.status);
        }
      })
  },
  errorModal: true
});

const _logout = () => {
  popModal();
  logout();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    title: "Terms and Conditions",
    disableClosing: true
  }, {
    default: _withCtx(() => [
      _createVNode(DataFetchDisplay, {
        requests: [_unref(getTAndCRequest), _unref(acceptTermsRequest)]
      }, {
        default: _withCtx(() => [
          (_unref(getTAndCRequest).data)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _unref(getTAndCRequest).data
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            id: "accept-terms",
            type: "button",
            class: "btn blue",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(acceptTermsRequest).invoke()))
          }, "Accept"),
          _createElementVNode("button", {
            id: "reject-terms",
            type: "button",
            class: "btn btn-danger",
            onClick: _logout
          }, "Reject")
        ]),
        _: 1
      }, 8, ["requests"])
    ]),
    _: 1
  }))
}
}

})