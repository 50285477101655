import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { GenericDatatable } from '@/components/DataTable.vue';
import { isCitiLogikAdmin } from '@/composables/appComposables';
import { DatatableColumn, SortOrder, DatatableOptions } from '@/composables/globalComponents/datatableComposables';
import { UserRequestedReport, RequestedReportDto, dtoToUserRequestedReport } from '@/models/customerReportsModels';
import { toStandardLocaleDate } from '@/util/dateHelpers';
import { computed, PropType } from 'vue';
import { openDatasetInfoModal } from '../util/composables';

type DatatableType = UserRequestedReport & RequestedReportDto & {
  statusDisplay: {
    content: string,
    type: string
  },
  creationDate: string,
  startDateDisplay: string
};


export default _defineComponent({
  props: {
  datasets: {
    type: Array as PropType<(RequestedReportDto & UserRequestedReport)[]>,
    required: true
  }
},
  setup(__props) {

const props = __props


const getCreationDate = (userReport: RequestedReportDto) => toStandardLocaleDate(new Date(userReport.creationTime!));
const getStartDateDisplay = (userReport: UserRequestedReport) => toStandardLocaleDate(userReport.startDate!);
const getReportStatus = (userReport: RequestedReportDto) => {
  const content = ({
    0: 'Requested <i class="fa fa-spinner"></i>',
    1: 'In Progress <i class="fa fa-spinner"></i>',
    2: 'Completed <i class="fa fa-check"></i>'
  } as { [key: number]: string })[userReport.reportStatus!]

  return {
    type: 'html',
    content
  }
}

const datatableData = computed<DatatableType[]>(() => props.datasets.map(userReport => ({
  ...userReport,
  statusDisplay: getReportStatus(userReport),
  creationDate: getCreationDate(userReport),
  startDateDisplay: getStartDateDisplay(dtoToUserRequestedReport(userReport))
})));

const datatableColumns = computed<DatatableColumn<DatatableType>[]>(() => {
  const makeDateSort = (column: 'creationDate' | 'startDate') => (a: DatatableType, b: DatatableType, order: SortOrder) => {
    if (order === "asc")
      return new Date(a[column]!) > new Date(b[column]!) ? 1 : -1;
    return new Date(a[column]!) > new Date(b[column]!) ? -1 : 1;
  };
  const fields: DatatableColumn<DatatableType>[] = [
    { name: "Name", field: "clientProjectName", sortable: true },
    { name: "", field: "viewButton" },
    { name: "Template", field: "reportName", sortable: true },
    { name: "Created By", field: "requestedByUserName", sortable: true },
    {
      name: "Status", field: "statusDisplay", sortable: true, sortFunction: (a, b, order) => {
        if (order === "asc")
          return (a.reportStatus ?? 1) > (b.reportStatus ?? 0) ? 1 : -1
        return (a.reportStatus ?? 1) > (b.reportStatus ?? 0) ? -1 : 1
      }
    },
    {
      name: "Creation Date", field: "creationDate", sortable: true, sortFunction: makeDateSort('creationDate')
    },
    { name: "Start Date", field: "startDateDisplay", sortable: true, sortFunction: makeDateSort('startDate') },
    { name: "Group", field: "groupName", sortable: true },
    { name: "", field: "groupButton" },
  ];

  if (isCitiLogikAdmin.value) {
    fields.push({ name: "Organisation", field: "organisationName", sortable: true });
    fields.push({ name: "Change Org...", field: "changeOrgButton" })
  }

  return fields;
});

const datatableOptions: DatatableOptions<DatatableType> = {
  tableClass: "datatable-light clickable-rows",
  initialSort: "creationDate",
  initialSortOrder: "desc" as SortOrder,
  rowClickCallback: row => openDatasetInfoModal(row, true)
};

const Datatable = GenericDatatable<DatatableType>()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Datatable), {
    data: _unref(datatableData),
    columns: _unref(datatableColumns),
    options: datatableOptions
  }, null, 8, ["data", "columns"]))
}
}

})