import { Geometry, Point } from 'ol/geom';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Cluster as ClusterSource, Vector as VectorSource } from "ol/source"
import Map from "ol/Map";
import { GeoJSON } from "ol/format";
import { FitOptions } from 'ol/View';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import { Style, Icon as IconStyle, Text, Fill } from "ol/style";
// @ts-ignore-line
import MapPinIcon from "@/assets/images/map-pin-icon.png";
import { mapPinsRAG } from './mapPins';
import { Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';
import RenderFeature from 'ol/render/Feature';
import { MapBrowserEvent } from 'ol';

export function clearAllFeatureParams(layer: VectorImageLayer<VectorSource<Geometry>>, ...params: string[]) {
  params.forEach(param => clearFeatureParam(layer, param));
}

function clearFeatureParam(layer: VectorImageLayer<VectorSource<Geometry>>, param: string) {
  (layer.getSource() as VectorSource<Geometry>)
    .getFeatures()
    .forEach((feature) => {
      feature.set(param, undefined);
    });
}

export function zoomToGeometry(geoJson: any, map: Map) {
  const format = new GeoJSON();
  const feature = format.readFeature(geoJson);
  const extent = feature.getGeometry().getExtent();
  map.getView().fit(extent, map.getSize() as FitOptions);
}

export function addShowHideObserver(rootElement: HTMLElement, map: Map) {
  const Observe = (elem: HTMLElement, opt: MutationObserverInit, cb: () => void) => {
    const Obs = new MutationObserver((m) => [...m].forEach(cb));
    Obs.observe(elem, opt);
  };

  Observe(rootElement, {
    attributeFilter: ["style"],
    attributeOldValue: true
  },
    () => {
      map.updateSize();
      map.changed();
    });
}

export function addFeatureClickAction(map: Map, action: (feature: Feature<Geometry>, event: MapBrowserEvent<any>) => void) {
  map.on("click", (e) => {
    let selected: Feature<Geometry>;
    map.forEachFeatureAtPixel(e.pixel, (feature) => {
      selected = feature as Feature<Geometry>;
      return true;
    });

    if (selected! !== undefined) {
      action(selected, e);
    }
  })
}

class PoiLayerSource extends VectorSource<Geometry> {
  constructor() {
    super();
  }

  addPoint(name: string, coords: number[]) {
    const pointGeom = new Point(coords);
    const pointFeature = new Feature({
      geometry: pointGeom,
      size: 1000,
      name
    });
    this.addFeature(pointFeature)
  }
}

const makePoiStyle = (hovered: boolean, cluster: boolean) => {
  return (f: Feature<Geometry> | RenderFeature) => {
    const features = cluster ? f.get('features') as Feature<Geometry>[] : [f];

    let pinImage = MapPinIcon;

    const style = new Style({});

    if (features.length === 1) {
      const feature = features[0];
      const score = feature.get("Score") as number | undefined;
      pinImage = score != undefined ? mapPinsRAG[Math.floor(score/20)] : MapPinIcon;

      const icon = feature.get('FaIcon') as string | null;
      if (icon) {
        style.setText(new Text({
          text: String.fromCharCode(parseInt(icon, 16)),
          offsetY: hovered ? -38 : -28,
          font: hovered ? "21px FontAwesome" : "14px FontAwesome",
          fill: new Fill({
            color: 'white'
          }),
        }))
      }

      const zIndex = feature.get('ZIndex') as string | null;
      if (zIndex) {
        style.setZIndex(parseInt(zIndex))
      }
    } else {
      style.setText(new Text({
        text: features.length.toString(),
        offsetY: hovered ? -38 : -28,
        font: hovered ? "21px FontAwesome" : "14px FontAwesome",
        fill: new Fill({
          color: 'white'
        })
      }))
    }
    
    style.setImage(new IconStyle({
      src: pinImage,
      opacity: 1,
      scale: hovered ? 0.11 : 0.08,
      anchor: [0.5, 1],
    }));

    return style;
  }
}

export function makePoiLayer(cluster?: boolean) {
  const poiSource = new PoiLayerSource();

  const clusterSource = new ClusterSource({
    distance: 30,
    minDistance: 20,
    source: poiSource
  });

  const poiLayer = new VectorLayer({
    source: cluster ? clusterSource : poiSource,
    style: makePoiStyle(false, cluster === true)
  });

  const poiHover = new Select({
    condition: pointerMove,
    layers: [poiLayer],
    style: makePoiStyle(true, cluster === true)
  });
  
  return {
    poiSource,
    poiLayer,
    poiHover
  };
}