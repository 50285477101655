import envConfig from "@/config/envConfig";
import { RequestedReportDto, dtoToUserRequestedReport } from "@/models/customerReportsModels";
import { useRequest, authenticatedRequest } from "../requestComposables";

export const useGetRequestedReportRequest = (datasetId: number | string) => {
  return useRequest({
    requestCallback() {
      const url = `${envConfig.admin_address}/api/services/app/CustomerReport/GetRequestedReport?id=${datasetId}`
      const headers = new Headers();
      return authenticatedRequest(url, { headers })
        .then(res => {
          if (!res.ok) throw new Error("Unable to load report details")
          return res.json()
        })
        .then((data: { result: RequestedReportDto }) => {
          const result = { ...data.result, details: dtoToUserRequestedReport(data.result) };
  
          return result;
        });
    },
    requestImmediately: true,
    errorModal: true
  });
}