import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "btn-group btn-group-toggle",
  style: {"width":"100%"},
  "data-toggle": "buttons"
}
const _hoisted_2 = ["value", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "cl-multi-select"
}

import { PropType, ref, watch } from "vue";
import Multiselect from "@vueform/multiselect";

type Option = {
  label: string,
  value: any,
  disabled?: boolean
};


export default _defineComponent({
  props: {
  modelValue: {
    required: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false
  },
  mode: {
    type: String,
    required: true
  },
  options: {
    type: Array as PropType<Option[]>,
    required: true
  },
  createTag: {
    type: Boolean,
    required: false,
    default: false
  },
  searchable: {
    type: Boolean,
    required: false,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit }) {

const props = __props




const results = ref<any>(props.modelValue);
watch(props, () => results.value = props.modelValue, { deep: true })
watch(results, () => emit("update:modelValue", results.value));

return (_ctx: any,_cache: any) => {
  return (__props.options.length <= 3 && props.mode === 'single')
    ? (_openBlock(), _createElementBlock("div", {
        class: "cl-option-select",
        key: results.value
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (option, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              class: _normalizeClass(["btn btn-outline-rosie btn-toggle", { 'btn-rosie': results.value === option.value }]),
              style: _normalizeStyle({ 'width': ((100 / props.options.length) + '%') })
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                autocomplete: "off",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (results.value = $event)),
                value: option.value,
                disabled: props.disabled
              }, null, 8, _hoisted_2), [
                [_vModelRadio, results.value]
              ]),
              _createTextVNode(" " + _toDisplayString(option.label), 1)
            ], 6))
          }), 128))
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_unref(Multiselect), {
          modelValue: results.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (results.value = $event)),
          mode: props.mode,
          options: props.options,
          disabled: props.disabled,
          placeholder: props.placeholder,
          createTag: __props.createTag,
          searchable: __props.searchable
        }, null, 8, ["modelValue", "mode", "options", "disabled", "placeholder", "createTag", "searchable"])
      ]))
}
}

})