import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, watch } from 'vue';


export default _defineComponent({
  props: {
  iconCode: {
    type: String,
    required: true
  }
},
  setup(__props) {

const props = __props


const iconElem = ref<HTMLSpanElement>();

function setIconElem(icon: string) {
  if (!iconElem.value) return;
  iconElem.value.setAttribute('data-code', String.fromCharCode(parseInt(icon!, 16)))
}

onMounted(() => setIconElem(props.iconCode));

watch(props, newVal => setIconElem(newVal.iconCode), { deep: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: "dynamic-fa-icon FontAwesome",
    ref: iconElem
  }, null, 512))
}
}

})