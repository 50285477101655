<template>
  <div class="portlet light">
    <div class="portlet-body">
      <h1>Welcome to the Citi Logik People Movement Portal</h1>

      <p>The portal offers a range of dataset templates to help you unlock the power of mobile network data. But the
        portal is more than just data, it is a toolkit to help you explore and get the insights you need.</p>

      <h2><i class="fa fa-wand-magic-sparkles"></i> Create Dataset</h2>

      <p>Here you can browse our catalogue of dataset templates and see what they do. Clicking create will take you to a
        simple wizard to customise your dataset. Each template also offers a sample dataset to explore:</p>

      <p><span class="bold">Explore Sample Report</span> - a detailed analysis of the location using a variety of mobile
        network data metrics. The
        analysis is separated into tabs and then into accordions for each subset.</p>
      <p><span class="bold">Explore Sample Map</span> - a map view of the data allowing you to filter and generate heat
        maps based on the various
        metrics.</p>
      <p><span class="bold">Download Sample Data</span> - download the raw data in a spreadsheet or download the shape
        files which define the
        zones
        used on the map.</p>

      <p>More templates will be added for different use-cases such as EV charge point locations, carbon emissions,
        tourist spending, and more! Don’t see the template you need? Let us know!</p>

      <h2><i class="fa fa-database"></i> My Datasets</h2>

      <p>Any datasets you create are stored here and there are some helpful tools to help you manage them:</p>

      <p><span class="bold">Grouping</span> - you can create groups for your datasets to help keep them organised.
        Deleting a group does not
        delete
        any datasets it contains.</p>

      <p><span class="bold">Filter & Search</span> - use the dropdown and search box to find the dataset you are looking
        for.</p>

      <p><span class="bold">Map View</span> - sometimes it is useful to visualise your datasets so switch to the map
        view and see them rendered as
        pins.</p>

      <h2><i class="fa fa-user"></i> My Account</h2>

      <p>Here you can view all the users in your organisation. If you are a primary user you can configure whether other
        users in your organisation can spend credits and what datasets they can view. You can also invite new users.</p>

      <hr />

      <p class="bold">If you would like to generate your own datasets please contact <a
          href="mailto:christopher.bax@amey.co.uk">christopher.bax@amey.co.uk</a>.</p>
      <p class="bold">
        If you have any feedback on the portal or if there are any templates or features you'd like to see, we'd love to
        hear from you. Please use the 'contact support' form to get in touch.</p>
    </div>
  </div>
</template>

<style scoped>
i {
  font-size: x-large;
}
</style>
