import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "portlet light",
  style: {"max-width":"1100px","min-width":"650px","margin":"auto","margin-bottom":"50px"}
}
const _hoisted_2 = {
  class: "portlet-title",
  style: {"margin":"-10px"}
}
const _hoisted_3 = { style: {"z-index":"100"} }

import { PropType, watch } from 'vue';

type WizardStep = {
  index: number,
  title: string
}

export default _defineComponent({
  props: {
  steps: {
    type: Array as PropType<WizardStep[]>,
    required: true
  },
  currentStep: {
    type: Number,
    required: true
  }
},
  setup(__props) {

const props = __props


function getStepContainerStyles() {
  return {
    width: `${Math.floor(100 / props.steps.length)}%`
  };
}

function getStepClasses(index: number, stepId: number) {
  const classes = {
    "step-header": true,
    "passed-step-header": props.currentStep > stepId,
    "current-step-header": props.currentStep === stepId,
    "step-header-first": index === 0,
    "step-header-middle": index > 0 && index < props.steps.length - 1,
    "step-header-last": index === props.steps.length - 1
  };

  return classes;
}

watch(() => props.currentStep, () => {
  window.scrollTo({ top: 0 })
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "step-container",
          style: _normalizeStyle(getStepContainerStyles())
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(getStepClasses(index, step.index))
          }, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(step.title), 1)
          ], 2)
        ], 4))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.steps, (step) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "portlet-body",
        key: step.index
      }, [
        _withDirectives(_createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, step.index)
        ], 512), [
          [_vShow, step.index === __props.currentStep]
        ])
      ]))
    }), 128))
  ]))
}
}

})