<template>
  <div id="cl-map-legend" v-once>
    <img src="../../assets/images/map_gradient_legend.png"/>
  </div>
</template>

<style scoped>
#cl-map-legend {
  position: absolute;
  bottom:38px;
  margin: 10px;
  right:0px;
  background:white;
}
#cl-map-legend img {
  padding: 10px;
}
</style>