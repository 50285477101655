import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, PropType, ref, watch } from 'vue';
import Map from "ol/Map";
import { basemapsGroup } from "@/util/maps/mapLayers";
import * as olProj from "ol/proj";
import View from "ol/View";
import { defaults } from "ol/control";
import { dtoToUserRequestedReport, RequestedReportDto, UserRequestedReport } from '@/models/customerReportsModels';
import { Feature, Overlay } from 'ol';
import { Geometry } from "ol/geom";
import { makePoiLayer } from '@/util/maps/mapHelpers';
import { toStandardLocaleDate } from '@/util/dateHelpers';
import { createReportMapFeatures } from "../util/mapUtil";
import { openDatasetInfoModal } from "../util/composables";


export default _defineComponent({
  props: {
  datasets: {
    type: Array as PropType<(RequestedReportDto & UserRequestedReport & {score?: number})[]>,
    required: true
  }
},
  setup(__props) {

const props = __props



const popupWrapper = ref<HTMLDivElement>();
const mapRef = ref<HTMLElement>();

function displaySinglePoiModal(feature: Feature<Geometry>) {
  const datasetId = feature.get("DatasetId");
  const dataset = props.datasets.filter(r => r.id == datasetId)[0];
  openDatasetInfoModal({ ...dataset, ...dtoToUserRequestedReport(dataset) }, false)
}

function displayMultiplePoisMenu(popupWrapper: HTMLElement, features: Feature<Geometry>[], overlay: Overlay, location: number[]) {
  const div = document.createElement('div');
  div.classList.add("ol-popup");

  features.forEach(f => {
    const a = document.createElement('a');
    a.onclick = () => displaySinglePoiModal(f);

    const datasetId = f.get("DatasetId");
    const dataset = props.datasets.filter(r => r.id == datasetId)[0];
    a.innerHTML = `${dataset.clientProjectName} - ${toStandardLocaleDate(dataset.startDate!)}`;

    div.appendChild(a);
    div.appendChild(document.createElement('br'));
  });

  overlay.setPosition(location);

  popupWrapper.appendChild(div);
}

onMounted(async () => {
  const { poiSource, poiLayer, poiHover } = makePoiLayer(true);

  const map = new Map({
    layers: [basemapsGroup.getLayersArray()[basemapsGroup.getLayersArray().length - 1], poiLayer],
    target: mapRef.value!,
    view: new View({
      center: olProj.transform([-1.5, 54.0528], "EPSG:4326", "EPSG:3857"),
      zoom: 6
    }),
    controls: defaults({
      attribution: true,
      zoom: true
    })
  });

  const overlay = new Overlay({
    element: popupWrapper.value,
    autoPan: true,
    autoPanAnimation: {
      duration: 150
    }
  });
  map.addOverlay(overlay);
  map.addInteraction(poiHover);

  map.on("click", (e) => {
    let selected: Feature<Geometry>;
    map.forEachFeatureAtPixel(e.pixel, (feature) => {
      selected = feature as Feature<Geometry>;
      return true;
    });

    popupWrapper.value?.replaceChildren();

    if (selected! !== undefined) {
      const features = selected.get('features') as Feature<Geometry>[];

      if (features.length === 1) displaySinglePoiModal(features[0]);
      else displayMultiplePoisMenu(popupWrapper.value!, features, overlay, e.coordinate);
    }
  });


  function fitToExtent() {
    const layerExtent = poiSource.getExtent();
    try {
      const view = map.getView();
      view.fit(layerExtent);
      view.setZoom(view.getZoom()! - 1);
      if (view.getZoom()! > 10) view.setZoom(10);
    } catch (e) {
      console.error('No POIs available')
    }
  }

  poiSource.addFeatures(createReportMapFeatures(props.datasets));
  fitToExtent();

  watch(props, () => {
    poiSource.clear();
    poiSource.addFeatures(createReportMapFeatures(props.datasets));
    fitToExtent();
    popupWrapper.value?.replaceChildren();
  }, { deep: true });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: mapRef,
      class: "cl-ol-map",
      style: {"border":"2px rgba(0,0,0,0.5) solid","height":"calc(100vh - 278px)"}
    }, null, 512),
    _createElementVNode("div", { ref: popupWrapper }, null, 512)
  ]))
}
}

})