import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import envConfig from '@/config/envConfig';
import { generateWKT } from '@/util/maps/mapUtils';
import { authenticatedRequest } from "@/composables/requestComposables";
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { Feature, View } from 'ol';
import { register } from 'ol/proj/proj4';
import VectorSource from 'ol/source/Vector';
import proj4 from 'proj4';
import { inject, onMounted, Ref, ref, watch } from 'vue';
import { GeoJSON, WKT } from 'ol/format';
import { bbox } from 'ol/loadingstrategy';
import { addLayerSwitcher, basemapsGroup } from '@/util/maps/mapLayers';
import Map from "ol/Map";
import { Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';
import { createZoneStyle } from '@/util/maps/mapZoneUtils';
import { addOverlayPopup } from '@/util/maps/mapOverlayUtils';
import VectorImageLayer from 'ol/layer/VectorImage';
import Geometry from 'ol/geom/Geometry';
import { getArea } from 'ol/sphere';
import { UserRequestedReport } from '@/models/customerReportsModels';
import { Coordinate } from 'ol/coordinate';
import { addSearch } from '@/util/maps/mapSearchControl';
import { addZoomRangeText } from '@/util/maps/zoomRangeTextControl';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { useModals } from "@/composables/modalComposables";

type Zone = {
  objectid: number,
  code: string,
  name: string,
  geometry27700: string,
  extents: string
}

type AdjancyGraph = { [key: number]: number[] };


export default _defineComponent({
  props: {
  maxArea: {
    type: Number,
    required: true
  },
  zoneSystem: {
    type: String,
    required: true
  },
  area: {
    type: Number,
    required: true
  }
},
  emits: ["update:area"],
  setup(__props, { emit }) {

const props = __props
const { openInfoModal } = useModals();





const mapCenter = inject<Ref<Coordinate>>("mapCenter")!;
const mapZoom = inject<Ref<number>>("mapZoom")!;

proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717" + " +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
register(proj4);

//const reportConfig = inject<Ref<CustomerReport>>("reportConfig")!;
const userReportDetails = inject("userReportDetails") as UserRequestedReport

const selectedZones = ref<Feature<Geometry>[]>([]);

watch(() => userReportDetails.selectedZones, (newVal) => {
  if (newVal.length == 0) {
    selectedZones.value.forEach(feature => {
      feature.set("Selected", false)
    });
    selectedZones.value = [];
  }
}, { deep: true });

const format = new WKT();
function createFeatureFromZone(zone: Zone) {
  const wkt = zone.geometry27700

  const feature = format.readFeature(wkt, {
    dataProjection: "EPSG:27700",
    featureProjection: "EPSG:3857"
  });

  feature.set("objectid", zone.objectid);
  feature.set("code", zone.code);
  feature.set("name", zone.name);

  if (selectedZones.value.filter(z => z.get("objectid") === zone.objectid).length > 0) feature.set("Selected", true);

  return feature;
}

//Geometries are MultiPolygon type but only first polygon should be in use
function areContiguous(feature1: Feature<Geometry>, feature2: Feature<Geometry>) {
  const coords1 = (() => {
    const type = feature1.getGeometry()?.getType();
    switch(type) {
      case "MultiPolygon":
        return (feature1.getGeometry() as MultiPolygon).getCoordinates()[0][0];
      case "Polygon":
        return (feature1.getGeometry() as Polygon).getCoordinates()[0];
      default:
        throw "Unknown geometry type";
    }
  })()


  for (let coord of coords1) {
    const closestCoords = feature2.getGeometry()!.getClosestPoint(coord);
    const distance = Math.sqrt(Math.pow((closestCoords[0] - coord[0]), 2) + Math.pow((closestCoords[1] - coord[1]), 2)); //Pythagoras theorem
    if (distance < 5) return true; //The feature contains a point which is touching the target geometry
  }

  return false;
}

function getAdjacencyGraph(features: Feature<Geometry>[]) {
  const graph: AdjancyGraph = {};

  for (let featureUnderTest of features) {
    const zoneIdUnderTest = featureUnderTest.get("objectid")! as number;
    const adjacentZoneIds = [];

    const filteredFeatures = features.filter(f => f.get("objectid") !== zoneIdUnderTest) //No point testing if a zone is contiguous with itself
    for (let featureTestingAgainst of filteredFeatures) {
      if (areContiguous(featureUnderTest, featureTestingAgainst))
        adjacentZoneIds.push(featureTestingAgainst.get("objectid")! as number);
    }
    graph[zoneIdUnderTest] = adjacentZoneIds;
  }

  return graph;
}

function graphIsContiguous(graph: AdjancyGraph, features: Feature<Geometry>[]) {
  if (Object.keys(graph).length === 0) return true;

  const visitedZones: number[] = [];

  const searchZone = (zoneId: number) => {
    if (visitedZones.includes(zoneId)) return;
    visitedZones.push(zoneId);

    for (let adjacentZoneId of graph[zoneId]) {
      searchZone(adjacentZoneId);
    }
  }

  const startingZone = parseInt(Object.keys(graph)[0]);
  searchZone(startingZone);

  return visitedZones.length === features.length;
}

function zoneIsContiguous(zone: Feature<Geometry>, features: Feature<Geometry>[]) {
  //Much faster check than the graph search, for when a new zone is added to the map
  for (let feature of features) {
    if (areContiguous(zone, feature)) return true;
  }
  return false
}

function lsoaZonesSource() {
  const zonesSource = new VectorSource({
    format: new GeoJSON(),
    loader: (extent) => {

      const extentPolygon = fromExtent(extent);
      const extentWkt = generateWKT(new Feature({ geometry: extentPolygon }) as Feature<Polygon>);

      let endpoint = `${envConfig.api_address}/datasetsapi/allzones/LSOA`;
      let queryParams = `&extentWkt=${encodeURIComponent(extentWkt)}`;

      const url = `${endpoint}?${queryParams}`;
      const headers = new Headers();

      zonesSource.clear();
      authenticatedRequest(url, { headers })
        .then(res => res.json())
        .then(data => {
          const geoFeatures = data.Results.Items
            .map((zone: Zone) => createFeatureFromZone(zone))
            .map((feature: any) => {
              return feature
            });

          zonesSource.addFeatures(geoFeatures);
        })
        .catch(e => {
          console.error("failed:", e);
          zonesSource.removeLoadedExtent(extent);
        });
    },
    strategy: bbox,
  });
  return zonesSource;
}

async function customZonefileSource() {
  const zonesGeoJson = (() => {
    const url = `${envConfig.api_address}/datasetsapi/customzonesystem?zonesystemid=${userReportDetails.zoneSystemId}&zonesystemfilename=${userReportDetails.zoneSystem!}`;
    return authenticatedRequest(encodeURI(url), { headers: new Headers() })
      .then(res => res.json())
      .then(data => data.signedUrl as string);
  })();
  const url = await zonesGeoJson;

  const zonesSource = new VectorSource({
    url,
    format: new GeoJSON()
  });

  return zonesSource;
}

let map: Map;
const mapRef = ref<HTMLDivElement>();
const popupWrapper = ref<HTMLDivElement>();
onMounted(async () => {
  const zonesSource = await (async () => {
    switch (props.zoneSystem) {
      case "LSOA":
        return lsoaZonesSource();
      case "CUSTOM":
        return await customZonefileSource();
      default:
        throw "Invalid zone type";
    }
  })();

  const zonesLayer = new VectorImageLayer({
    source: zonesSource,
    minZoom: 12,
    style: (feature) => createZoneStyle(0, 0, feature.get("Selected"), false, false, () => "rgba(255,255,255, 0)")
  });

  const hoverSelect = new Select({
    condition: pointerMove,
    style: () => createZoneStyle(0, 0, false, true, true)
  });

  map = new Map({
    layers: [basemapsGroup, zonesLayer],
    target: mapRef.value as HTMLElement,
    view: new View({
      center: mapCenter.value,
      zoom: mapZoom.value
    })
  });
  addLayerSwitcher(map);
  addSearch(map);
  addZoomRangeText(map, "Zoom in to show zones", 0, 12)

  map.on('moveend', () => {
    mapZoom.value = map.getView().getZoom() as number;
    mapCenter.value = map.getView().getCenter() as Coordinate;
  });

  watch(mapZoom, (newVal) => {
    map.getView().setZoom(newVal);
  });
  watch(mapCenter, (newVal) => {
    map.getView().setCenter(newVal);
  });

  map.addInteraction(hoverSelect);

  addOverlayPopup(map, [{
    title: "Zone ID",
    featureProperty: "objectid"
  },
  {
    title: "Name",
    featureProperty: "name"
  }], popupWrapper.value!);


  map.on("click", (e) => {
    let clicked: Feature<Geometry>;
    map.forEachFeatureAtPixel(e.pixel, (feature) => {
      clicked = feature as Feature<Geometry>;
      return true;
    });

    if (clicked! !== undefined) {
      let features = [...selectedZones.value] as Feature<Geometry>[];
        console.log("features:", features)

      const isSelectedAlready = !!clicked.get("Selected");
      if (!isSelectedAlready) {
        if (features.length > 40) {
          openInfoModal("Too many zones", `<p>You can only select up to 40 zones</p>`)
          return;
        }
        else if (features.length > 0) {
          if (!zoneIsContiguous(clicked, features)) {
            openInfoModal("Non-Contiguous Zone", `<p>Selected zone area must be contiguous</p>`)
            return;
          }
        }

        features.push(clicked);
      } else {
        const filteredFeatures = features.filter(f => f.get("objectid") !== clicked.get("objectid"))
        const adjacencyGraph = getAdjacencyGraph(filteredFeatures);
        const isContiguous = graphIsContiguous(adjacencyGraph, filteredFeatures);

        if (!isContiguous) {
          openInfoModal("Non-Contiguous Zone", `<p>Selected zone area must be contiguous</p>`)
          return;
        }

        features = features.filter(f => f.get("objectid") !== clicked.get("objectid"))
      }


      const areas = features.map(feature => getArea(feature.getGeometry()!));
      const area = areas.length > 0 ? areas.reduce((prev, current) => prev + current) : 0;
      const areaKm2 = Math.round((area / 1000000) * 100) / 100;

      if (!isSelectedAlready && areaKm2 > props.maxArea) {
        openInfoModal("Area too large", `<p>The area you selected is ${areaKm2} km<sup>2</sup> the highest you can select for this project is ${props.maxArea} km<sup>2</sup>.<br/><br/>Please try again.</p>`)
      } else {
        clicked.set("Selected", !isSelectedAlready);

        if (isSelectedAlready) selectedZones.value = selectedZones.value.filter(z => z.get("objectid") !== clicked.get("objectid"));
        else selectedZones.value.push(clicked);

        userReportDetails.selectedZones = selectedZones.value.map(z => z.get("objectid")!);

        emit("update:area", areaKm2);

      }
    }
  })
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: mapRef,
      style: {"height":"600px","border":"2px rgba(0,0,0,0.5) solid"}
    }, null, 512),
    _createElementVNode("div", { ref: popupWrapper }, null, 512)
  ], 64))
}
}

})