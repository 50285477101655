import { StringIndexable } from "@/models/appModels";
import { computed, ref, Ref, watch } from "vue"

export type SortOrder = "asc" | "desc";

type SortFunction<T extends StringIndexable> = (a: T, b: T, sortOrder: SortOrder) => (1 | -1 | 0)

export type DatatableColumn<T extends StringIndexable> = {
  name: string,
  field: string,
  info?: string,
  invisible?: boolean,
  sortFunction?: SortFunction<T>,
  sortable?: boolean
};
export type NormalizedColumn<T extends StringIndexable> = {
  name: string,
  field: string,
  info: string,
  invisible: boolean,
  sortFunction: SortFunction<T>,
  sortable: boolean
};

export type DatatableOptions<T extends StringIndexable> = {
  exports?: {
    csv?: boolean,
    clipboard?: boolean,
    fileName?: string
  },
  search?: boolean,
  noResultsSize?: boolean,
  tableClass?: string,
  pageSize?: number,
  emitters?: string[],
  initialSort?: string,
  initialSortOrder?: SortOrder,
  mouseoverCallback?: (row: T) => any,
  mouseleaveCallback?: (row: T) => any,
  rowClickCallback?: (row: T) => any
};

export type DatatableProps<T extends StringIndexable> = {
  data: Ref<T[]>,
  columns: Ref<DatatableColumn<T>[]>,
  options?: DatatableOptions<T>
};

const makeDefaultSortFunction = (field: string) => {
  //Higher order fn to make a default sort function from desired field
  //Default sort function converts to string and sorts alphabetically
  return (a: any, b: any, order: SortOrder) => {
    let aX = a;
    let bX = b;

    //string
    if (typeof a[field] == "string") aX = a[field].toLowerCase().trim();
    if (typeof b[field] == "string") bX = b[field].toLowerCase().trim();

    //decimal
    if (!isNaN(a[field]) && a[field].toString().indexOf(".") != -1) aX = parseFloat(a[field]);
    if (!isNaN(b[field]) && a[field].toString().indexOf(".") != -1) bX = parseFloat(b[field]);

    //integer
    if (String(parseInt(a[field], 10)) == a[field]) aX = parseInt(a[field], 10);
    if (String(parseInt(b[field], 10)) == b[field]) bX = parseInt(b[field], 10);

    if (order === "desc") {
      if (aX < bX) return 1;
      if (aX > bX) return -1;
      return 0;
    } else {
      if (aX < bX) return -1;
      if (aX > bX) return 1;
      return 0;
    }
  };
};

const normalizeColumns = <T extends StringIndexable>(columns: DatatableColumn<T>[]) => {
  const normalized: NormalizedColumn<T>[] = columns.map(c => ({
    name: c.name,
    field: c.field,
    info: c.info ?? "",
    invisible: c.invisible ?? false,
    sortFunction: c.sortFunction ?? makeDefaultSortFunction(c.field),
    sortable: c.sortable ?? false
  }));
  return normalized;
};

const useSearch = <T extends StringIndexable>(data: Ref<T[]>, columns: NormalizedColumn<T>[]) => {
  const searchQuery = ref("");

  const searchedData = computed(() => {
    if (searchQuery.value == "") return data.value;

    const searchData: T[] = [];
    const fields = columns.filter((col) => !col.invisible).map((col) => col.field);
    const query = searchQuery.value.toLowerCase();

    data.value.forEach((row) => {
      for (const field of fields) {
        if (String(row[field]).toLowerCase().includes(query)) {
          searchData.push(row);
          break;
        }
      }
    });

    return searchData;
  });

  return {
    searchQuery,
    searchedData
  };
};

const useSorting = <T extends StringIndexable>(data: Ref<T[]>, columns: NormalizedColumn<T>[], options?: DatatableOptions<T>) => {
  const sortCol = ref(options?.initialSort ?? "");
  const sortOrder = ref<SortOrder>(options?.initialSortOrder ?? "desc");

  const sortedData = computed(() => {
    if (!sortCol.value) return data.value;
    const col = columns.find((column) => column.field == sortCol.value);
    if (!col) return data.value;
    return data.value.slice().sort((a, b) => col.sortFunction(a, b, sortOrder.value));
  });

  return {
    sortCol,
    sortOrder,
    sortedData
  }
};

const usePagination = <T extends StringIndexable>(data: Ref<T[]>, pageSize: number) => {
  const currentPage = ref(0);

  watch(data, () => currentPage.value = 0, { deep: true });

  const paginatedData = computed(() => {
    const paginated = [];
    for (let i = 0; i < data.value.length; i += pageSize) {
      const currentSlice = data.value.slice(i, i + pageSize);
      if (currentSlice.length > 0) {
        paginated.push(data.value.slice(i, i + pageSize));
      }
    }
    return paginated;
  });

  const displayablePages = computed(() => {
    const totalPages = paginatedData.value.length - 1;
    const startPage = Math.max(0, currentPage.value - 3);
    const endPage = Math.min(totalPages, currentPage.value + 3);

    const pages: number[] = [];

    if (currentPage.value > 3) pages.push(0);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (currentPage.value < totalPages - 3) pages.push(totalPages);

    return pages;

  });

  return {
    currentPage,
    paginatedData,
    displayablePages
  }
}



const changeSortColumn = <T>(sortOrder: Ref<SortOrder>, currentPage: Ref<number>, sortCol: Ref<string>, col: NormalizedColumn<T>, force = false) => {
  if (!col.sortable && !force) return;
  currentPage.value = 0;

  if (sortCol.value === col.field) {
    if (sortOrder.value === "asc") sortOrder.value = "desc";
    else sortOrder.value = "asc";
  } else {
    sortCol.value = col.field;
    sortOrder.value = "asc";
  }
  sortCol.value = col.field
}

export const useDatatable = <T extends StringIndexable>(props: DatatableProps<T>) => {
  const datatableClass = computed(() => props.options?.tableClass ?? "cl-datatable datatable-default-style");
  const pageSize = computed(() => props.options?.pageSize ?? 50);

  const normalizedColumns = computed(() => normalizeColumns(props.columns.value));

  const { searchQuery, searchedData } = useSearch(props.data, normalizedColumns.value);
  const { sortCol, sortOrder, sortedData } = useSorting(searchedData, normalizedColumns.value, props.options);
  const { paginatedData, displayablePages, currentPage } = usePagination(sortedData, pageSize.value);

  const sortByColumn = (col: NormalizedColumn<T>, force = false) => changeSortColumn(sortOrder, currentPage, sortCol, col, force);

  const displayedResultsText = computed(() => {
    if (searchedData.value?.length === 0 ?? false) return "";

    const startResult = currentPage.value * pageSize.value;
    const endResult = Math.min(searchedData.value.length - 1, currentPage.value * pageSize.value + pageSize.value - 1);
    return `Showing ${startResult + 1} to ${endResult + 1} results of ${searchedData.value.length}`;
  });

  return {
    normalizedColumns,
    paginatedData,
    datatableClass,
    pageSize,
    searchQuery,
    sortCol,
    sortOrder,
    displayablePages,
    currentPage,
    sortByColumn,
    displayedResultsText
  }
};