import releases from "@/components/ReleaseNotes/releases";
import { computed, ref, watch } from "vue"
import { deleteAllCookies, getCookie, setCookie } from "../util/cookieHelpers";
import { useOrganisationDetailsRequest } from "./data/organisationComposables";
import { useModals } from "@/composables/modalComposables";
import Modal from "@/components/Modals/Modal.vue";
import { h } from "vue";

const { getOrgDetailsRequest } = useOrganisationDetailsRequest();
const { pushModal } = useModals();

//Services
export type Service = {
  hasAcceptedTAndC: boolean,
  id: number,
  name: string,
  packages: string[],
  shouldAcceptTAndC: boolean
}

const services = ref<Service[]>([]);
export const updateServices = (s: Service[]) => {
  services.value = s;
};
export const getServices = () => services.value;


//Authentication
export type UserLevel = "" | "User" | "Admin";
const _userLevel = ref<UserLevel>("");
const authenticated = ref(false);

export const isAuthenticated = computed(() => authenticated.value);
export const userLevel = computed(() => _userLevel.value);

export const isCitiLogikAdmin = computed(() => userLevel.value === "Admin"
  && !!getOrgDetailsRequest.data &&
  getOrgDetailsRequest.data.organisationName === "Citi Logik");


export function checkAuthenticated() {
  if (getCookie("token_id")) authenticated.value = true;
  else authenticated.value = false;

  _userLevel.value = getCookie("userLevel") as UserLevel;

  return authenticated.value;
}

export function logout() {
  deleteAllCookies();
  checkAuthenticated();
}


//Actions to do upon logging in
watch(isAuthenticated, (newVal) => {
  if (newVal) {
    getOrgDetailsRequest.invoke();
  }
});

export function checkReleaseNotes() {
  const lastReleaseCookie = getCookie("last_release");
  const lastRelease = releases[releases.length - 1];

  if (lastRelease.name !== lastReleaseCookie) {
    setCookie("last_release", lastRelease.name, 7884000); //3 months... I imagine there'll be a new release by then
    pushModal(h(Modal, {title: `New Release - v${lastRelease.name}`}, h(lastRelease)));
  }
}