import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { style: {"display":"inline-block","width":"100%"} }
const _hoisted_3 = { style: {"font-size":"large"} }
const _hoisted_4 = { style: {"float":"right"} }

import { computed, PropType, ref, watchEffect } from "vue";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { setPortalPieSeriesStyle, setPortalColumnSeriesStyle } from "@/util/chartHelpers";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

type Sizes = "small" | "medium" | "large";


export default _defineComponent({
  props: {
  title: { type: String, required: true },
  data: { type: Array as PropType<any[]> },
  valueField: { type: String, required: true },
  categoryField: { type: String, required: true },
  size: { type: String as PropType<Sizes> },
  startWithPie: { type: Boolean }
},
  setup(__props) {

const props = __props
am4core.useTheme(am4themes_animated);




const active = ref(1);
if (props.startWithPie) active.value = 2;

const chartHeight = computed(() => {
  if (props.size === "small") return "250px";
  else if (props.size === "large") return "750px";
  else return "500px";
})

const barChartElem = ref<HTMLDivElement>();
const pieChartElem = ref<HTMLDivElement>();

watchEffect(() => {
  if (!props.data) return;

  if (barChartElem.value) {
    const barChart = am4core.create(barChartElem.value, am4charts.XYChart);
    barChart.data = props.data;

    const valueAxis = barChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    const categoryAxis = barChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = props.categoryField;

    const series = barChart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = props.categoryField;
    series.dataFields.valueY = props.valueField;
    series.name = props.title;
    series.columns.template.tooltipText = `[bold] {categoryX}[/] - {valueY}`;
    setPortalColumnSeriesStyle(series);

    const total = props.data.reduce((total: number, item: any) => total + parseInt(item[props.valueField!]), 0);
    const average = total / props.data.length

    const averageLine = barChart.series.push(new am4charts.LineSeries());
    averageLine.dataFields.valueY = "value";
    averageLine.dataFields.categoryX = "category";
    averageLine.strokeWidth = 2
    averageLine.stroke = am4core.color("#000");
    averageLine.strokeDasharray = "8,4";
    averageLine.data = [
      { "category": props.data[0][props.categoryField], "value": average },
      { "category": props.data[props.data.length - 1][props.categoryField], "value": average }
    ];


  } else if (pieChartElem.value) {
    const pieChart = am4core.create(pieChartElem.value, am4charts.PieChart);
    pieChart.data = [...props.data].sort((a,b) => parseFloat(a[props.valueField]) < parseFloat(b[props.valueField]) ? 1 : -1);
    pieChart.start = 0;

    const pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = props.valueField;
    pieSeries.dataFields.category = props.categoryField;
    pieSeries.name = props.title;
    setPortalPieSeriesStyle(pieSeries, props.data!.length)
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(__props.title), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass(["fa fa-chart-simple chart-switcher-icon", { 'chart-switcher-icon-active': active.value === 1 }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (active.value = 1)),
            title: "Switch to line graph"
          }, null, 2),
          _createElementVNode("button", {
            class: _normalizeClass(["fa fa-chart-pie chart-switcher-icon", { 'chart-switcher-icon-active': active.value === 2 }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (active.value = 2)),
            title: "Switch to pie chart"
          }, null, 2)
        ])
      ])
    ]),
    (active.value === 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({ height: _unref(chartHeight) }),
          ref: barChartElem
        }, null, 4))
      : (active.value === 2)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            style: _normalizeStyle({ height: _unref(chartHeight) }),
            ref: pieChartElem
          }, null, 4))
        : _createCommentVNode("", true)
  ], 64))
}
}

})